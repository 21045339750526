import ncs5cffront from "../../assets/NCS/NCS 5CF Front.png";
import ncs5cfback from "../../assets/NCS/NCS 5CF.png";
import ncs5dffront from "../../assets/NCS/NCS 5DF Front.png";
import ncs5dfback from "../../assets/NCS/NCS 5DF.png";
import ncs1 from "../../assets/NCS/NCS 5CF (2).png";
import vc1 from "../../assets/vc 1.png";
import VI1516GN from "../../assets/VI1516GN1.png";
import ncspdf1 from "../../assets/NCS-5CF.pdf";
import ncspdf2 from "../../assets/NCS-5DF.pdf";
import archepdf1 from "../../assets/Arche/AR31 new datasheet (1).pdf";
import archepdf2 from "../../assets/Arche/AR32 new datasheet.pdf";
import archepdf3 from "../../assets/Arche/AR331 new datasheet.pdf";
import archepdf4 from "../../assets/Arche/AR332 new datasheet.pdf";
import ncspdf3 from "../../assets/Novo/NCS-5C.pdf";
import ncspdf4 from "../../assets/Novo/NCS-5D.pdf";
import ncspdf5 from "../../assets/Novo/NCS-110CU.pdf";
import ncspdf6 from "../../assets/Novo/NEB-124W.pdf";

import ad4s1 from "../../assets/ads/AD-4S/0.png";
import ad4s2 from "../../assets/ads/AD-4S/2.png";
import ad4s3 from "../../assets/ads/AD-4S/v1.png";

import ad211 from "../../assets/ads/AD21/0.png";
import ad212 from "../../assets/ads/AD21/v1.png";
import ad213 from "../../assets/ads/AD21/v3.png";

import ad491 from "../../assets/ads/AD49-W/1.png";
import ad492 from "../../assets/ads/AD49-W/2.png";
import ad493 from "../../assets/ads/AD49-W/3.png";

import ads4spdf from "../../assets/ads/AD4S Datasheet.pdf";
import ad49wpdf from "../../assets/ads/AD49-W Datasheet.pdf";
import ad21pdf from "../../assets/ads/AD21 Datasheet.pdf";


const dummyProducts = [
  // Arche
  {
    name: "AR 31",
    id: "1",
    productName: "Arche",
    pdf: archepdf1,
    des: "Wireless Microphone Systems",
    category: "Wireless Microphone",
    description:
      "AR3 is a 1/2 EIA standard Rack Unit metal chassis single-channel wireless receiver for AR1 & AR2 transmitters. Bright & intuitive LCD front panel display gives necessary information like RF level, Audio levels, working channel & transmitter battery status, etc. With 32MHz operating bandwidth, each receiver band allows up to 10 compatible systems which are interference-free. With multiple bands, it can reach up to 24 compatible systems*. The PLL synthesized circuitry ensures RF transmission reliability with separation from GSM & other EMI interference. AFS (Auto Frequency Selection) functions scan the environment and lock itself in an open interference-free frequency channel. The diversity antenna circuit chooses the better one receiving out of its 1/2 Wave Antennae to give optimum RF stability every time.",
    technicalSpecification: {
      "Carrier Frequency Range": "522MHz~936MHz",
      "Modulation Mode": "FM stere modulation",
      Oscillation: "PLL synthesized",
      Sensitivity: "5dBμV, S/N>60dB at 25 deviation",
      "Operating Bandwidth": "32MHz (E, I, J, K)",
      "Max. Deviation Range": "±45KHz",
      "S/N Ratio": ">105dB",
      "T. H. D": "<0.7%@1KHz",
      "Frequency Response (audio)": "45Hz-18KHz ±3dB",
      "Operating Range": "100M typical*",
      "Power Supply": "DC 12V/12W adapter",
      "Dimension (mm)": "20 (W)x206(D)x43(H)",
      Weight: "Approximately 1.0kg",
      "Output Connector": "XLR balanced & 6.3φ phone jack unbalanced",
      Housing: "Pressed steel powder coated enclosure",
    },
    keyFeatures: [
      "Key Features",
      "AFS (Automatic Frequency Selection)",
      "Press the “AFS” (Auto Frequency Selection) button 3 sec and the receiver will auto-scan and lock on to an open, interference-free frequency.",
      "Compatible systems",
      "Maximum 24 sets can be used simultaneously by using multiple frequency bands in one venue.",
      "IR Infrared Sync",
      "Press [IR] button to automatically pair the receiver frequency with the transmitter.",
      "Full Band Transmitter",
      "134MHz full band transmitter allows pairing with the receiver irrespective of frequency band.",
      "Anti-Interference",
      "PLL (Phase Lock Loop frequency control) design ensures transmission reliability, “NoiseLock”squelch effectively blocks stray RF.",
      "Energy Conservation",
      "Low RF power mode gives twice the battery life of up to 14 hours*. (On AA Alkaline Batteries)",
    ],
    image: require("../../assets/Arche/Arche-31/Ar31.png"),
    image1: require("../../assets/Arche/Arche-31/AR3.png"),
    image2: require("../../assets/Arche/Arche-31/AR1.png"),
    download: [
      {
        title: "AR31 Datasheet",
        pdf: archepdf1,
      },
    ],
  },
  {
    name: "AR 32",
    productName: "Arche",

    des: "Wireless Microphone Systems",
    category: "Wireless Microphone Systems",
    description:
      "AR3 is a 1/2 EIA standard Rack Unit metal chassis single-channel wireless receiver for AR1 & AR2 transmitters. Bright & intuitive LCD front panel display gives necessary information like RF level, Audio levels, working channel & transmitter battery status, etc. With 32MHz operating bandwidth, each receiver band allows up to 10 compatible systems which are interference-free. With multiple bands, it can reach up to 24 compatible systems*. The PLL synthesized circuitry ensures RF transmission reliability with separation from GSM & other EMI interference. AFS (Auto Frequency Selection) functions scan the environment and lock itself in an open interference-free frequency channel. The diversity antenna circuit chooses the better one receiving out of its 1/2 Wave Antennae to give optimum RF stability every time.",
    technicalSpecification: {
      "Carrier Frequency Range": "522MHz~936MHz",
      "Modulation Mode": "FM stere modulation",
      Oscillation: "PLL synthesized",
      Sensitivity: "5dBμV, S/N>60dB at 25 deviation",
      "Operating Bandwidth": "32MHz (E, I, J, K)",
      "Max. Deviation Range": "±45KHz",
      "S/N Ratio": ">105dB",
      "T. H. D": "<0.7%@1KHz",
      "Frequency Response (audio)": "45Hz-18KHz ±3dB",
      "Operating Range": "100M typical*",
      "Power Supply": "DC 12V/12W adapter",
      "Dimension (mm)": "20 (W)x206(D)x43(H)",
      Weight: "Approximately 1.0kg",
      "Output Connector": "XLR balanced & 6.3φ phone jack unbalanced",
      Housing: "Pressed steel powder coated enclosure",
    },
    keyFeatures: [
      "Key Features",
      "AFS (Automatic Frequency Selection)",
      "Press the “AFS” (Auto Frequency Selection) button 3 sec and the receiver will auto-scan and lock on to an open, interference-free frequency.",
      "Compatible systems",
      "Maximum 24 sets can be used simultaneously by using multiple frequency bands in one venue.",
      "IR Infrared Sync",
      "Press [IR] button to automatically pair the receiver frequency with the transmitter.",
      "Full Band Transmitter",
      "134MHz full band transmitter allows pairing with the receiver irrespective of frequency band.",
      "Anti-Interference",
      "PLL (Phase Lock Loop frequency control) design ensures transmission reliability, “NoiseLock”squelch effectively blocks stray RF.",
      "Energy Conservation",
      "Low RF power mode gives twice the battery life of up to 14 hours*. (On AA Alkaline Batteries)",
    ],
    image: require("../../assets/Arche/Arche-32/AR32.png"),
    image1: require("../../assets/Arche/Arche-32/AR2 2.png"),
    image2: require("../../assets/Arche/Arche-32/AR32.png"),
    download: [
      {
        title: "AR32 Datasheet",
        pdf: archepdf2,
      },
    ],
  },
  {
    name: "AR 331",
    productName: "Arche",

    des: "Wireless Microphone Systems",
    category: "Wireless Microphone Systems",
    description:
      "AR33 is a dual channel wireless receiver for AR1 & AR2 transmitters housed in 1 EIA standard Rack Unit metal chassis. Bright & intuitive LCD front panel display gives the necessary information for both the transmitters like RF level, Audio levels, working channel & transmitter battery status, etc. With 32MHz operating bandwidth for each receiver, the band allows up to 10 compatible systems which are interference-free. With multiple bands, it can reach up to 24 compatible systems*. The PLL synthesized circuitry ensures RF transmission reliability with separation from GSM & other EMI interference. AFS (Auto Frequency Selection) functions scan the environment and lock itself in an open interference-free frequency channel. The diversity antenna circuit chooses the better one receiving out of its 1/2 Wave Antennae to give optimum RF stability every time.",
    technicalSpecification: {
      "Mainframe Size": "EIA Standard 1U",
      Channels: "Dual Channel",
      "Frequency Stability": "±0.0005% (0~50 °C)",
      "Carrier Frequency Range": "522MHz~936MHz",
      "Modulation Mode": "FM stereo modulation",
      Oscillation: "PLL synthesized",
      "Receiving Sensitivity": "5dBuv, S/N>60dB",
      Bandwidth: "at 25 deviation 32MHz",
      "Max. Deviation Range": "±45KHz",
      "S/N Ratio": ">105dB",
      "Total Harmonic Distortion (T.H.D)": "<0.7%@1KHz",
      "Frequency Response": "80Hz-18KHz ±3dB",
      "Operating Range": "80~100M typical (in open space)",
      "Power Supply": "DC 12V/12W",
      Weight: "1.9kg",
      "Dimension (mm)": "410(W) x 206 (D) x 43 (H)",
      "Output Connector": "XLR balanced & 6.3 φ phone jack unbalanced",
    },
    keyFeatures: [
      "AFS (Automatic Frequency Selection)",
      "Press the “AFS” (Auto Frequency Selection) button 3 sec and the receiver will auto-scan and lock on to an open, interference-free frequency.",
      "Compatible Systems",
      "Maximum 24 sets can be used simultaneously by using multiple frequency bands in one venue.",
      "IR Infrared Sync",
      "Press [IR] button to automatically pair the receiver frequency with the transmitter.",
      "Full Band Transmitter",
      "134MHz full band transmitter allows pairing with the receiver irrespective of frequency band.",
      "Anti-Interference",
      "PLL (Phase Lock Loop frequency control) design ensures transmission reliability, “NoiseLock” squelch effectively blocks stray RF.",
      "Energy Conservation",
      "Low RF power mode gives twice the battery life of up to 14 hours*. (On AA Alkaline Batteries)",
    ],
    image: require("../../assets/Arche/Arche-331/AR331.png"),
    image1: require("../../assets/Arche/Arche-331/AR1.png"),
    image2: require("../../assets/Arche/Arche-331/AR 33.PNG"),
    download: [
      {
        title: "AR331 Datasheet",
        pdf: archepdf3,
      },
    ],
  },
  {
    name: "AR 332",
    productName: "Arche",
    pdf: archepdf4,
    des: "Wireless Microphone Systems",
    category: "Wireless Microphone Systems",
    description:
      "AR33 is a dual channel wireless receiver for AR1 & AR2 transmitters housed in 1 EIA standard Rack Unit metal chassis. Bright & intuitive LCD front panel display gives the necessary information for both the transmitters like RF level, Audio levels, working channel & transmitter battery status, etc. With 32MHz operating bandwidth for each receiver, the band allows up to 10 compatible systems which are interference-free. With multiple bands, it can reach up to 24 compatible systems*. The PLL synthesized circuitry ensures RF transmission reliability with separation from GSM & other EMI interference. AFS (Auto Frequency Selection) functions scan the environment and lock itself in an open interference-free frequency channel. The diversity antenna circuit chooses the better one receiving out of its 1/2 Wave Antennae to give optimum RF stability every time.",
    technicalSpecification: {
      "Mainframe Size": "EIA Standard 1U",
      Channels: "Dual Channel",
      "Frequency Stability": "±0.0005% (0~50 °C)",
      "Carrier Frequency Range": "522MHz~936MHz",
      "Modulation Mode": "FM stereo modulation",
      Oscillation: "PLL synthesized",
      "Receiving Sensitivity": "5dBuv, S/N>60dB",
      Bandwidth: "at 25 deviation 32MHz",
      "Max. Deviation Range": "±45KHz",
      "S/N Ratio": ">105dB",
      "Total Harmonic Distortion (T.H.D)": "<0.7%@1KHz",
      "Frequency Response": "80Hz-18KHz ±3dB",
      "Operating Range": "80~100M typical (in open space)",
      "Power Supply": "DC 12V/12W",
      Weight: "1.9kg",
      "Dimension (mm)": "410(W) x 206 (D) x 43 (H)",
      "Output Connector": "XLR balanced & 6.3 φ phone jack unbalanced",
    },
    keyFeatures: [
      "AFS (Automatic Frequency Selection)",
      "Press the “AFS” (Auto Frequency Selection) button 3 sec and the receiver will auto-scan and lock on to an open, interference-free frequency.",
      "Compatible Systems",
      "Maximum 24 sets can be used simultaneously by using multiple frequency bands in one venue.",
      "IR Infrared Sync",
      "Press [IR] button to automatically pair the receiver frequency with the transmitter.",
      "Full Band Transmitter",
      "134MHz full band transmitter allows pairing with the receiver irrespective of frequency band.",
      "Anti-Interference",
      "PLL (Phase Lock Loop frequency control) design ensures transmission reliability, “NoiseLock” squelch effectively blocks stray RF.",
      "Energy Conservation",
      "Low RF power mode gives twice the battery life of up to 14 hours*. (On AA Alkaline Batteries)",
    ],
    image: require("../../assets/Arche/Arche-332/AR332.png"),
    image1: require("../../assets/Arche/Arche-332/AR2 2.png"),
    image2: require("../../assets/Arche/Arche-332/AR332.png"),
    download: [
      {
        title: "AR332 Datasheet",
        pdf: archepdf4,
      },
    ],
  },

  // DSP & Controls
  {
    name: "Keyer 1616",
    productName: "Keyer",
    pdf: "https://drive.google.com/file/d/1_Bzr1xkfnvw1SpLLfT5JQBa8_oGJ9a1S/view?usp=sharing",
    des: "DSP & Controls",
    category: "DSP & Controls",
    description:
      "The KEYER 1616 is an audio processing and control system that uses an advanced TI456MHz Dual-Core Chip for DSP giving you an unprecedented opportunity to solve the most complex problems of signal routing & processing. Keyer 1616 makes mixing, signal routing, vocal processing, and room optimisation, all these tasks, are very simple and quite straightforward. The Keyer Series DSP helps you to get the most optimised audio performance for presentation and conferencing applications in wherever place you want whether it be in boardrooms, courtrooms, or a restro-bar.",
    technicalSpecification: {
      "Sampling Rate": "48KHz",
      "Phantom Power": "48V",
      "Frequency Response": "20 – 20KHz，±0.2dB",
      "Maximum Input/Output Level": "18dBu",
      "Output Impedance (Balanced)": "100Ω",
      "Equivalent Input Noise EIN": "≤-125dBU",
      "Channel Isolation @1kHz": "100dB",
      "THD+N": "<0.004% @4dBu",
      "Digital/Analog Dynamic Range": "120dB",
      "Background Noise": "90dBU",
      "Analog/Digital Dynamic Range": "114dB",
      "Input Impedance (Balanced)": "20kΩ",
      "Input Gain Amplification Range": "24 to -27dB, (3dB Step, 17 in total)",
      "Operating Power Supply": "AC110/220V,50Hz/60Hz",
      "Enclosure / Chassis Material": "Pressed Steel (powder coated)",
      "Chassis Dimensions (H×W×D)": "45×482×258 mm, Size 19” 1RU",
      "Product Weight": "2.9 kg",
      "Included Accessories": "IEC 1.2 MTR Power Cord",
      "Environmental Conditions": "10 to +40 °C",
      Compliance: "CE & RoHS",
      "Analog Input Channels": 16,
      "Analog Output Channels": 16,
      "Dante Input Channels": "N/A",
      "Dante Output Channels": "N/A",
      "Ethernet Control Ports": 1,
      "Dante Interface (Primary)": "N/A",
      "Dante Interface (Secondary)": "N/A",
      RS232: 1,
      RS485: 1,
      GPIO: 8,
    },
    keyFeatures: [
      "DSP audio processing",
      "Built-in automatic mixing console",
      "Ducker dodger",
      "Optional feedback elimination",
      "Echo elimination",
      "Noise elimination module",
      "Input Per Channel: Preamplifier, signal generator, expander, compressor, 10-band parametric equalization",
      "Output Per Channel: 31-band graphic equalizer, delay, crossover, limiter",
      "Built-in bi-directional USB Audio Interface for soft codec conferencing solution supports recording & play",
      "Support for scene preset function",
      "Automatic power failure protection memory function",
      "Full-featured matrix mixing function",
      "User definable GUI windows-based control software to manage & configure the DSP unit",
      "Built-in automatic camera tracking function",
    ],
    image: require("../../assets/Keyer/Keyer-1616/1616 front.png"),
    image1: require("../../assets/Keyer/Keyer-1616/1616 BACK.png"),
    image2: require("../../assets/Keyer/Keyer-1616/1616 front.png"),
    download: [
      {
        title: "Keyer 1616 Datasheet",
        pdf: "https://drive.google.com/file/d/19crZpysX4LyZXq1ST7rrdBLgKkgTFf04/view?usp=sharing",
      },
      {
        title: "Keyer Quick Start Guide",
        pdf: "https://drive.google.com/file/d/1DeVoLi6OCrO9aMuYtH4udE0ufKpjI_qi/view?usp=sharing ",
      },
      {
        title: "Keyer 1616 User Manual",
        pdf: "https://drive.google.com/file/d/1uMo12-UKBTggcFZNqivf7rD5GIaZkeL9/view?usp=sharing",
      },
      {
        title: "Keyer 1616 Mechanical Drawing",
        pdf: "https://drive.google.com/file/d/1AV-1vkoQkL73xq9pXM9PdiRPh3T6bec6/view?usp=sharing",
      },
    ],
  },

  {
    name: "Keyer 44",
    productName: "Keyer",
    des: "DSP & Controls",
    category: "DSP & Controls",
    description:
      "The KEYER 44 is an audio processing and control system that uses an advanced TI456MHz Dual-Core Chip for DSP giving you an unprecedented opportunity to solve the most complex problems of signal routing & processing. Keyer44 makes mixing, vocal processing, signal routing & room optimisation all these tasks very simple & straight forward. The Keyer Series DSP helps you to get the most optimised audio performance for presentation and conferencing applications in wherever place you want whether it be in boardrooms, courtrooms, or a resto-bar.",
    technicalSpecification: {
      "Sampling Rate": "48KHz",
      "Phantom Power": "48V",
      "Frequency Response": "20 – 20KHz，±0.2dB",
      "Maximum Input/Output Level": "18dBu",
      "Output Impedance (Balanced)": "100Ω",
      "Equivalent Input Noise EIN": "≤-125dBU",
      "Channel Isolation @1kHz": "100dB",
      "THD+N": "<0.004% @4dBu",
      "Digital/Analog Dynamic Range": "120dB",
      "Background Noise": "90dBU",
      "Analog/Digital Dynamic Range": "114dB",
      "Input Impedance (Balanced)": "20kΩ",
      "Input Gain Amplification Range": "24 to -27dB, (3dB Step,17 in total)",
      "Operating Power Supply": "AC110/220V,50Hz/60Hz",
      "Enclosure / Chassis Material": "Pressed Steel (powder coated)",
      "Chassis Dimensions (H×W×D)": "45×482×258 mm, Size 19” 1RU",
      "Product Weight": "2.9 kg",
      "Included Accessories": "IEC 1.2 MTR Power Cord",
      "Environmental Conditions": "10 to +40 °C",
      Compliance: "CE & RoHS",
      "Analog Input Channels": 4,
      "Analog Output Channels": 4,
      "Dante Input Channels": "N/A",
      "Dante Output Channels": "N/A",
      "Ethernet Control Ports": 1,
      "Dante Interface (Primary)": "N/A",
      "Dante Interface (Secondary)": "N/A",
      RS232: 1,
      RS485: 1,
      GPIO: 8,
    },
    keyFeatures: [
      "DSP audio processing",
      "Built-in automatic mixing console",
      "Ducker dodger",
      "Optional feedback elimination",
      "Echo elimination",
      "Noise elimination module",
      "Input Per Channel: Preamplifier, signal generator, expander, compressor, 10-band parametric equalization",
      "Output Per Channel: 31-band graphic equalizer, delay, crossover, limiter",
      "Built-in bi-directional USB Audio Interface for soft codec conferencing solution supports recording & play",
      "Support for scene preset function",
      "Automatic power failure protection memory function",
      "Full-featured matrix mixing function",
      "User definable GUI windows-based control software to manage & configure the DSP unit",
      "Built-in automatic camera tracking function",
    ],
    image: require("../../assets/Keyer/Keyer-44/44 front.png"),
    image1: require("../../assets/Keyer/Keyer-44/44 BACK.png"),
    image2: require("../../assets/Keyer/Keyer-44/44 front.png"),
    download: [
      {
        title: "Keyer 44 Datasheet",
        pdf: "https://drive.google.com/file/d/1dlgCR-ys6QWnrax7Dz394oi6DL4GMjeI/view?usp=sharing",
      },
      {
        title: "Keyer Quick Start Guide",
        pdf: "https://drive.google.com/file/d/1aexuWIYv7nF-HzGKPVdQSeVuwhiUoXY1/view?usp=sharing",
      },
      {
        title: "Keyer 44 User Manual",
        pdf: "https://drive.google.com/file/d/1sXopAeDzqUI-ZfB_eyTApzZbCAG0j-AD/view?usp=sharing",
      },
      {
        title: "Keyer 44 Mechanical Drawing",
        pdf: "https://drive.google.com/file/d/1NPE_m2PXYsIN16gb8UIKVp0tjmicqS-o/view?usp=sharing",
      },
    ],
  },
  {
    name: "Keyer 88",
    productName: "Keyer",
    des: "DSP & Controls",
    category: "DSP & Controls",
    description:
      "The KEYER 88 is an audio processing and control system that uses an advanced TI456MHz Dual-Core Chip for DSP giving you an unprecedented opportunity to solve the most complex problems of signal routing & processing. Keyer Series makes mixing, signal routing, vocal processing, and room optimisation, all these tasks very simple and quite straightforward. The Keyer Series DSP helps you to get the most optimised audio performance for presentation and conferencing applications in wherever place you want whether it be in boardrooms, courtrooms, or a resto-bar.",
    technicalSpecification: {
      "Sampling Rate": "48KHz",
      "Phantom Power": "48V",
      "Frequency Response": "20 – 20KHz，±0.2dB",
      "Maximum Input/Output Level": "18dBu",
      "Output Impedance (Balanced)": "100Ω",
      "Equivalent Input Noise EIN": "≤-125dBU",
      "Channel Isolation @1kHz": "100dB",
      "THD+N": "<0.004% @4dBu",
      "Digital/Analog Dynamic Range": "120dB",
      "Background Noise": "90dBU",
      "Analog/Digital Dynamic Range": "114dB",
      "Input Impedance (Balanced)": "20kΩ",
      "Input Gain Amplification Range": "24 to -27dB, (3dB Step,17 in total)",
      "Operating Power Supply": "AC110/220V,50Hz/60Hz",
      "Enclosure / Chassis Material": "Pressed Steel (powder coated)",
      "Chassis Dimensions (H×W×D)": "45×482×258 mm, Size 19” 1RU",
      "Product Weight": "2.9 kg",
      "Included Accessories": "IEC 1.2 MTR Power Cord",
      "Environmental Conditions": "10 to +40 °C",
      Compliance: "CE & RoHS",
      "Analog Input Channels": 8,
      "Analog Output Channels": 8,
      "Dante Input Channels": "N/A",
      "Dante Output Channels": "N/A",
      "Ethernet Control Ports": 1,
      "Dante Interface (Primary)": "N/A",
      "Dante Interface (Secondary)": "N/A",
      RS232: 1,
      RS485: 1,
      GPIO: 8,
    },

    keyFeatures: [
      "DSP audio processing",
      "Built-in automatic mixing console",
      "Ducker dodger",
      "Optional feedback elimination",
      "Echo elimination",
      "Noise elimination module",
      "Input Per Channel: Preamplifier, signal generator, expander, compressor, 10-band parametric equalization",
      "Output Per Channel: 31-band graphic equalizer, delay, crossover, limiter",
      "Built-in bi-directional USB Audio Interface for soft codec conferencing solution supports recording & play",
      "Support for scene preset function",
      "Automatic power failure protection memory function",
      "Full-featured matrix mixing function",
      "User definable GUI windows-based control software to manage & configure the DSP unit",
      "Built-in automatic camera tracking function",
    ],
    image: require("../../assets/Keyer/Keyer-88/88 front.png"),
    image1: require("../../assets/Keyer/Keyer-88d/KEYER-88D-1.png"),
    image2: require("../../assets/Keyer/Keyer-88/88 back.png"),
    download: [
      {
        title: "Keyer 88 Datasheet",
        pdf: "https://drive.google.com/file/d/1MPPE2OzpZggVYNbwBmLHzcGPcVFF3ebl/view?usp=sharing",
      },
      {
        title: "Keyer Quick Start Guide",
        pdf: "https://drive.google.com/file/d/1aexuWIYv7nF-HzGKPVdQSeVuwhiUoXY1/view?usp=sharing",
      },
      {
        title: "Keyer 88 User Manual",
        pdf: "https://drive.google.com/file/d/18p762916ce8tRnVHIsuZaBIGfzYwNWkR/view?usp=sharing",
      },
      {
        title: "Keyer 88 Mechanical Drawing",
        pdf: "https://drive.google.com/file/d/1fMuZ5QmZvmfuNGjOjPmAl3zcGOWk1gJK/view?usp=sharing",
      },
    ],
  },
  {
    name: "Keyer 88D",
    productName: "Keyer",
    des: "DSP & Controls",
    category: "DSP & Controls",
    description:
      "TThe KEYER 88D is an audio processing and control system that uses an advanced TI456MHz Dual-Core Chip for DSP giving you an unprecedented opportunity to solve the most complex problems of signal routing & processing. The Dante module provides a high bandwidth, low latency, high compatibility, and low-cost solution for network audio transmission. Keyer 88D makes mixing, signal routing, vocal processing, and room optimisation, all these tasks, are very simple and quite straightforward. The Keyer Series DSP helps you to get the most optimised audio performance for presentation and conferencing applications in wherever place you want whether it be in boardrooms, courtrooms, or a resto-bar.",
    technicalSpecification: {
      "Sampling Rate": "48KHz",
      "Phantom Power": "48V",
      "Frequency Response": "20 – 20KHz, ±0.2dB",
      "Maximum Input/Output Level": "18dBu",
      "Output Impedance (Balanced)": "100Ω",
      "Equivalent Input Noise EIN": "≤-125dBU",
      "Channel Isolation @1kHz": "100dB",
      "THD+N": "<0.004% @4dBu",
      "Digital/Analog Dynamic Range": "120dB",
      "Background Noise": "90dBU",
      "Analog/Digital Dynamic Range": "114dB",
      "Input Impedance (Balanced)": "20kΩ",
      "Input Gain Amplification Range": "24 to -27dB, (3dB Step, 17 in total)",
      "Operating Power Supply": "AC110/220V, 50Hz/60Hz",
      "Enclosure / Chassis Material": "Pressed Steel (powder coated)",
      "Chassis Dimensions (H×W×D)": "45×482×258 mm, Size 19” 1RU",
      "Product Weight": "2.9 kg",
      "Included Accessories": "IEC 1.2 MTR Power Cord",
      "Environmental Conditions": "10 to +40 °C",
      Compliance: "CE & RoHS",
      "Analog Input Channel": 8,
      "Analog Output Channel": 8,
      "Dante Input Channel": 8,
      "Dante Output Channel": 8,
      "Ethernet Control Port": 1,
      "Dante Interface (Primary)": 1,
      "Dante Interface (Secondary)": 1,
      RS232: 1,
      RS485: 1,
      GPIO: 8,
    },
    keyFeatures: [
      "DSP audio processing",
      "Built-in automatic mixing console",
      "Ducker dodger",
      "Optional feedback elimination",
      "Echo elimination",
      "Noise elimination module",
      "Input Per Channel: Preamplifier, signal generator, expander, compressor, 10-band parametric equalization",
      "Output Per Channel: 31-band graphic equalizer, delay, crossover, limiter",
      "Built-in bi-directional USB Audio Interface for soft codec conferencing solution supports recording & play",
      "Built-in 8x8 Dante audio module",
      "Support for scene preset function",
      "Automatic power failure protection memory function",
      "Full-featured matrix mixing function",
      "User definable GUI windows-based control software to manage & configure the DSP unit",
      "Built-in automatic camera tracking function",
    ],
    image: require("../../assets/Keyer/Keyer-88d/88D FRONT.png"),
    image1: require("../../assets/Keyer/Keyer-88d/KEYER-88D-1.png"),
    image2: require("../../assets/Keyer/Keyer-88d/88D-back-1000x155.png"),
    download: [
      {
        title: "Keyer 88D Datasheet",
        pdf: "https://drive.google.com/file/d/1XJPr16GDM2jiKK3NA1XU9U_gF2b0i_jg/view?usp=sharing",
      },
      {
        title: "Keyer Quick Start Guide",
        pdf: "https://drive.google.com/file/d/1aexuWIYv7nF-HzGKPVdQSeVuwhiUoXY1/view?usp=sharing",
      },
      {
        title: "Keyer 88D User Manual",
        pdf: "https://drive.google.com/file/d/1vFbnGjliIOnhXhabbX4jFqU7GsVMZZJc/view?usp=sharing",
      },
      {
        title: "Keyer 88D Mechanical Drawing",
        pdf: "https://drive.google.com/file/d/1LKaswWqNfrBsMp6b8TBZlbYvk6y-ckKZ/view?usp=sharing",
      },
    ],
  },
  {
    name: "Parameter Controller",
    productName: "Keyer",
    des: "DSP & Controls",
    category: "DSP & Controls",
    description:
      "The PC1 is a wall-mount control panel that acts as a remote-control device for the Keyer Series of DSPs. It can be customized to suit various applications like processor volume or switching application scenes, mixing matrix, and other functions. It communicates with the DSP unit through UDP commands on standard network cable Cat5e or Cat6 & can be powered up through a POE switch on the same cable.",
    technicalSpecification: {
      "Operating Temperature": "10 to 40",
      "Operating Power": "PoE (IEEE 802.3af)",
      "IP Control": "UDP",
      "Control Cable": "RJ-45 (CAT-5/6/7 Network cable)",
      "Control Line Length": "≤100m",
      "Screen Size": "1.3",
      "Control Knob": 1,
      Material: "Aluminum alloy sandblasting",
      Color: "Black",
      Dimension: "86mm*25.65mm*105mm",
    },
    keyFeatures: [
      "Fully customizable front panel knob to adjust various parameters of DSP it is connected to.",
      "Works on UDP protocol through standard network cable",
      "Powered by POE switch so no need of separate power cable or supply unit.",
    ],
    image: require("../../assets/Keyer/PARAMETER/PC1 FRONT.png"),
    image1: require("../../assets/Keyer/PARAMETER/PC1.png"),
    image2: require("../../assets/Keyer/PARAMETER/1.png"),
    download: [
      {
        title: "PC-1 Datasheet",
        pdf: "https://drive.google.com/file/d/1_cGZASYUZtvM1ec893q6VBJOHhdGOtyE/view?usp=sharing",
      },
    ],
  },

  // Novo Conference System
  {
    name: "NCS 5C",
    productName: "Novo",
    pdf: ncspdf3,
    des: "Chairman microphone unit for Novo Conference System. ",
    category: " Conference System",
    description:
      "The NCS-5C is a desktop base discussion microphone unit designed as a chairman microphone for Novo Conference System. The microphone unit is engineered in high-quality aluminum alloy die cast housing. The chairman microphone connects to the NCS-110 central control unit via network category 5 cable. The unit includes a shotgun Gooseneck microphone with a cardioid unidirectional pickup capsule. The microphone comes with an illuminating ring at the bottom which indicates the active status. The chairman unit prioritizes itself over all other delegate units via override button.",
    technicalSpecification: {
      "Polar Pattern": "Cardioid Condenser",
      "Frequency Response": "50Hz-20KHz",
      Sensitivity: "20dBV (+/-3dB) (0dB=1Vpa, f=1KHz)",
      "Maximum Input Sound Pressure Level": "110dB (THD < 5%, f=1KHz)",
      "Signal to Noise Ratio (A-weighted)": ">100dBA (THD<1%, f=1KHz)",
      Distortion: "<0.1%",
      "Power Supply": "24V DC (supplied by the controller)",
      "Power Consumption": "0.72W",
      "Unit Interface": "RJ45 network input",
      Weight: "about 670g",
      "Dimensions (mm)": "147 (L) x 115 (W) x 218 (H)",
    },
    keyFeatures: [
      "Wire Integrated Gooseneck microphone with cardioid pickup capsule for clear and full sound quality",
      "High sensitivity and good long-distance pick-up effect",
      "Speaking status indicator: Red when mute, Green when speaking",
      "Convenient loop through cabling via standard Cat5 category",
      "In-built mute button with status LED",
      "Aluminum finished metallic die-cast body",
    ],
    image: require("../../assets/Novo/NCS-5C/NCS-5C top.png"),
    image1: require("../../assets/Novo/NCS-5C/NCS-5C top.png"),
    image2: require("../../assets/Novo/NCS-5C/NCS-5C back.png"),
    download: [
      {
        title: "NCS 5C Datasheet",
        pdf: ncspdf3,
      },
    ],
  },
  {
    name: "NCS 5D",
    productName: "Novo",
    des: "Delegate Microphone Unit for Novo Conference System. ",
    category: "Audio Conference System",
    description:
      "The NCS-5D is a desktop base discussion microphone unit designed as a delegate microphone for Novo Conference System. The microphone unit is engineered in high-quality aluminum alloy die cast housing. The delegate microphone connects to the NCS-110 central control unit via network category 5 cable. The unit includes a shotgun Gooseneck microphone with a cardioid unidirectional pickup capsule. The microphone comes with an illuminating ring at the bottom which indicates the active status. The delegate unit has a mute button to control its integrated microphone.",
    technicalSpecification: {
      "Polar pattern": "Cardioid Condenser",
      "Frequency response": "50Hz-20KHz",
      Sensitivity: "20dBV (+/-3dB) (0db=1Vpa, f=1Khz)",
      "Maximum input sound pressure level": "110dB (THD < 5%, f=1KHz)",
      "Signal to noise ratio (A-weighted)": ">100dBA (THD<1%, f=1KHz)",
      Distortion: "<0.1%",
      "Power supply": "24V DC (supplied by the controller)",
      "Power consumption": "0.72W",
      "Unit interface": "RJ45 network input",
      Weight: "about 670g",
      "Dimensions (mm)": "147 (L) X 115 (W) x 218 (H)",
    },
    keyFeatures: [
      "Integrated Gooseneck microphone with cardioid pickup capsule for clear and full sound quality, high sensitivity, and a good long-distance pick-up effect.",
      "Speaking status indicator: Red when mute, Green when speaking.",
      "Convenient loop-through cabling via standard cat5 category.",
      "NCS-5C chairman unit has the priority to override all delegate units.",
      "Aluminum finished metallic die-cast body.",
    ],
    image: require("../../assets/Novo/NCS-5D/NCS-5D top.png"),
    image1: require("../../assets/Novo/NCS-5D/NCS-5D front.png"),
    image2: require("../../assets/Novo/NCS-5D/NCS-5D side1.png"),
    download: [
      {
        title: "NCS 5D Datasheet",
        pdf: ncspdf4,
      },
    ],
  },
  {
    name: "NCS-110CU",
    productName: "Novo",
    des: "Central Controller Unit for Novo Conference System.",
    category: " Conference System",
    description:
      "NCS110-CU is the central controller unit of Novo Conference System which powers, manages and controls the whole conference system. The latest microprocessor chip automatically detects the number of units connected, current conference mode, controls cameras connected to it and many other functions which are required in a modern-day conference room. It’s built in Audio Gain Processing circuit prevents unnecessary feedback and improves the vocal intelligibility of the room. Everything functions and parameter in this unit can be easily accessed through the front OLED display which shows the microphone status, operating mode, number of active speakers etc. Housed in a pressed steel body with 1RU size NCS110-CU is designed based on “everything built into hardware” where the complete system is made to run like an appliance without much technical support required.",
    technicalSpecification: {
      "Number of microphones supported": 110,
      "Maximum Power consumption": "100W/24V",
      "XLR balanced carbon maximum output level": "14.6dBu",
      "Maximum output level of the 6.35mm balance socket": "14.6dBu",
      "Microphone unit extension": "Anti-drop 9P aviation plug x 1",
      "Network port (female)": "XLR",
      "Video input port": "BNC x 4",
      "Video output port": "BNC x 1",
      "Video interface impedance": "75Ω",
      "Dante interface": "RJ45 x 1",
      "Audio output": "XLRM x 1, 6.35mm jack x 1, RCA Lotus x 1",
      "Audio input": "RCA Lotus x 1",
      "Pin serial port control interface": "DB9 Female plug x 1",
      "Rs-232 control protocol interface": "5P Phoenix terminal X 1",
      "Rs-422 control protocol interface": "5P Phoenix terminal X 1",
      "Supply voltage": "90-264V AC or 127-370V AC, 47-63Hz",
      Weight: "about 2.3kg",
      "Dimensions (mm)": "410(W) X 43(H) x 206(D)",
    },
    keyFeatures: [
      "4 kinds of conference mode selection: FIFO, Automatic (with limiting time), Request and Sound activated.",
      "The central controller can select the number of speakers, and active 2/3/4 microphones simultaneously.",
      "Support camera tracking, through the serial port RS-232 or RS-422 communication protocol, controls up to 4 cameras, compatible with VISCA, PELCO-D, and PELCO-P 3 kinds of camera control code protocol.",
      "With 4 Video inputs and 1 output port, SD and HD SDI video inputs and outputs are supported.",
      "Supports Dante digital output, flexible to connect with various Dante enabled solutions.",
      "With automatic gain audio processing circuit, prevent feedback effectively and improve the clarity of the whole system.",
      "It is easy to expand and change the configuration. The central controller connects up to 40 microphone units. If connected to the NEB-124W can connect up to 110 microphone units.",
      "With aux input and output, you can plug external audio sources to add multiple functionality like recording, PA/Music inclusion, etc.",
    ],
    image: require("../../assets/Novo/NCS-110cu/NCS110 front.png"),
    image1: require("../../assets/Novo/NCS-110cu/NCS110 top.png"),
    image2: require("../../assets/Novo/NCS-110cu/NCS110 side1.png"),
    download: [
      {
        title: "NCS 110CU Datasheet",
        pdf: ncspdf5,
      },
      {
        title: "NCS Quick Installation Guide",
        pdf: "https://drive.google.com/file/d/1TcwSrXYy1GmSskZhp7y8AVdbEJmoDJTd/view?usp=sharing",
      },
      {
        title: "NCS-110CU User Manual",
        pdf: "https://drive.google.com/file/d/1lkuPx89o_Ic2sol2vxNCwt9WQM8kZLwh/view?usp=sharing",
      },
    ],
  },
  {
    name: "NEB-124W",
    productName: "Novo",
    des: "Power repeater for up to 30 units for Novo Conference System",
    category: "Audio Conference System",
    description:
      "The NEB-124W is a repeater used in the Novo Conference System to provide a power connection for up to 30 microphone units. It enhances the stability of the entire system and increases the number of microphone units used which otherwise can’t be powered by NCS 110CU alone. NEB-124W repeater is made of pressed steel metal housing, which is sturdy, and stylish. It can be fixed on the wall or the bottom of the table etc., which is convenient for installation purposes.",
    technicalSpecification: {
      Model: "NEB-124W",
      AC: "110-240V, 50/60Hz",
      OutputVoltage: "24V",
      MaximumOutputPower: "72W",
      InputOutputTerminal: "RJ45 Network port",
      Length: 220,
      Width: 140,
      Height: 48,
      Weight: "950 gm",
    },
    keyFeatures: [
      "Built-in 72W/24V power module, providing power for 30 microphone units.",
      "Two network cable connectors connected by a dedicated network cable, with one serving as the input and the other as the output.",
      "Built-in fan for effective heat dissipation when operating at high power.",
      "Working indicator: Red light during standby, and blue light when actively working.",
    ],
    image: require("../../assets/Novo/NEB-124/NEB-124W front.png"),
    image1: require("../../assets/Novo/NEB-124/NEB-124W top.png"),
    image2: require("../../assets/Novo/NEB-124/NEB-124W back1.png"),
    download: [
      {
        title: "NEB 124W Datasheet",
        pdf: ncspdf6,
      },
    ],
  },

  // Vector
  {
    name: "VC1",
    // name: "AR 31",
    id: "1",
    productName: "Vector",
    des: "Wired Microphones",
    category: "Wired Microphones",
    pdf: "https://drive.google.com/file/d/1IwBmpPCoi_xdWr7BndDxg0VL2Emn-KnW/view?usp=sharing",
    description: ` The VC1 is a multi-purpose, professional vocal condenser micro
      phone designed for stage, studio, and broadcast applications. It 
      has a smooth and accurate frequency response and resistance to 
      feedback. With its tight and controlled cardioid polar pattern, the 
      VC1 helps isolate vocals on stage, while handling high SPLs with
      out distortion.It is also versatile enough to capture instruments 
      such as guitars, woodwinds, brasses, percussion toy`,
    technicalSpecification: {
      "Capsule Type": "Backplane polarized electret condenser ",
      "Polar Pattern": "Cardioid Unidirectional",
      "Frequency Response": "50Hz - 20KHz  (+- 3dB)",
      Sensitivity: "44 dB (1V/Pa)",
      " Output Impedance": "280 ohms (use symbol)",
      "Max sound pressure level": "20 dB (1KHz at 1% T.H.D",
      "ynamic Dynamic Range(Typical)": "115dB，1KHz at max sound pressure",
      "S/N Rati": ">72dB(A)，1KHz at 1Pa",
      "Phantom Power requirement": "11 – 52 VDC",
      "Putput Connector": "Built-in 3-pin XLR-M Type",
      Size: "Size:  φ51mm x 190mm ",
    },
    keyFeatures: [
      "-Multi-purpose condenser microphone for professional audi",
      "Designed for smooth vocal pickup",
      "Flat frequency response in vocal range",
      "High strength metal grille with mesh protection",
      "Rugged metal body with matte paint",
    ],
    image: vc1,
    image1: "https://i.ibb.co/BLXB694/vc1-p.png",
    image2: "https://i.ibb.co/DYNbTbL/vc1-with-cable.png",

    download: [
      {
        title: "VC1 Datasheet",
        pdf: "https://drive.google.com/file/d/1z11DLimhnncD2u3DtI35oVD5Os2BmisH/view?usp=drive_link",
      },
    ],
  },
  {
    name: "VD1",
    productName: "Vector",
    des: "Wired Microphones",
    category: "Wired Microphones",
    description: `With its clear and accurate sound reproduction, resistance to feed
      back, and ability to handle high SPLs without distortion, the VD1 is 
      a game-changer in the world of vocal microphones. The VD1's 
      tight, uniform pattern effectively isolates vocals from the instru
      ments on stage, ensuring crystal-clear sound quality. Its wide fre
      quency range of 50 Hz – 14 kHz, coupled guarantees a precise and 
      exceptional voice response. Furthermore, VD1 Microphone comes 
      with reliable reed type mute switch which avoids switching noise 
      during the performances. Even on large or professional systems, 
      the VD1 retains its true sound, making it a versatile choice for any 
      setup. VD1 microphone is made of high strength metal enclosure 
      with steel grille and mesh protection layer`,
    technicalSpecification: {
      "Capsule Type": "Dynamic coi",
      "Polar Pattern": " Cardioid Unidirectional",
      "Frequency Response": "50Hz - 14KHz (+- 3dB)",
      Sensitivity: "58 dB (1V/Pa)",
      "Output Impedance": "600 ohms",
      "Max sound pressure leve": "dB (1KHz at 1% T.H.D)",
      "utput Output Connector": "Built-in 3-pin XLR-M Type",
      Size: "φ51mm x 190mm ",
      "weight: Approximatel": "343g",
    },
    keyFeatures: [
      "Multi-purpose dynamic vocal microphone for professional audio",
      "Tailored vocal emphasized frequency response",
      "High quality reed type mute switch",
      "High strength metal grille with mesh protection",
      "Rugged metal body with matte paint",
    ],
    image: "  https://i.ibb.co/KcnJkJc/vd-1.png",

    image1: "https://i.ibb.co/M63BdHV/vd1s-1.png",

    image2: "https://i.ibb.co/ZzMvybQ/Vd1.png",

    download: [
      {
        title: "VD1 Datasheet",
        pdf: "https://drive.google.com/file/d/1lv5nb2TtFRcsuJil5Ah2HaqahxyMdv9D/view?usp=drive_link",
      },
    ],
  },
  {
    name: "VI512D-GN",
    productName: "Vector",

    des: "Wired Microphones",
    category: "Wired Microphones",
    description:
      "Introducing the VI512D-GN Desktop Gooseneck Microphone: a versatile solution for public addressing, conferencing, and podcasting. Its 12-inch dual-flex gooseneck allows precise positioning, while the stable desktop base ensures reliability. Featuring touch-sensitive LED talk switch and toggle for  Push to Talk  or Push to Lock modes, alongside a bass roll-off filter for clear sound quality. Ideal for professionals seeking premium audio performance and convenience.",
    technicalSpecification: {
      "Element ": " Electret-Condenser",
      "Polar pattern": " Cardioid",
      "Frequency Range": " 80-18,000 Hz",
      "Open circuit sensitivity":
        "55dB±3 (0 dB=1V/ microbar 1000 Hz indicated by open circuit)",
      Impedance: "100+30% Ohms (at 1000 Hz) ",
      "Maximum Input Sound Level": "115dB SPL, 1KHz at 1%T.H.D.",
      "Dynamic Range": "82dB 1KHz at Max SPL",
      "Signal-To-Noise Ratio": "61dB 1KHz at 1Pa ",
      "Operating Voltage": "Phantom Power 11-52V DC, 2mA typical",
      "Switch Type": "Membrane-Touching switch      ",
      "ON/OFF Switch Function": "PTT and PTL selectable      ",
      "Bass Filter Switch": "Flat, Roll-Off      ",
    },
    keyFeatures: [
      "12” Gooseneck length with dual flex ",
      "Attached desktop base",
      "In built touch based mute switch with LED indication",
      "Toggle Mute Switch – PTT/PTL (Push to Talk/ Push to Lock)",
      "Bass roll off filter",
      "Brass Enclosure with coiled steel gooseneck tube",
    ],
    image: "https://i.ibb.co/SyG1bYn/2.png",
    image1: "https://i.ibb.co/cNFSkCW/1.png",
    image2: "https://i.ibb.co/58Rs0LW/VI512-D-GN.png",

    download: [
      {
        title: "VI512D-GN Datasheet",
        pdf: "https://drive.google.com/file/d/1KGWM_d4VON6VV4hsj4uanZhPT2xUqDvU/view?usp=drive_link",
      },
    ],
  },
  {
    name: "VI522GN",
    productName: "Vector",

    des: "Wired Microphones",
    category: "Wired Microphones",
    description: ` VI522GN is a professional gooseneck microphone specifically 
      designed for podium speeches, presentations, meetings, and 
      teleconferencing sessions.With their miniature gooseneck design,
       these condenser microphones offer exceptional sound quality and 
      clarity.Whether you're addressing a large audience or participat
      ing in a virtual meeting, these professional microphones ensure 
      that your voice is hear loud and clear. The Microphones offers a 
      wide frequency range of 30 Hz - 18 kHz, ensuring that  every detail 
      in your audio is captured and reproduced accurately, resulting in a 
      supe
       superior listening experience.The Gooseneck 
      Microphone is equipped with dual flex neck and in built 
      Mic Preamp circuitry in the base of the XLR. The Mic Preamp is 
      internally balanced to avoid any Hum n Noise from external 
      sources. The Microphone also contains a Low Frequency Roll off 
      switch along with 10dB attenuation switch for added control over 
      the input sources. The Microphone is made up of high quality 
      brass construction body with black matte finish paint on top.`,
    technicalSpecification: {
      Element: "Back Electret-Condenser",
      " Polar Pattern": "Cardioid",
      " Frequency Range": "30 Hz ~ 18,000 Hz ",
      " Low Frequency Roll-off": "180Hz, 12dB/octave",
      Impedanc: "250Ω ± 30% ( at 1000 Hz)",
      "Open Circuit Sensitivity":
        "-57 dB ± 3 dB (0 dB=1 V/microbar 1000 Hz indicated by open circuit)",
      "Maximum Input Sound Maximum Input Sound Leve":
        "115dB SPL, 1KHz at 1%T.H.D.",
      "Dynamic Range": "82dB 1KHz at Max SPL",
      "Signal-To-Noise Ratio": "61dB 1KHz at 1Pa",
      "Operating Voltage": "Phantom Power 11 – 52 V DC, 2mA typical",
      Switch: "Flat, Roll-off 10dB pad (Nominal)",
    },
    keyFeatures: [
      " 22” Gooseneck length with dual flex design",
      " Natural audio reproduction",
      " Low Noise Mic Pre Amp Circuitr",
      " Low Frequency Roll off switch",
      "0/-10 dB Attenuation Switch",
      " Superior Brass Body with black matte finish",
    ],
    image: "https://i.ibb.co/g9cwHCT/VI522gn-2.png",
    image1: "https://i.ibb.co/qMhfBLw/1.png",
    image2: "https://i.ibb.co/44hL6r9/2.png",

    download: [
      {
        title: "VI522GN Datasheet",
        pdf: "https://drive.google.com/file/d/1i_ow71omScGJXX5rqM5AOI9tOKU-0n94/view?usp=drive_link",
      },
    ],
  },
  {
    name: "VI1516GN-L",
    productName: "Vector",

    des: "Wired Microphones",
    category: "Wired Microphones",
    description:
      "Introducing the VI516GN-L gooseneck microphone, ideal for speeches, presentations, and meetings. Featuring a bright LED light ring for clear status indication, it ensures visibility whether On or Off. With a condenser capsule and uni-directional polar pattern, it offers superior sensitivity and accuracy. Equipped with a wide frequency range of 50 Hz - 18 kHz and built-in Mic Preamp circuitry for clear audio reproduction. Can be mounted permanently with optional desktop base stand or flange-mounted with shock mount.      ",
    technicalSpecification: {
      "ELEMENT ": "Back Electret - Condenser ",
      "POLAR PATTERN ": "Cardioid",
      "FREQUENCY RESPONSE ": "50 - 18,000 Hz",
      "OPEN CIRCUIT SENSITIVITY ": "- 65 dB / microbar ",
      IMPEDANCE: " 200 Ohms ",
      "MAXIMUM INPUT SOUND LEVEL": "145 dB SPL, 1 kHz at 1% T.H.D. ",
      "DYNAMIC RANGE ": "116 dB, 1 kHz at Max SPL",
      "SIGNAL-TO-NOISE RATIO ": "65 dB, 1 kHz at 1Pa ",
      "OPERATING VOLTAGE": "Phantom Power 48 - 52 V DC, 6mA typical ",
      "OUTPUT CONNECTOR": "Integral 3 - pin XLRM-type",
      DIMENSIONS: "19x426mm ",
    },
    keyFeatures: [
      '16" Gooseneck length      ',
      "LED Light Ring",
      "Low Noise Mic Pre Amp Circuitry",
      "Wide Frequency Range with natural audio reproduction",
    ],
    image: "https://i.ibb.co/X2Mqtt2/VI516gn-l.png",
    image1: "https://i.ibb.co/Kmxyf2y/2.png",
    image2: VI1516GN,

    download: [
      {
        title: "VI1516GN-L Datasheet",
        pdf: "https://drive.google.com/file/d/1lT_-I2udixe5jbuySYzPZbpD55EllBtO/view?usp=drive_link",
      },
    ],
  },
  {
    name: "VI60DB",
    productName: "Vector",

    des: "Wired Microphones",
    category: "Wired Microphones",
    description:
      "The VI60DB boundary microphone: ideal for conferencing and public addressing. Exceptionally sensitive with long-distance pickup, it suits various uses including theatre and instruments. Wide frequency range of 50 Hz - 16 kHz ensures clear and accurate sound. Constructed with precision and durability, featuring zinc casing and steel mesh grill. Includes mini XLR connector and 5m cable for convenience.",
    technicalSpecification: {
      "ELEMENT  ": " Back Electret - Condenser  ",
      "POLAR PATTERN": "Cardioid  ",
      "FREQUENCY RESPONSE  ": "50 - 16,000 Hz  ",
      "OPEN CIRCUIT SENSITIVITY": " -58 dB ± 3dB ( at 1KHz )  ",
      "IMPEDANCE      ": "100Ω ± 30%  ",
      "MAXIMUM INPUT SOUND LEVEL": "120 dB SPL, 1 kHz at 1% T.H.D.  ",
      "DYNAMIC RANGE  ": "93 dB, 1 kHz at Max SPL  ",
      "SIGNAL-TO-NOISE RATIO ": "67 dB, 1 kHz at 1Pa   ",
      "OPERATING VOLTAGE   ": " Phantom Power 11 - 52 V DC, 2mA typical  ",
      "SWITCH TYPE   ": "Membrane-Touching switch      ",
      "ON/OFF SWITCH FUNCTION ": "PTT and PTL selectable      ",
      "BASS FILTER SWITCH ": "Flat , Roll-Off      ",
    },
    keyFeatures: [
      "Low Profile Boundary Microphone",
      "In built touch based mute switch with LED indication",
      "Toggle Switch - PTM/PTL (Push to Mute / Push to Lock)      ",
      "High gain selectable switch",
      "Zinc Coated Die Cast Enclosure",
    ],
    image: "https://i.ibb.co/BTxcw0M/VI60DB.png",
    image1: "https://i.ibb.co/yNnqzpM/1.png",
    image2: "https://i.ibb.co/N1NFW8W/2.png",

    download: [
      {
        title: "VI60DB Datasheet",
        pdf: "https://drive.google.com/file/d/1FrfJCjgnI1yoVKbVYvrOiOtY9kotlLqb/view?usp=drive_link",
      },
    ],
  },
  {
    name: "VI518GN",
    productName: "Vector",

    des: "Wired Microphones",
    category: "Wired Microphones",
    description: ` VI518GN is a professional gooseneck microphone specifically 
      designed for podium speeches, presentations, meetings, and 
      teleconferencing sessions.With their miniature gooseneck design,
       these condenser microphones offer exceptional sound quality and 
      clarity.Whether you're addressing a large audience or participat
      ing in a virtual meeting, these professional microphones ensure 
      that your voice is hear loud and clear. The Microphones offers a 
      wide frequency range of 30 Hz - 18 kHz, ensuring that  every detail 
      in your audio is captured and reproduced accurately, resulting in a 
      supe
       superior listening experience.The Gooseneck 
      Microphone is equipped with dual flex neck and in built 
      Mic Preamp circuitry in the base of the XLR. The Mic Preamp is 
      internally balanced to avoid any Hum n Noise from external 
      sources. The Microphone also contains a Low Frequency Roll off 
      switch along with 10dB attenuation switch for added control over 
      the input sources. The Microphone is made up of high quality 
      brass construction body with black matte finish paint on top`,
    technicalSpecification: {
      " Element": "Back Electret-Condenser",
      "Polar Patter": "Cardioi",
      "Frequency Range": "30 Hz ~ 18,000 Hz",
      "Low Frequency Roll-of": "180Hz, 12dB/octave",
      Impedance: "250Ω ± 30% ( at 1000 Hz)",
      " Open Circuit Sensitivi":
        "-57 dB ± 3 dB (0 dB=1 V/microbar 1000 Hz indicated by open circuit",
      "ximum Input Sound Maximum Input Sound Leve":
        "115dB SPL, 1KHz at 1%T.H.D.",
      "Dynamic Range": "82dB 1KHz at Max SPL",
      "Signal-To-Noise Ratio": "61dB 1KHz at 1Pa",
      "Operating Voltage": "Phantom Power 11 – 52 V DC, 2mA typica",
      Switch: "Flat, Roll-off 10dB pad (Nominal",
    },
    keyFeatures: [
      " 18” Gooseneck length with dual flex design",
      "Natural audio reproduction",
      "Low Noise Mic Pre Amp Circuitry     ",
      " Low Frequency Roll off switch      ",
      "0/-10 dB Attenuation Switch",
      "Superior Brass Body with black matte finish",
    ],
    image: "https://i.ibb.co/BrVfkXH/VI518gn.png",
    image1: "https://i.ibb.co/qMhfBLw/1.png",
    image2: "https://i.ibb.co/44hL6r9/2.png",

    download: [
      {
        title: "VI60DB Datasheet",
        pdf: "https://drive.google.com/file/d/1FrfJCjgnI1yoVKbVYvrOiOtY9kotlLqb/view?usp=drive_link",
      },
    ],
  },
  {
    name: "NCS-5CF",
    productName: "Novo",

    des: "microphone unit designed",
    category: "Novo",
    description: ` NCS-5CF is a flush mount type discussion microphone unit designed as a chairman unit for Novo Conference System. The microphone unit is engineered in high-quality copper zinc die cast housing. The microphone connects to the NCS-110CU central control unit via network category 5/6 cable. The unit comes with a screw type detachable microphone port to connect the gooseneck microphone in it.  The chairman unit prioritizes it self over all other delegate units via override button`,
    technicalSpecification: {
      " Polar pattern": "Cardioid Condenser",
      "Frequency response": "50Hz-20KHz",
      Sensitivity: "-20dBV (+/-3dB) (0db=1Vpa, f=1Khz)",
      "Maximum input sound pressure level": "11OdB (THD < 5%, f=1KHz)",
      "Signal to noise ratio (A-weighted)": "> IOOdBA (THD<1%, f=1 Khz)",
      " Distortion": "<0.1%",
      "Power supply": "24V DC (supplied by the controller)",
      "Power consumption": "0.72W",
      "Unit interface": "RJ45 network input",
      Weight: "about 345g (without microphone)",
      "Dimension (mm)": "100 (L) x 42 (W) x 90(D)",
    },
    keyFeatures: [
      " Recessed enclosure design for discreet looks",
      "High Quality Copper Zinc alloy die-cast body",
      "Detachable Screw Type Gooseneck Microphone     ",
      " Backlit Speak & Override button      ",
    ],
    image: ncs5cfback,
    image1: ncs5cffront,
    image2: ncs1,

    download: [
      {
        title: "NCS-5CF Datasheet",
        pdf: ncspdf1,
      },
    ],
  },
  {
    name: "NCS-5DF",
    productName: "Novo",

    des: "microphone unit designed",
    category: "Novo",
    description: ` NCS-5DF is a flush mount type discussion microphone unit designed as a delegate unit for Novo Conference System. The microphone unit is engineered in high-quality copper zinc alloy die cast housing. The microphone connects to the NCS-110CU central control unit via network category 5/6 cable. The unit comes with a screw type detachable microphone port to connect thegooseneck microphone in it. The delegate unit has a mute button to control its integrated microphone`,
    technicalSpecification: {
      " Polar pattern": "Cardioid Condenser",
      "Frequency response": "50Hz-20KHz",
      Sensitivity: "-20dBV (+/-3dB) (0db=1Vpa, f=1Khz)",
      "Maximum input sound pressure level": "11OdB (THD < 5%, f=1KHz)",
      "Signal to noise ratio (A-weighted)": "> IOOdBA (THD<1%, f=1 Khz)",
      " Distortion": "<0.1%",
      "Power supply": "24V DC (supplied by the controller)",
      "Power consumption": "0.72W",
      "Unit interface": "RJ45 network input",
      Weight: "about 345g (without microphone)",
      "Dimension (mm)": "100 (L) x 42 (W) x 90(D)",
    },
    keyFeatures: [
      " Recessed enclosure design for discreet looks",
      "High Quality Copper Zinc alloy die-cast body",
      "Detachable Screw Type Gooseneck Microphone     ",
      " Backlit Speak & button     ",
    ],
    image: ncs5dfback,
    image1: ncs5dffront,
    image2: ncs1,

    download: [
      {
        title: "NCS-5DF Datasheet",
        pdf: ncspdf2,
      },
    ],
  },

  {
    name: "AD4S",
    productName: "ADS",

    des: "Antenna Distribution System",
    category: "Antenna Distribution System",
    description: ` The AD4S by Erthpot represents a significant advancement for expanding wireless microphone setups. This four-way active high-performance antenna distribution system simplifies signal distribution from a single pair of antennas to multiple receivers, offering enhanced convenience. By enabling the sharing of one pair of diversity antennas with up to four Arche Wireless receivers (Single or Dual), it effectively mitigates signal dropouts caused by antenna interference. Key to its functionality are the four internal power supplies, each providing a reliable 12V/800mA power output to connected receivers, ensuring consistent performance across the setup. Bid farewell to signal interruptions and welcome seamless audio transmission with the AD4S antenna distribution system.
    `,
    technicalSpecification: {
      "Frequency Range": "470 — 900 MHz",
      "Gain of Output": "0 dB, ±3 dB (A Typical)",
      "Input Impedance": "50Ω (A Typical)",
      "Output Impedance": "50Ω (A Typical)",
      "Power Supply of Antenna": "+8V,150mA",
      "Power Supply of Receiver": "+12V DC, 800mA",
      "Power Supply": "AC 100-240V, 50/60 Hz",
      "Dimension (mm)": "410(W) x 206(H) x 43(H)",
      Weight: "About 2.5 Kg",
    },
    keyFeatures: [
      "Provides diversity antenna distribution to four single or dual receivers",
      "Wide band frequency band allows stable RF signal reliability",
      "Provides DC power distribution for four Arche Wireless receivers",
      "RF Signal Output Gain of 3dB to compensate for insertion loss",
      "Comes bundled with all necessary RF & power cables",
      "Full Metal housing unit with matte paint finish",
    ],
    image: ad4s2,
    image1: ad4s1,
    image2: ad4s3,

    download: [
      {
        title: "AD4S Datasheet",
        pdf: ads4spdf,
      },
    ],
  },

  {
    name: "AD49-W",
    productName: "ADS",

    des: "Active Wide Band Directional Antenna",
    category: "Antenna Distribution System",
    description: ` AD49-W is a wide band directional antenna (470 - 900 MHz) designed to be used with professional wireless microphones. Its in built RF amplifier allows signal gain of up to 13 dB to compensate for long cable runs. The specifically designed antenna's midrib and side vein receptors allow a higher polarization of 6dBi towards its cardioid pick up coverage. The AD49-W can be fixed to the microphone stand or suspended from the ceiling, or it can be fixed to the wall using the integrated rotatable stand. The lightweight metal structure allows rugged use over indoor or outdoor working conditions.`,
    technicalSpecification: {
      "Antenna Frequency Range": "480MHz~950Mhz",
      "Amplifier Frequency Range": "480MHz~950MHZ",
      "Antenna Directivity": "Cardiord",
      "Antenna Polarization": "Line",
      "Antenna Gain": "≥6.5dB",
      "Antenna Standing Wave Ratio": "<2",
      "Amplifier Gain": "5dB/13dB",
      "Amplifier Low Noise": "<0.45dB",
      "Amplifier OIP3": ">38dBm",
      "External Power Supply": "6-12V DC",
      Current: "<50mA",
      "Connector Port": "BNC female",
      "Dimensions (mm)": "323(L) x 306(W)",
    },
    keyFeatures: [
      "Wide band directional antenna",
      "In built RF amplifier of 5/13 dB selectable gain",
      "Cardioid pick up coverage with 6dBi gain",
      "Lightweight in design",
      "Suitable for both indoor and outdoor conditions",
    ],
    image: ad491,
    image1: ad492,
    image2: ad493,

    download: [
      {
        title: "NCS-5DF Datasheet",
        pdf: ad49wpdf,
      },
    ],
  },

  {
    name: "AD21",
    productName: "ADS",

    des: "Antenna Splitter / Combiner",
    category: "Antenna Distribution System",
    description: ` AD21 is a two-way passive antenna splitter/ combiner compatible with Erthpot's Arche Wireless Systems. This handy device can split an incoming signal into two outgoing signals, or it can combine two incoming signals into one signal, giving you even more flexibility and options for improving your signal strength.`,
    technicalSpecification: {
      "Frequency Range": "400MHz - 1000MHz",
      Attenuation: "-3.5dB, ±1dB",
      Insulate: ">-20dB",
      Impedance: "50 Ohm",
      "Voltage/Current": "+50V DC/1.50A",
      Connector: "BNC",
      "Size(mm)": "65(W) x 50(L) x 20(H)",
      Weight: "About 35g",
    },
    keyFeatures: [
      "Wideband frequency support over entire UHF range",
      "Allows the user to expand coverage and signal stability",
      "Supports all 50-ohm Coaxial RF Cables",
    ],
    image: ad211,
    image1: ad212,
    image2: ad213,

    download: [
      {
        title: "NCS-5DF Datasheet",
        pdf: ad21pdf,
      },
    ],
  },
];

export default dummyProducts;
