import React from "react";
import image from "../assets/founder/electrical-master-equipment-on-house-plans-2023-11-27-05-02-40-utc.jpg";
const Indian = () => {
  return (
    <div className="w-full ">
      <div className=" grid grid-cols-1">
        <div
          className="relative h-screen group cursor-pointer overflow-hidden"
          style={{ maxHeight: "55vh" }}
        >
          <img
            className="h-full w-full group-hover:cursor-pointer object-cover duration-700 group-hover:duration-700 overflow-hidden transition-transform transform-gpu group-hover:scale-110 filter blur-[2px]"
            src={image}
            alt=""
          />

          <div className="w-full ">
            <div className="flex-col w-[80%] mx-auto mt-8 group flex items-center justify-center absolute inset-0">
              <h3 className="text-3xl font-bold text-center border-[#00AFAC]">
                {" "}
                Purely{" "}
                <span className="group-hover:text-[#00AFAC]">Indian </span>{" "}
                Audio Innovation.
              </h3>
              <p className="mt-5 text-center text-[15px]   drop-shadow-lg  font-medium font-anand">
                Erthpot, a Make in India endeavor, strives to deliver top-notch
                audio technologies globally, initiative to embodying the
                principles of groundedness,
                <br />
                humility, and honesty in customer interactions, earning the
                moniker "Erthpot".
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Indian;
