import React from "react";
import "./About.css";
import { motion } from "framer-motion";
import image from "../../assets/founder/abstract-shape-of-colorfulwires-or-pipe-on-a-dark-2023-11-27-05-04-28-utc.jpg";

import b1 from "../../assets/About_pic/cropped-shot-of-a-group-of-people-holding-plants-g-2023-11-27-05-36-40-utc.jpg";
import b2 from "../../assets/About_pic/diverse-group-of-engineers-or-architects-having-a-2023-11-27-05-21-49-utc.jpg";
import b3 from "../../assets/About_pic/load-of-diy-tools-and-parts-2023-11-27-05-23-18-utc.jpg";
import b4 from "../../assets/About_pic/technology-futuristic-abstract-background-neon-lig-2023-11-27-05-36-11-utc.jpg";

import Foundation from "./Foundation";

const About = () => {
  return (
    <>
      <div className="bg-black -mt-10  font-anand pt-20">
       
        <div className="w-full py-10  bg-black ">
          <div className=" grid grid-cols-1">
            <div className="relative h-screen  group cursor-pointer overflow-hidden  " style={{maxHeight: "55vh"}}>
              <img
                className="h-full w-full  group-hover:cursor-pointer group-hover:grayscale-0 object-cover duration-700 group-hover:duration-700 overflow-hidden transition-transform transform-gpu group- filter blur-[2px]"
                src={image}
                alt=""
              />

              <div className="w-full ">
                <div className="flex-col w-[80%] mx-auto mt-8 group flex items-center justify-center absolute inset-0">
                  <h3 class="text-3xl font-bold text-gray-100 font-anand text-center">
                    What Makes Us Erthpot
                  </h3>
                  <p class="mt-5 text-center font-bold text-[15px] text-white  drop-shadow-lg   font-anand">
                    We are a brand with creativity, technology, craftsmanship,
                    and socio-responsibility. We make
                  </p>
                  <p class=" text-center text-[15px] text-white  drop-shadow-lg font-bold font-anand">
                    audio products that add value to your life.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
       

        <div className="w-full pb-5 mt-5">
          <div className="w-[90%]  mx-auto grid   md:grid-cols-2  gap-2 md:h-screen[80%] " style={{maxHeight: "99vh"}}>
            <motion.div class="flex justify-center items-center  p-3  ">
              <div className="group">
                <div className="">
                  <span className="text-2xl text-white font-bold">
                    Our{" "}
                    <span className="text-2xl md:text-2xl  text-white font-bold ">
                      Responsibility{" "}
                    </span>
                  </span>
                  <p className="text-2xl  text-white font-bold">
                    Towards{" "}
                    <span className="text-2xl md:text-2xl  text-white font-bold ">
                      Environment
                    </span>{" "}
                    & Ecology{" "}
                  </p>

                  <div className="w-full py-5 font-bold ">
                    <p className="text-gray-300 md:text-[15px]  font-anand">
                      The AV industry produces a lot of E-Waste which not only
                      pollutes the environment but is equally toxic for
                      different life forms. We at Erthpot, focus on building
                      sustainable products. We try to minimize the peripheral
                      waste produced during the manufacturing of our products,
                      making us more environment-friendly.
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>

            <motion.div class=" md:w-[96%] w-full flex justify-end items-center   " >
              <div className="md:h-[450px] md:w-[450px]   bg-contain overflow-hidden  hover:bg-black">
                <img
                  src={b1}
                  className="hover:cursor-pointer hover:scale-110 hover:opacity-50 hover:duration-700 overflow-hidden transition-transform transform-gpu object-cover bg-contain h-full w-full rounded-tl-none rounded-br-none "
                  alt="img"
                  
                />
              </div>
            </motion.div>
          </div>
          <div className="w-[90%]  mx-auto grid   md:grid-cols-2  gap-2   md:h-screen[80%] mt-5" >
            <motion.div class=" w-full md:pl-[1rem] flex justify-start items-center   ">
              <div className="md:h-[450px] md:w-[450px]   bg-contain overflow-hidden  hover:bg-black">
                <img
                  src={b2}
                  className="hover:cursor-pointer object-cover hover:opacity-50 bg-contain h-full w-full rounded-tl-none rounded-br-none hover:scale-110 hover:duration-700 overflow-hidden transition-transform transform-gpu"
                  alt="img"
                />
              </div>
            </motion.div>
            <motion.div class="flex justify-center items-center  p-3 pr-[1rem] ">
              <div className="group">
                <div className=" text-right">
                  <span className="text-2xl text-white font-bold">
                    Passion for Manufacturing
                    <span className="text-2xl md:text-2xl  text-white font-bold"></span>
                  </span>
                  <p className="text-2xl  text-white font-bold">
                    Best Audio
                    <span className="text-2xl md:text-2xl  text-white font-bold "></span>{" "}
                    Products !
                  </p>

                  <div className="w-full py-5 font-bold ">
                    <p className="text-gray-300 md:text-[15px]  font-anand">
                      We aim to provide products created under the strict
                      observation of our audio experts. We strive to bring
                      positive change. Our vision is to create a brand that
                      emits sustainability and values it in every breath.
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
          <div className="w-[90%]  mx-auto grid   md:grid-cols-2  gap-2  md:h-screen[80%] " style={{maxHeight: "113vh"}}>
            <motion.div class="flex justify-center items-center  p-3  " >
              <div className="group">
                <div className="">
                  <span className="text-2xl text-white font-bold">
                    Our{" "}
                    <span className="text-2xl md:text-2xl  text-white font-bold ">
                      Responsibility{" "}
                    </span>
                  </span>
                  <p className="text-2xl  text-white font-bold">
                    Towards{" "}
                    <span className="text-2xl md:text-2xl  text-white font-bold ">
                      Environment
                    </span>{" "}
                    & Ecology{" "}
                  </p>

                  <div className="w-full py-5 font-bold ">
                    <p className="text-gray-300 md:text-[15px]  font-anand">
                      The AV industry produces a lot of E-Waste which not only
                      pollutes the environment but is equally toxic for
                      different life forms. We at Erthpot, focus on building
                      sustainable products. We try to minimize the peripheral
                      waste produced during the manufacturing of our products,
                      making us more environment-friendly.
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>

            <motion.div class=" md:w-[96%] w-full flex justify-end items-center   " >
              <div className="md:h-[450px] md:w-[450px]   bg-contain overflow-hidden  hover:bg-black">
                <img
                  src={b3}
                  className="hover:cursor-pointer object-cover bg-contain h-full w-full rounded-tl-none rounded-br-none hover:opacity-50 hover:scale-110 hover:duration-700 overflow-hidden transition-transform transform-gpu"
                  alt="img"
                />
              </div>
            </motion.div>
          </div>
          <div className="w-[90%]  mx-auto grid   md:grid-cols-2  gap-2  md:h-screen[80%] mt-5" >
            <motion.div class=" w-full flex justify-start items-center   ">
              <div className="md:h-[450px] md:w-[450px] md:pl-[1rem]  bg-contain overflow-hidden  hover:bg-black">
                <img
                  src={b4}
                  className="hover:cursor-pointer object-cover bg-contain h-full w-full rounded-tl-none rounded-br-none hover:opacity-50 hover:scale-110 hover:duration-700 overflow-hidden transition-transform transform-gpu "
                  alt="img"
                />
              </div>
            </motion.div>
            <motion.div class="flex justify-center items-center  p-3  ">
              <div className="group">
                <div className="text-right">
                  <span className="text-2xl text-white font-bold">
                    Better{" "}
                    <span className="text-2xl md:text-2xl  text-white font-bold ">
                      Technology & Innovation in Audio
                    </span>
                  </span>
                  <p className="text-2xl  text-white font-bold">
                    Manufacturing Industry{" "}
                  </p>

                  <div className="w-full py-5 font-bold ">
                    <p className="text-gray-300 md:text-[15px]  font-anand">
                      Better Technology & Innovation in Audio manufacturing
                      Industry The ecosystem for electronics manufacturing in
                      India is still in its nascent stage. Finding the right
                      parts suppliers and OEM designers is difficult as of now.
                      Luckily in the software and embedded design part, India
                      has a huge pool of talented engineers. We are constantly
                      improving the quality of vendors and service providers to
                      develop better products that stand out in
                      the global market.
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>

        <Foundation />
      </div>
    </>
  );
};

export default About;
