import React from "react";
import Banner from "./Banner/Banner";
import Action from "./Action/Action";
import Details from "./Technology/Details";
import Story from "./Story/Story";
import Client from "./ClientLogo/Client";
import Indian from "./Indian";

const Landing = () => {
  return (
    <div>
      <Banner />
      <Indian />
      <Story />
      <Details />
      <Action />
      <Client />
    </div>
  );
};

export default Landing;
