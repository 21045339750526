import React from "react";
import { Link } from "react-router-dom";
import kyerimg from "../../assets/Banner/keyer.png";
import vactor_img from "../../assets/Banner/Vector.png";
import ads_img from "../../assets/ads/ads.png";

const HeroBanner2 = (props) => {
  const largeText1 = props.largeText1;
  const buttonText = props.buttonText;
  const desc = props.desc;

  const largeText2 = props.largeText2;
  const product = props.largeText1;
  const heroBanner = {
    image: {
      _type: "image",
      asset: {
        _ref:
          largeText1 === "Keyer "
            ? kyerimg
            : largeText1 === "ADS "
            ? ads_img
            : vactor_img,
      },
    },
  };

  return (
    <div className=" w-full bg-black py-2">
      <Link to={`/allproducts/${largeText1}`}>
        <div className=" w-[95%] mx-auto flex bg-gray-200 py-2 pr-2 justify-evenly items-center" style={{height:"auto"}}>
          <div className="flex justify-center items-center  md:h-[440px] pr-2">
            <img
              src={heroBanner.image.asset._ref}
              className=" inset-0  md:h-[480px] object-cover"
              alt="mic"
              style={{
                width: largeText1 === "ADS " ? "28vw" :"35vw",
                height: largeText1 === "ADS " ? "19vw" : "15vw",
              }}
            />
          </div>
          <div className="">
            <div className="pl-3">
              <h1 className="uppercase md:text-5xl text-lg font-extrabold font-anand">
                {largeText1}
              </h1>
              <span className="text-[#00AFAC] font-medium md:text-3xl text-[12px] font-anand ">
                {largeText1 === "Keyer "
                  ? "Digital Processing"
                  : largeText1 === "ADS "
                  ? "Antenna"
                  : "Wired"}
              </span>
              <span className="text-black md:text-3xl text-[12px]  font-anand px-1">
                {largeText1 === "ADS " ? "Distribution System" : largeText2}
              </span>
              <div className=" ">
                <Link to={`/allproducts/${largeText1}`}>
                  <button className="hover:bg-[#00AFAC] hover:text-gray-100 hover:duration-700 duration-700 uppercase text-center border-2 border-[#00AFAC] rounded-md px-3  pb-[1px]  md:mt-4 text-[#00AFAC] font-semibold md:text-[12px] text-[10px]">
                    {buttonText}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default HeroBanner2;
