import React, { useEffect, useState } from "react";
import "./SingleProduct.css";
import "./Card.css";
import "./Products.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams, Link } from "react-router-dom";
import {
  FaFacebookSquare,
  FaLinkedin,
  FaPinterest,
  FaTwitterSquare,
  FaTelegram,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";

import { Swiper, SwiperSlide } from "swiper/react";
import "./drop.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import p1 from "../../assets/Arche Wireless Series.png";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import upArrow from "../../assets/up-arrow.png";
import downArrow from "../../assets/down-arrow.png";

import dummyProducts from "./DummyProducts.jsx";

const ProductCard = ({
  product: { name, category, image, productName },
  index,
}) => {
  const handleClick = (product) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div class="card">
      <Link
        to={`/product/${productName}/${name}`}
        onClick={() => handleClick({ name, category })}
        className={` ${index % 2 === 0 ? "even" : "odd"} bg-gray-800 card `}
      >
        {image && image.length > 0 && (
          <img src={image} className="card__img px-6" alt={name} />
        )}

        <div></div>

        <p
          class="card__sizeTitle text-black font-bold"
          style={{ textAlign: "center", zIndex: 1, marginTop: "16rem" }}
        >
          {name}
        </p>
      </Link>
    </div>
  );
};

const SingleProductPage = ({ handleClick }) => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { categoryName, productName } = useParams();

  const [currentSlide, setCurrentSlide] = useState(0);

  const myobject1 = {
    Arche: [
      {
        title: "The Arche Wireless Microphones",
        // images: ["image1.jpg", "image2.jpg", "image3.jpg"],
        image: require("../../assets/Arche Wireless Series.png"),
        description:
          "Arche wireless Microphone systems are designed with one goal in mind – Efficient Connectivity. What you get is a stable and reliable RF connectivity right out of the box. With its wide operating frequency range 522-936MHz and RF boost (40mW) in the entire UHF spectrum, these wireless microphones give flexibility and long working range.  Whether it’s a seminar hall, a live stage, or a classroom, working with Arche Wireless systems is a cake walk",
      },
      {
        title: "AFS (Automatic Frequency Selection)",
        image: require("../../assets/Specification/Archie1.webp"),
        description:
          "Press the “AFS” (Auto Frequency Selection) button 3 sec and the receiver will auto-scan and lock on to an open, interference-free frequency",
      },
      {
        title: "Compatible systems",
        image: require("../../assets/Specification/Archei1.webp"),
        description:
          "Maximum 24 sets can be used simultaneously by using multiple frequency bands in one venue.",
      },
      {
        title: "IR Infrared Sync",
        image: require("../../assets/Specification/archie3.webp"),
        description:
          "Press [IR] button to automatically pair the receiver frequency with the transmitter.",
      },
    ],
    Novo: [
      {
        title: "NOVO Conference System ",
        // images: ["image1.jpg", "image2.jpg", "image3.jpg"],
        image: require("../../assets/Specification/Novo/Novo0.webp"),
        description:
          "Novo Conference System is a wired simple and affordable conference discussion system with exquisite design focusing on plug n play operation. Its various applications include small to medium conference rooms, assembly halls & classrooms. ",
      },
      {
        title: "Wired loop In-Out connectivity ",
        image: require("../../assets/Specification/Novo/Novo1.webp"),
        description:
          "Wired loop In-Out connectivity to microphone units on standard Cat5e Network Cables",
      },
      {
        title: "System Expandability",
        image: require("../../assets/Specification/Novo/Novo2.webp"),
        description:
          "System Expandability of up to 110 units using external power supply for discussion units          ",
      },
      {
        title: "IR Infrared Sync",
        image: require("../../assets/Specification/Novo/Novo3.webp"),
        description:
          "Press [IR] button to automatically pair the receiver frequency with the transmitter.",
      },
    ],
    Vector: [
      // Similarly structured objects for 'vector'
      {
        title: "The Arche Wireless Microphones",
        // images: ["image1.jpg", "image2.jpg", "image3.jpg"],
        image: require("../../assets/Arche Wireless Series.png"),
        description:
          "Arche wireless Microphone systems are designed with one goal in mind – Efficient Connectivity. What you get is a stable and reliable RF connectivity right out of the box. With its wide operating frequency range 522-936MHz and RF boost (40mW) in the entire UHF spectrum, these wireless microphones give flexibility and long working range.  Whether it’s a seminar hall, a live stage, or a classroom, working with Arche Wireless systems is a cake walk",
      },
      {
        title: "AFS (Automatic Frequency Selection)",
        image: require("../../assets/Specification/Archie1.webp"),
        description:
          "Press the “AFS” (Auto Frequency Selection) button 3 sec and the receiver will auto-scan and lock on to an open, interference-free frequency",
      },
      {
        title: "Compatible systems",
        image: require("../../assets/Specification/Archei1.webp"),
        description:
          "Maximum 24 sets can be used simultaneously by using multiple frequency bands in one venue.",
      },
      {
        title: "IR Infrared Sync",
        image: require("../../assets/Specification/archie3.webp"),
        description:
          "Press [IR] button to automatically pair the receiver frequency with the transmitter.",
      },
    ],
    Keyer: [
      // Similarly structured objects for 'keyer'
      {
        title: "The Keyer Series ",
        // images: ["image1.jpg", "image2.jpg", "image3.jpg"],
        image: require("../../assets/Specification/Keyer/keyer-img-updated0.webp"),
        description: "Digital Processing & Control ",
      },
      {
        title: "Dante Audio Transmission",
        image: require("../../assets/Specification/Keyer/keyer1.webp"),
        description:
          "The Dante technology from Audinate provides smooth and hassle-free digital audio transmission over the local area networks.        Dante-enabled devices can share multiple channels of high-resolution digital audio with each other over a local area network.  ",
      },
      {
        title: "Acoustic Echo Cancellation ",
        image: require("../../assets/Specification/Keyer/keyer2.webp"),
        description:
          "The Keyer Series of DSP have advanced AEC algorithms to create much faster echo or double talk eliminations and thereby providing a disturbance-free Intelligent voice quality. ",
      },
      {
        title: "Advanced Audio Processing",
        image: require("../../assets/Specification/Keyer/Keyer3.webp"),
        description: `Keyer DSPs boast much-needed algorithms like

          ANS(Automatic Noise Suppression)
          AFC (Adaptive Feedback Control)
          AGC (Automatic Gain Control) to provide you with a comprehensible audio output.
          `,
      },
    ],
  };

  const myobject = myobject1[categoryName];
  console.log({ dummyProducts });

  const filteredProducts = dummyProducts.filter(
    (product) => product.productName === categoryName
  );

  const renderProductCards = () => {
    return filteredProducts.map((product, index) => (
      <div key={index} className="md:px-2">
        <ProductCard
          product={product}
          index={index}
          handleClick={() => handleClick(product)}
        />
      </div>
    ));
  };

  const sliderRef = React.useRef();

  const carouselSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
  };

  const closeAllAccordions = () => {
    setIsOpen1(false);
    setIsOpen2(false);
    setIsOpen3(false);
    setIsOpen4(false);
    setIsOpen5(false);
    setIsOpen6(false);
  };

  const toggleAccordion = (accordionNumber) => {
    closeAllAccordions();
    switch (accordionNumber) {
      case 1:
        setIsOpen1(!isOpen1);
        break;
      case 2:
        setIsOpen2(!isOpen2);
        break;
      case 3:
        setIsOpen3(!isOpen3);
        break;
      case 4:
        setIsOpen4(!isOpen4);
        break;
      case 5:
        setIsOpen5(!isOpen5);
        break;
      case 6:
        setIsOpen6(!isOpen6);
        break;
      default:
        break;
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const [product, setProduct] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  useEffect(() => {
    const foundProduct = dummyProducts.find((p) => p.name === productName);
    // console.log({foundProduct});

    if (foundProduct) {
      setProduct(foundProduct);
    } else {
      // Handle the case where the product is not found
      // console.error(Product with name ${productName} not found.);
    }
  }, [productName]);

  if (!product) {
    return <div className="mt-[20px]">Loading...</div>;
  }

  const handleDownloadClick = () => {
    if (product.download) {
      window.open(product.download, "_blank");
    }
  };

  return (
    <main className=" bg-gray-100  ">
      <div className="w-[80%]  mx-auto pt-20  ">
        <div class="grid  md:h-[80vh] grid-cols-1 md:grid-cols-2 gap-2   ">
          <div className=" w-full  h-full  flex justify-center flex-col ">
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              loop={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="  flex justify-center w-[80%] h-[300px]  p-2"
            >
              {/* 1 */}
              <SwiperSlide className=" ">
                <img
                  src={product.image}
                  className="h-full w-full object-contain  anand cursor-pointer  "
                />
              </SwiperSlide>
              {/* 2 */}
              <SwiperSlide className="  ">
                <img
                  src={product.image1}
                  className="h-full w-full object-contain  anand cursor-pointer"
                />
              </SwiperSlide>
              {/* 3 */}
              <SwiperSlide className="  ">
                <img
                  src={product.image2}
                  className="    h-full w-full object-contain anand cursor-pointer"
                />
              </SwiperSlide>
            </Swiper>
            <Swiper
              onClick={setThumbsSwiper}
              loop={true}
              spaceBetween={40}
              slidesPerView={3}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper  md:w-[50%] mx-auto mt-3 h-[90px]  w-full  overflow-hidden"
            >
              {/* 1 */}
              <SwiperSlide className="w-[300px] h-[320px] ">
                <img
                  className="object-contain h-full w-full bg-cover  cursor-pointer"
                  // className="object-contain h-[100px] scale-90  "
                  src={product.image}
                />
              </SwiperSlide>
              {/* 2 */}
              <SwiperSlide className="w-[300px] h-[320px]  ">
                <img
                  className="object-contain h-full w-full bg-cover  cursor-pointer"
                  src={product.image1}
                  alt="Description"
                />
              </SwiperSlide>
              {/* 3 */}
              <SwiperSlide className="w-[300px] h-[320px]   ">
                <img
                  className="object-contain h-full w-full bg-cover cursor-pointer "
                  src={product.image2}
                />
              </SwiperSlide>
            </Swiper>
          </div>

          <div class=" flex    mx-auto  items-center  justify-between  h-full ">
            {/* <div class=" flex   md:col-span-3 mx-auto  items-center pl-5 border-2 border-red-300 "> */}
            <div className=" w-full  md:h-[400px] font-anand mt-20">
              <h1 className="  text-shadow-none font-semibold text-gray-900 text-xl text-center md:text-4xl mb-3 hover-text">
                {product.name}
              </h1>
              <h4 className="text-gray-800   py-3 md:text-[16px]">
                <div className="mb-4">
                  <p className="text-center">{product.des}</p>
                </div>
                <div className="md:pr-10">
                  <p className="text-center text-[14px]">
                    {product.description}
                  </p>
                </div>
              </h4>

              <h1 className="text-center">
                <span className="text-sm font-medium text-gray-800">
                  Categories :{" "}
                  <span className="  text-gray-800 py-3 md:text-[14px] ">
                    {product.category}{" "}
                  </span>
                </span>
              </h1>
              <div className="flex justify-center py-8 gap-2 text-gray-800 text-center">
                <p className="text-gray-800 text-center text-[14px]">
                  Share :{" "}
                </p>
                <Link to="https://www.facebook.com/Erthpot" target="_blank">
                  <FaFacebookSquare className=" text-2xl hover:text-[#00b0ea] duration-700 hover:duration-700 cursor-pointer" />
                </Link>
                <Link to="https://www.instagram.com/Erthpot/" target="_blank">
                  <FaInstagram className=" text-2xl hover:text-[#00b0ea] duration-700 hover:duration-700 cursor-pointer" />
                </Link>
                <a
                  href="https://twitter.com/i/flow/login?redirect_after_login=%2Fearthpot"
                  target="_blank"
                >
                  <FaTwitterSquare className=" text-2xl hover:text-[#00b0ea] duration-700 hover:duration-700 cursor-pointer" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCVLSYcj6om_pv2p2ovFhe_Q"
                  target="_blank"
                >
                  <FaYoutube className=" text-2xl hover:text-[#00b0ea] duration-700 hover:duration-700 cursor-pointer" />
                </a>
                <a
                  href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHSLrqKr9H3vQAAAYxtAsP45b-BhAzG_gN2X-hxApwVxKsf41mGiF137jbygg5X4ghzPWcztSASQs7VH4ZUxla27OUs_hf_6QrDehtKE-jANHY018Uxx5jogGZYNcclrDWZbyM=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Ferthpot-electronics-private-limited%2F"
                  target="_blank"
                >
                  <FaLinkedin className=" text-2xl hover:text-[#00b0ea] duration-700 hover:duration-700 cursor-pointer" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" py-2 pt-2 md:min-h-screen font-anand">
        <div className="  overflow-hidden text-xs	 md:w-[70%] mx-auto  flex justify-center flex-col pt-8">
          {product.keyFeatures && product.keyFeatures.length > 0 && (
            <div
              className={isOpen2 ? "dropdown active" : "dropdown"}
              onClick={() => toggleAccordion(2)}
            >
              <div className="dropdown__text   uppercase text-center font-bold md:text-lg">
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Key Features{" "}
                  {isOpen2 ? (
                    <img
                      src={upArrow}
                      alt=""
                      style={{ width: 30, height: 16 }}
                    />
                  ) : (
                    <img
                      src={downArrow}
                      alt=""
                      style={{ width: 30, height: 16 }}
                    />
                  )}
                </span>
                {/* <div className="w-[100px] h-[1.8px] bg-black mx-auto mt-2"></div> */}
              </div>
              <div className="dropdown__items text-gray-800 px-7   md:w-[50%] mx-auto">
                <ul className="">
                  {product.keyFeatures.map((features, index) => (
                    <li className="list-disc" key={index}>
                      {features}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {product.technicalSpecification &&
            Object.keys(product.technicalSpecification).length > 0 && (
              <div
                className={isOpen3 ? "dropdown active" : "dropdown"}
                onClick={() => toggleAccordion(3)}
              >
                <div className="dropdown__text w-full md:text-lg uppercase text-center font-bold ">
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Technical Specifications{" "}
                    {isOpen3 ? (
                      <img
                        src={upArrow}
                        alt=""
                        style={{ width: 30, height: 16 }}
                      />
                    ) : (
                      <img
                        src={downArrow}
                        alt=""
                        style={{ width: 30, height: 16 }}
                      />
                    )}
                  </span>

                  {/* <div className="w-[100px] h-[1.8px] bg-black mx-auto mt-2"></div> */}
                </div>
                <div className="dropdown__items group-focus:block text-gray-800 px-4 ">
                  <div
                    className="relative  shadow-md   mx-auto md:w-[60%]"
                    style={{ maxHeight: "300px", overflowY: "auto" }}
                  >
                    <table className="  md:w-full  text-xs text-left rtl:text-right text-gray-800 dark:text-gray-400  border-2 border-[#00AFAC] ">
                      <tbody>
                        {Object.entries(product.technicalSpecification).map(
                          ([parameter, value], index) => (
                            <tr
                              key={index}
                              className=" border-b border-gray-200 dark:border-gray-700     "
                            >
                              <th
                                scope="row"
                                className="  p-2  font-medium whitespace-nowrap text-gray-800 dark:text-gray-800 border-b-2 border-[#00AFAC]  border-r-2 "
                              >
                                {parameter}:
                              </th>
                              <td className="  py-2 text-gray-800  border-b-2 border-[#00AFAC] ">
                                {value}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          {product.download && (
            <div
              className={isOpen4 ? "dropdown active" : "dropdown"}
              onClick={() => toggleAccordion(4)}
            >
              <div className="dropdown__text   mx-auto uppercase text-center font-bold md:text-lg flex justify-center flex-col ">
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Download{" "}
                  {isOpen4 ? (
                    <img
                      src={upArrow}
                      alt=""
                      style={{ width: 30, height: 16 }}
                    />
                  ) : (
                    <img
                      src={downArrow}
                      alt=""
                      style={{ width: 30, height: 16 }}
                    />
                  )}
                </span>

                <div className="dropdown__items text-gray-800 pt-5 flex  ">
                  {product.download.map((item, i) => (
                    <div className="  w-full ">
                      <a
                        target="_blank"
                        href={item.pdf}
                        className="md:text-[12px] text-[#00afac]  font-bold text-center"
                      >
                        {item.title}{" "}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className=" px-8  sm:py-10  pb-20">
          <h3 className="text-center font-bold text-black text-2xl md:pt-16 pt-16  uppercase ">
            Related Products
          </h3>
          <div className="md:w-full mx-auto w-full  flex justify-between ">
            <Slider
              className="md:w-[70%] w-full mx-auto flex justify-center "
              {...carouselSettings}
              ref={sliderRef}
            >
              {renderProductCards()}
            </Slider>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SingleProductPage;
