import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa6";
import { FiX } from "react-icons/fi";
import { IoSearch } from "react-icons/io5";
import { FaBars } from "react-icons/fa6";
import Whitelogo from "../../assets/white logo.png";
import "./Navbar.css";
const Navbar = () => {
  const [isScrolled, setScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState([]);
  const [showNotFound, setShowNotFound] = useState(false);
  const [results, showresults] = useState();
  const [formData, setFormData] = useState({
    productName: "",
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function performSearchLogic(data) {
    const Products = ["arche", "novo", "vector", "keyer"];
    const data1 = data.toLowerCase();
    for (let items of Products) {
      if (items === data1) {
        return data;
      }
    }

    return "";
  }

  function searchHandler(event) {
    event.preventDefault();
    setShowSearch(!showSearch);
    setMobileMenuOpen(!isMobileMenuOpen);
    setFormData({ productName: "" });

    const results = performSearchLogic(formData.productName);

    if (results === "Novo" || results === "novo") {
      navigate("/allproducts/Novo");
    } else if (results === "Arche" || results === "arche") {
      navigate("/allproducts/Arche");
    } else if (results === "Vector" || results === "vector") {
      navigate("/allproducts/Vector");
    } else if (results === "Keyer" || results === "keyer") {
      navigate("/allproducts/Keyer");
    }

    setSearchResults(results);

    setShowNotFound(results.length === 0);
  }

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setMobileMenuOpen(false); // Close the mobile menu on resize
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;

    setFormData({
      ...formData,
      productName: value,
    });
  };

  return (
    <nav
      className={`fixed top-0 w-full  z-50 ${
        isScrolled ? "bg-black  duration-700" : "bg-transparent duration-700  "
      }`}
    >
      <div className="flex justify-between items-center relative px-3 bg-black py-2 font-anand">
        <div className="flex items-center">
          <Link to="/">
            <img
              src={Whitelogo}
              alt="Logo"
              className="w-[170px]   mr-4 cursor-pointer  rounded-sm"
            />
          </Link>
        </div>

        {isMobile ? (
          <div className="flex items-center ml-auto duration-1000">
            <button
              className="text-black font-extrabold focus:outline-none"
              onClick={handleMobileMenuToggle}
            >
              <FaBars className="text-2xl text-white" />
            </button>
            {isMobileMenuOpen && (
              <div className="fixed top-0 left-0 h-full w-full bg-black text-gray-100 p-4 space-y-6 shadow-lg flex flex-col items-center duration-1000">
                <button
                  className=" focus:outline-none self-end duration-1000"
                  onClick={handleMobileMenuToggle}
                >
                  <FiX className="text-3xl mr-2 font-bold mt-2" />
                </button>
                <Link
                  onClick={handleMobileMenuToggle}
                  to="/"
                  className={`hover_animation cursor-pointer  hover:duration-700  p-1 rounded-md ${
                    isScrolled ? "" : ""
                  }`}
                >
                  Home
                </Link>
                <Link
                  onClick={handleMobileMenuToggle}
                  to="/about"
                  className={`hover_animation cursor-pointer   hover:duration-700    p-1 rounded-md ${
                    isScrolled ? "" : ""
                  }`}
                >
                  About
                </Link>

                <div className="relative group">
                  <div
                    className={`hover_animation cursor-pointer hover:duration-700  p-1 rounded-md  ${
                      isScrolled ? "" : ""
                    }`}
                  >
                    {/* Support */}
                    <span className="flex items-center gap-1">
                      Products
                      <FaAngleDown className="pt-1 text-base group-hover:rotate-180 group-hover:duration-700 duration-700" />
                    </span>
                  </div>
                  <div className="absolute top-8 left-[-20px] hidden bg-black text-gray-100   z-50 group-hover:block shadow-lg rounded-lg transition-all duration-300 ease-in-out w-[250px] ">
                    <div className="w-full flex flex-col px-4">
                      <Link
                        onClick={handleMobileMenuToggle}
                        to="/products"
                        className=" hover_animation cursor-pointer   hover:duration-700    p-1 rounded-md "
                      >
                        All Products
                      </Link>
                      <Link
                        onClick={handleMobileMenuToggle}
                        to="/allproducts/ADS"
                        className="hover_animation cursor-pointer   hover:duration-700    p-1 rounded-md "
                      >
                        Antenna Distribution System
                      </Link>

                      <Link
                        onClick={handleMobileMenuToggle}
                        to="/allproducts/Keyer"
                        className="hover_animation cursor-pointer   hover:duration-700    p-1 rounded-md "
                      >
                        DSP & Controls
                      </Link>
                      <Link
                        onClick={handleMobileMenuToggle}
                        to="/allproducts/Novo"
                        className="hover_animation cursor-pointer   hover:duration-700    p-1 rounded-md "
                      >
                        Audio Conference System
                      </Link>
                      <Link
                        onClick={handleMobileMenuToggle}
                        to="/allproducts/Arche"
                        className="hover_animation cursor-pointer   hover:duration-700    p-1 rounded-md "
                      >
                        Wireless Mircophones
                      </Link>
                      <Link
                        onClick={handleMobileMenuToggle}
                        to="/allproducts/Vector"
                        className="hover_animation cursor-pointer   hover:duration-700    p-1 rounded-md "
                      >
                        Wired Microphones
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="relative group">
                  <div
                    className={`hover_animation cursor-pointer hover:duration-700  p-1 rounded-md  ${
                      isScrolled ? "" : ""
                    }`}
                  >
                    <span className="flex items-center gap-1">
                      {" "}
                      Support{" "}
                      <FaAngleDown className="pt-1 text-base group-hover:rotate-180 group-hover:duration-700 duration-700" />
                    </span>
                  </div>
                  <div className="absolute top-8 left-[-20px] hidden bg-black text-gray-100   z-50 group-hover:block shadow-lg rounded-lg transition-all duration-300 ease-in-out w-[300px] ">
                    <div className="w-full flex flex-col px-4">
                      <Link
                        onClick={handleMobileMenuToggle}
                        to="/registration-warranty-form"
                        className=" hover_animation cursor-pointer   hover:duration-700    p-1 rounded-md "
                      >
                        Registration & Warranty Form
                      </Link>

                      <Link
                        onClick={handleMobileMenuToggle}
                        to="/partner-with-us"
                        className="hover_animation cursor-pointer   hover:duration-700    p-1 rounded-md "
                      >
                        Partner With Us
                      </Link>

                      <Link
                        onClick={handleMobileMenuToggle}
                        to="/design-solution"
                        className="hover_animation cursor-pointer   hover:duration-700    p-1 rounded-md "
                      >
                        Design-Solution
                      </Link>
                      <Link
                        onClick={handleMobileMenuToggle}
                        to="/installation-debugging-instruction"
                        className="hover_animation cursor-pointer   hover:duration-700    p-1 rounded-md "
                      >
                        Installation Debugging Instruction
                      </Link>
                      <Link
                        onClick={handleMobileMenuToggle}
                        to="/after-sales-support-troubleshooting"
                        className="hover_animation cursor-pointer   hover:duration-700    p-1 rounded-md "
                      >
                        After Sales Support Troubleshooting
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="relative group">
                  <div
                    to="/support"
                    className={`hover_animation cursor-pointer   hover:duration-700    p-1 rounded-md  ${
                      isScrolled ? "" : ""
                    }`}
                  >
                    <span className="flex items-center gap-1">
                      Press{" "}
                      <FaAngleDown className="pt-1 text-base group-hover:rotate-180 group-hover:duration-700 duration-700" />
                    </span>
                  </div>
                  <div className="absolute top-8 hidden left-[-20px] bg-black text-gray-100   z-50 group-hover:block shadow-lg rounded-lg transition-all duration-300 ease-in-out w-[200px] ">
                    <div className="w-full flex flex-col px-4">
                      <Link
                        onClick={handleMobileMenuToggle}
                        to="/blogs"
                        className="hover_animation cursor-pointer   hover:duration-700    p-1 rounded-md "
                      >
                        Blogs
                      </Link>

                      <Link
                        onClick={handleMobileMenuToggle}
                        to="/news-events"
                        className="hover_animation cursor-pointer   hover:duration-700    p-1 rounded-md "
                      >
                        News & Events{" "}
                      </Link>
                    </div>
                  </div>
                </div>
                <Link
                  to="/contact"
                  onClick={handleMobileMenuToggle}
                  className={` hover_animation cursor-pointer   hover:duration-700    p-1 rounded-md  ${
                    isScrolled ? "" : ""
                  }`}
                >
                  Contact
                </Link>

                <div className="flex items-center mt-4">
                  <div className="flex items-center space-x-5 group">
                    {showSearch ? (
                      <div className="flex gap-2 items-center  mr-3">
                        <IoSearch
                          onClick={() => {
                            setShowSearch(!showSearch);
                          }}
                          title="search"
                          className="text-2xl group-hover:text-[#00AFAC] text-white cursor-pointer"
                        />
                        <span className="group-hover:text-[#00AFAC] text-white cursor-pointer text-[13px]">
                          Search
                        </span>
                      </div>
                    ) : (
                      <form onSubmit={searchHandler}>
                        <div className=" top-5">
                          <input
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 pt-2 "
                            value={formData.productName}
                            onChange={handleInputChange}
                            type="text"
                            placeholder="Search products"
                          />
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div
              className={`z-50 flex items-center justify-center     space-x-12  rounded-full p-2  text-black  bg-cyan-800${
                isScrolled
                  ? "justify-center  z-50  bg-banner-background rounded-full p-2 space-x-12 "
                  : "space-x-4 "
              }`}
            >
              <Link
                to="/"
                onClick={scrollToTop}
                className={`hover_animation   text-[13px] text-gray-100  cursor-pointer hover:text-gray-100 hover:duration-700    p-1 rounded-md ${
                  isScrolled ? "" : ""
                }`}
              >
                Home
              </Link>
              <Link
                to="/about"
                onClick={scrollToTop}
                className={`hover_animation   text-[13px] cursor-pointer text-gray-100 hover:text-gray-100 hover:duration-700    p-1 rounded-md ${
                  isScrolled ? "text-white" : ""
                }`}
              >
                About
              </Link>
              <div className="relative group">
                <div
                  className={`hover_animation    text-[13px] cursor-pointer text-gray-100 hover:text-gray-100 hover:duration-700    p-1 rounded-md  ${
                    isScrolled ? "text-white" : ""
                  }`}
                >
                  <span className="flex items-center gap-1">
                    {" "}
                    Products
                    <FaAngleDown className="pt-1 text-base group-hover:rotate-180 group-hover:duration-700 duration-700" />
                  </span>
                </div>
                <div
                  className={`absolute top-7 py-3 hidden    z-50 group-hover:block  rounded-lg transition-all duration-300 ease-in-out w-[200px] 
                                             ${isScrolled ? "bg-black" : ""}
                                                                       `}
                >
                  <div className="w-full flex flex-col bg-black px-2 py-2">
                    <Link
                      to="/products"
                      onClick={scrollToTop}
                      className="hover_animation cursor-pointer text-gray-100  text-[13px] hover:text-gray-100 hover:duration-700    p-1 rounded-md  "
                    >
                      All Products
                    </Link>
                    <Link
                      to="/allproducts/ADS"
                      onClick={scrollToTop}
                      className="hover_animation text-gray-100   cursor-pointer text-[13px]  hover:text-gray-100 hover:duration-700    p-1 rounded-md  "
                    >
                      Antenna Distribution System
                    </Link>
                    <Link
                      to="/allproducts/Vector"
                      onClick={scrollToTop}
                      className="hover_animation text-gray-100   cursor-pointer text-[13px]  hover:text-gray-100 hover:duration-700    p-1 rounded-md  "
                    >
                      Wired Microphones
                    </Link>
                    <Link
                      to="/allproducts/Keyer"
                      onClick={scrollToTop}
                      className="hover_animation cursor-pointer text-gray-100  text-[13px] hover:text-gray-100 hover:duration-700    p-1 rounded-md  "
                    >
                      DSP & Controls
                    </Link>
                    <Link
                      to="/allproducts/Arche"
                      onClick={scrollToTop}
                      className="hover_animation text-gray-100   cursor-pointer text-[13px]  hover:text-gray-100 hover:duration-700    p-1 rounded-md  "
                    >
                      Wireless Mircophones
                    </Link>
                    <Link
                      to="/allproducts/Novo"
                      onClick={scrollToTop}
                      className="hover_animation text-gray-100   cursor-pointer text-[13px]  hover:text-gray-100 hover:duration-700    p-1 rounded-md "
                    >
                      Audio Conference System
                    </Link>
                  </div>
                </div>
              </div>
              <div className="relative group">
                <div
                  className={`hover_animation text-gray-100   text-[13px] cursor-pointer  hover:text-gray-100 hover:duration-700    p-1 rounded-md  ${
                    isScrolled ? "text-white" : ""
                  }`}
                >
                  <span className="flex items-center gap-1">
                    {" "}
                    Support{" "}
                    <FaAngleDown className="pt-1 text-base group-hover:rotate-180 group-hover:duration-700 duration-700" />
                  </span>
                </div>
                <div
                  className={`absolute top-7 py-3 hidden    z-50 group-hover:block  rounded-lg transition-all duration-300 ease-in-out w-[200px] 
                                             ${isScrolled ? "bg-black" : ""}
                                                                       `}
                >
                  <div className="w-full flex flex-col bg-black px-2 py-2">
                    <Link
                      onClick={scrollToTop}
                      to="/registration-warranty-form"
                      className="hover_animation cursor-pointer text-gray-100  text-[13px] hover:text-gray-100 hover:duration-700    p-1 rounded-md  "
                    >
                      Registration & Warranty Form
                    </Link>

                    <Link
                      onClick={scrollToTop}
                      to="/partner-with-us"
                      className="hover_animation text-gray-100   cursor-pointer text-[13px]  hover:text-gray-100 hover:duration-700    p-1 rounded-md "
                    >
                      Partner With Us
                    </Link>
                    <Link
                      onClick={scrollToTop}
                      to="/design-solution"
                      className="hover_animation text-gray-100   cursor-pointer text-[13px]  hover:text-gray-100 hover:duration-700    p-1 rounded-md  "
                    >
                      Design-solution
                    </Link>

                    <Link
                      onClick={scrollToTop}
                      to="/installation-debugging-instruction"
                      className="hover_animation text-gray-100   cursor-pointer text-[13px]  hover:text-gray-100 hover:duration-700    p-1 rounded-md  "
                    >
                      Installation Debugging Instruction
                    </Link>

                    <Link
                      onClick={scrollToTop}
                      to="/after-sales-support-troubleshooting"
                      className="hover_animation text-gray-100   cursor-pointer text-[13px]  hover:text-gray-100 hover:duration-700    p-1 rounded-md  "
                    >
                      After Sales Support Troubleshooting
                    </Link>
                  </div>
                </div>
              </div>
              <div className="relative group ">
                <div
                  // to="/support"
                  className={` hover_animation text-gray-100  cursor-pointer text-[13px]      p-1 rounded-md  ${
                    isScrolled ? "text-white" : ""
                  }`}
                >
                  <span className="flex items-center gap-1">
                    {" "}
                    Press{" "}
                    <FaAngleDown className="pt-1 text-base group-hover:rotate-180 group-hover:duration-700 duration-700" />
                  </span>
                </div>
                <div
                  className={`absolute top-7 py-3 hidden  text-[13px]  z-50 group-hover:block w-[150px] h-full ${
                    isScrolled ? "" : ""
                  }`}
                >
                  <div className="w-full flex flex-col bg-black p-3 gap-2">
                    <Link
                      to="/blogs"
                      onClick={scrollToTop}
                      className="hover_animation cursor-pointer  text-gray-100 text-[13px]   "
                    >
                      Blogs
                    </Link>

                    <Link
                      to="/news-events"
                      onClick={scrollToTop}
                      className=" hover_animation cursor-pointer text-gray-100  text-[13px]   "
                    >
                      News & Events{" "}
                    </Link>
                  </div>
                </div>
              </div>
              <Link
                to="/contact"
                onClick={scrollToTop}
                className={` hover_animation text-gray-100   text-[13px] cursor-pointer  hover:text-gray-100 hover:duration-700    p-1 rounded-md  ${
                  isScrolled ? "text-white" : ""
                }`}
              >
                Contact
              </Link>
            </div>
          </div>
        )}

        {isMobileMenuOpen && isMobile && (
          <div className="flex items-center ml-auto">
            <div className="mr-4"></div>
            <div></div>
          </div>
        )}

        {!isMobile && (
          <div className="flex items-center space-x-5 group">
            {showSearch ? (
              <div
                className="flex gap-2 items-center  mr-3"
                onClick={() => {
                  setShowSearch(!showSearch);
                }}
              >
                <IoSearch
                  onClick={() => {
                    setShowSearch(!showSearch);
                  }}
                  title="search"
                  className="text-2xl group-hover:text-[#00AFAC] text-white cursor-pointer"
                />
              </div>
            ) : (
              <form onSubmit={searchHandler}>
                <div className=" top-5">
                  <input
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 pt-2 "
                    value={formData.productName}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Search products"
                  />
                </div>
              </form>
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
