import React from "react";
import { Link } from "react-router-dom";
import novo_img from "../../assets/Banner/novo.png";
import arch_img from "../../assets/Banner/Arche.png";

const HeroBanner = (props) => {
  const largeText1 = props.largeText1;
  const buttonText = props.buttonText;
  const desc = props.desc;
  const largeText2 = props.largeText2;
  const product = props.largeText1;

  console.log({ props });

  const heroBanner = {
    image: {
      _type: "image",
      asset: {
        _ref: largeText1 === "Arche " ? arch_img : novo_img,
      },
    },
  };

  return (
    <div className=" w-full  gap-2 bg-black py-2 ">
      <Link to={`/allproducts/${largeText1}`}>
        <div className=" w-[95%] mx-auto flex  justify-evenly bg-gray-200" style={{height: "auto"}}>
          <div className="flex justify-center items-center py-2">
            <div className="pl-3">
              <h1 className="uppercase md:text-5xl text-lg font-extrabold font-anand">
                {largeText1}
              </h1>
              <span className="text-[#00AFAC] font-medium md:text-3xl text-[12px]  font-anand">
                {largeText1 === "Arche " ? "Wireless" : "Conference"}
              </span>
              <span className="text-black md:text-3xl text-[12px]  font-anand px-1">
                {largeText2}
              </span>
              <div className=" ">
                <Link to={`/allproducts/${largeText1}`}>
                  <button className="hover:bg-[#00AFAC] hover:text-gray-100 hover:duration-700 duration-700 uppercase text-center border-2 border-[#00AFAC] rounded-md px-3  pb-[1px]  md:mt-4 text-[#00AFAC] font-semibold md:text-[12px] text-[10px] ">
                    {buttonText}
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center  md:h-[440px] pr-2">
            <img
              src={heroBanner.image.asset._ref}
              className={largeText1 === "Novo " ? null : " inset-0  md:h-[480px] object-cover scale-110" }
              alt="mic"
              style={
                largeText1 === "Arche "
                  ? { width: "35vw", height: "15vw" }
                  : largeText1 === "Novo "
                  ? { width: "25vw", height: "25vw" }
                  : null
              }
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default HeroBanner;
