import React from "react";
import Slider from "react-slick";

const SimpleSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Default number of slides to show
    slidesToScroll: 2, // Default number of slides to scroll
    responsive: [
      {
        breakpoint: 1024, // Adjust settings for tablets and smaller screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Adjust settings for mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full bg-black px-3 pt-10">
      <div className="w-[90%] mx-auto pt-0 flex flex-col gap-10">
        <div className="group">
          <h1 className=" text-2xl font-bold text-center text-gray-100  font-anand">
            Products in{" "}
            <span className="group-hover:text-[#00AFAC] text-2xl font-bold">
              Action.
            </span>
          </h1>
        </div>
        <div className="w-full md:w-full mx-auto justify-center items-center">
          <Slider {...settings} className="">
            <div className="">
              <div className="relative h-[450px]  group cursor-pointer overflow-hidden hover:bg-black">
                <img
                  className="h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                  src="https://i.ibb.co/h7MYtn1/6-product-in-action.png"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
            <div className="">
              <div className="relative  h-[450px] group cursor-pointer overflow-hidden hover:bg-black">
                <img
                  className="w-full h-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                  src="https://i.ibb.co/nD4dqyj/5-product-in-action.png"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
            <div className="">
              <div className="relative h-[450px]  group cursor-pointer overflow-hidden  hover:bg-black">
                <img
                  className="h-full w-full group-hover:cursor-pointer  object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                  src="https://i.ibb.co/ggB7Q9P/4-product-in-action.png"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
            <div className="">
              <div className="relative h-[450px]  group cursor-pointer overflow-hidden  hover:bg-black">
                <img
                  className="h-full w-full  group-hover:cursor-pointer hover:opacity-50 object-cover duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                  src="https://i.ibb.co/5887ShF/3-product-in-action.png"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
            <div className="">
              <div className="relative h-[450px]  group cursor-pointer overflow-hidden  hover:bg-black">
                <img
                  className="h-full w-full  group-hover:cursor-pointer hover:opacity-50  object-cover duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                  src="https://i.ibb.co/thygnzm/2-product-in-action.png"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
            <div className="">
              <div className="relative h-[450px]  group cursor-pointer overflow-hidden  hover:bg-black">
                <img
                  className="h-full w-full group-hover:cursor-pointer hover:opacity-50  object-cover duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                  src="https://i.ibb.co/p25cD6N/1-product-in-action.png"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default SimpleSlider;
