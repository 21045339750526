import React from "react";
import kyerimg from "../../assets/Banner/keyer.png";
import vactor_img from "../../assets/Banner/Vector.png";
import novo_img from "../../assets/Banner/NCS-with-NC5C.D.png";
import arch_img from "../../assets/Banner/Arche Wireless Series.png";
import dummyProducts from "./DummyProducts";
import { useParams } from "react-router";
import ads_img from "../../assets/ads/ads1.png";

const FooterBanner = () => {
  const { productName } = useParams();

  const filteredProducts = dummyProducts.filter(
    (product) => product.productName === productName
  );

  let productImage;
  switch (productName) {
    case "Arche":
      productImage = arch_img;

      break;
    case "Keyer":
      productImage = kyerimg;

      break;
    case "Novo":
      productImage = novo_img;

      break;
    case "Vector":
      productImage = vactor_img;

      break;
    case "ADS":
      productImage = ads_img;

      break;
    default:
      productImage = arch_img;
  }

  // @media (min-width: 768px){
  //   .md\:h-screen {
  //       height: 100vh !important;
  //   }
  //   }

  return (
    <div className="w-full pt-10">
      {/* grid md:grid-cols-2 grid-cols-1 */}
      <div className="w-full md:h-full mx-auto  bg-gray-200 flex flex-col">
        <div className="flex justify-center items-center h-[300px] md:h-full w-full">
          <div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                marginTop: "10vh",
                justifyContent: "center",
              }}
            >
              <h1 className="uppercase md:text-5xl text-xl font-extrabold flex justify-center">
                {productName}
              </h1>
              <p className="flex justify-center md:text-5xl text-xl md:ml-3 ml-2">
                {filteredProducts[0].category}
              </p>
            </div>
            <div className="flex justify-center ">
              <a href={`${filteredProducts[0].pdf}`} target="_blank">
                <button className=" hover:bg-[#00AFAC] hover:text-gray-100 hover:duration-700 duration-700 uppercase text-center border-2 border-[#00AFAC] rounded-md px-3 pb-[1px] mt-4 text-[#00AFAC] font-semibold md:text-[14px] sm:text-[14px]">
                  Brochure
                </button>
              </a>
            </div>

            <div className="flex justify-center items-center mt-5 ">
              <img
                src={productImage}
                className={
                  productName === "ADS"
                    ? " inset-0 w-[40vw] h-full object-cover pb-10 "
                    : " inset-0 w-[100vw] h-full object-cover pb-10 "
                }
                alt="mic"
                // style={{ width: productName === "ADS" ? "35vw" : "80vw" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBanner;
