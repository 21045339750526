import React from "react";
import p1 from "../../assets/customer-support.png";

import p2 from "../../assets/high-quality.png";
import p3 from "../../assets/search.png";
import p4 from "../../assets/prestige.png";

const Details = () => {
    return (
        
        <div className="relative  w-full  bg-black overflow-hidden font-anand md:h-screen pt-10">
            <div className="w-[95%] mx-auto pt-0 h-full  flex item-center justify-center  flex-col">
                <div className="pb-10 group ">
                    <h1 className="text-2xl font-anand font-bold text-center text-gray-100 ">The Most</h1>
                    <h1 className="  text-center font-bold text-gray-100">
                        {" "}
                        <span className="group-hover:text-[#00AFAC] text-2xl font-bold"> Innovative </span>
                        <span className="text-2xl font-bold">Audio Brand.</span>
                    </h1>
                </div>
                <div className=" grid  md:grid-cols-4 sm:grid-cols-2 gap-5 h-[60%]">
                    <div className="cursor-pointer group bg-white hover:bg-[#00AFAE] hover:duration-1000 duration-1000 px-5 py-10 w-full h-full flex  justify-center flex-col items-center bg-opacity-80 hover:-translate-y-3">
                        <div className="text-center flex w-full justify-center text-[#00AFAE] group-hover:text-gray-100 duration-1000 group-hover:duration-1-00">
                            <img src={p3} className=" w-[60px]" loading="lazy" />
                        </div>
                        <h3 className=" text-center text-lg font-semibold group-hover:text-gray-100 group-hover:duration-1000 duration-1000 py-4">
                            Uncompromised Quality
                        </h3>
                        <p className="text-[16px] group-hover:text-gray-100 group-hover:duration-1000 duration-700" style={{textAlign: 'center'}}>
                            Erthpot products are designed & made with the highest quality of components available for
                            that segment.
                        </p>
                    </div>
                    <div className="cursor-pointer group bg-white hover:bg-[#00AFAE] hover:duration-500 duration-700 px-5 py-10 w-full h-full flex  justify-center flex-col items-center bg-opacity-80 hover:-translate-y-3">
                        <div className="text-center flex w-full justify-center text-[#00AFAE] group-hover:text-gray-100 duration-700 group-hover:duration-700">
                            <img src={p1} className=" w-[60px]" loading="lazy" />
                        </div>
                        <h3 className="text-center text-lg font-semibold group-hover:text-gray-100 group-hover:duration-700 duration-700 py-4">
                            Audio Consulting
                        </h3>
                        <p className="text-[16px] group-hover:text-gray-100 group-hover:duration-700 duration-700" style={{textAlign: 'center'}}>
                            The tech team of Erthpot ensures that the customers know and understand the technology
                            behind our products
                        </p>
                    </div>
                    <div className="cursor-pointer group bg-white hover:bg-[#00AFAE] hover:duration-500 duration-700 px-5 py-10  w-full h-full flex  justify-center flex-col items-center bg-opacity-80 hover:-translate-y-3">
                        <div className="text-center flex w-full justify-center text-[#00AFAE] group-hover:text-gray-100 duration-700 group-hover:duration-700">
                            <img src={p4} className=" w-[60px]" loading="lazy" />
                        </div>
                        <h3 className=" text-center text-lg font-semibold group-hover:text-gray-100 group-hover:duration-700 duration-700 py-4">
                            User Experience
                        </h3>
                        <p className="text-[16px] group-hover:text-gray-100 group-hover:duration-700 duration-700 " style={{textAlign: 'center'}}>
                            Since Erthpot is into simplify complex audio applications, the softwares & systems designed
                            by Erthpot team ensure smooth operations & easy to learn
                        </p>
                    </div>
                    <div className="cursor-pointer group bg-white hover:bg-[#00AFAE] hover:duration-500 duration-700 px-5 py-10 w-full h-full flex  justify-center flex-col items-center bg-opacity-80 hover:-translate-y-3">
                        <div className="text-center flex w-full justify-center text-[#00AFAE] group-hover:text-gray-100 duration-700 group-hover:duration-700">
                            <img src={p2} className=" w-[60px]" loading="lazy" />
                        </div>
                        <h3 className=" text-center text-lg font-semibold group-hover:text-gray-100 group-hover:duration-700 duration-700 py-4">
                            Brand Credibility
                        </h3>
                        <p className="text-[16px] group-hover:text-gray-100 group-hover:duration-700 duration-700 " style={{textAlign: 'center'}}>
                            Erthpot products are certified for quality & aspirations. The various certificates we hold
                            like StartUp India, Make in India, UDYAM & ISO speak for our commitment.
                        </p>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default Details;
