import React from "react";
import HeroBanner from "./HeroBanner";
import HeroBanner2 from "./HeroBanner2";
import kyerimg from "../../assets/Banner/keyer.png";
import novo_img from "../../assets/Banner/NCS-with-NC5C.D.png";
import arch_img from "../../assets/Banner/Arche.png";
import vactor_img from "../../assets/Banner/Vector.png";
import ads_img from "../../assets/ads/ads.png";

const Products = () => {
  const bannerProps = {
    largeText1: "Arche ",
    buttonText: "Explore",
    desc: "Best Wireless Microphones on the market",
    image: {
      asset: {
        _ref: arch_img,
      },
      _type: "image",
    },
    largeText2: "Microphones",
    _type: "banner",
  };
  const bannerProps2 = {
    largeText1: "Keyer ",
    buttonText: "Explore",
    desc: "Best Digital Processing & Control on the market",
    image: {
      asset: {
        _ref: kyerimg,
      },
      _type: "image",
    },
    largeText2: "& Control",
    _type: "banner",
  };
  const bannerProps3 = {
    largeText1: "Novo ",
    buttonText: "Explore",
    desc: "Best Audio Conference System on the market",
    image: {
      asset: {
        _ref: novo_img,
      },
      _type: "image",
    },
    largeText2: "System",
    _type: "banner",
  };
  const bannerProps4 = {
    largeText1: "Vector ",
    buttonText: "Explore",
    desc: "Best Vector Conference System on the market",
    image: {
      asset: {
        _ref: vactor_img,
      },
      _type: "image",
    },
    largeText2: "Microphones",
    _type: "banner",
  };

  const bannerProps5 = {
    largeText1: "ADS ",
    buttonText: "Explore",
    desc: "Wireless microphone setups with its advanced four-way antenna distribution system",
    image: {
      asset: {
        _ref: ads_img,
      },
      _type: "image",
    },
    largeText2: "Microphones",
    _type: "banner",
  };
  return (
    <>
      <div className="navbar-container py-14 bg-black">
        <HeroBanner2 {...bannerProps5} />
        <HeroBanner {...bannerProps} />
        <HeroBanner2 {...bannerProps2} />
        <HeroBanner {...bannerProps3} />
        <HeroBanner2 {...bannerProps4} />
      </div>
    </>
  );
};

export default Products;
