import React from "react";
import bg_img from "../../assets/NCS with Banner.png";

const Banner = () => {
    return (
        // <div className="w-full bg-red-100 pt-20 ">
        // <div className="relative h-screen w-full  ">
        <div className="relative w-full bg-gray-100 pt-20  ">
            <div className="w-[90%] mx-auto   h-screen grid md:grid-cols-2 grid-cols-1 lg:grid-cols-2 gap-2 ">
                <div className=" w-full h-full flex justify-center items-center">
                    <div className=" md:mb-20 text-gray-800">
                        <h1 className="text-5xl  font-anand  ">elevate your</h1>
                        <h1 className="text-5xl  font-anand">audio.</h1>
                    </div>
                </div>

                <div className="relative   cursor-pointer overflow-hidden flex justify-center items-center h-full pt-20  ">
                    <img
                        className=" h-full  w-full  group-hover:cursor-pointer group-hover:grayscale-0 object-contain duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                        src={bg_img}
                        alt=""
                        loading="lazy"
                    />
                </div>
            </div>
        </div>
    );
};

export default Banner;
