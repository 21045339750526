import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import "./Products.css";
import "./Card.css";
import FooterBanner from "./CenterBanner";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import auditorium from "../../assets/auditorium.jpg";
import multiSpeech from "../../assets/multiple speech modes.jpg";
import systemExpandibility from "../../assets/system expandibility.jpg";
import loopIn from "../../assets/loop in out.jpg";
import compatible from "../../assets/compatible-systems.png";

import dummyProducts from "./DummyProducts";

import adsfeature1 from "../../assets/ads/feature/Less Antennae More Coverage.jpg";
import adsfeature2 from "../../assets/ads/feature/Enhanced Signal Reception.jpg";
import adsfeature3 from "../../assets/ads/feature/Power distribution simplified.jpeg";

import application1 from "../../assets/ads/Application/Experience Centre.jpg";
import application2 from "../../assets/ads/Application/Stadium.png";

const ProductCard = ({
  product: { name, category, image, productName },
  index,
}) => {
  const handleClick = () => {};
  return (
    <div class="card">
      <Link
        to={`/product/${productName}/${name}`}
        onClick={() => handleClick({ name, category })}
        className={` ${index % 2 === 0 ? "even" : "odd"} bg-gray-800 p-3  `}
      >
        {image && image.length > 0 && (
          <img
            src={image}
            className={
              productName === "ADS" ? "card__img px-6" : "card__img px-2"
            }
            alt={name}
          />
        )}
      </Link>

      <p
        class="card__sizeTitle w-full text-black font-bold"
        style={{ textAlign: "center", zIndex: 1, marginTop: "15rem" }}
      >
        {name}
      </p>
    </div>
  );
};

const AllProducts = ({ handleClick }) => {
  const { productName } = useParams();
  const [currentSlide, setCurrentSlide] = useState(0);

  const myobject1 = {
    Arche: [
      {
        title: "AFS (Automatic Frequency Selection)",
        heading: "The Arche Wireless Microphones",
        heading_description:
          "Arche wireless Microphone systems are designed with one goal in mind – Efficient Connectivity. What you get is a stable and reliable RF connectivity right out of the box. With its wide operating frequency range 522-936MHz and RF boost (40mW) in the entire UHF spectrum, these wireless microphones give flexibility and long working range.  Whether it’s a seminar hall, a live stage, or a classroom, working with Arche Wireless systems is a cake walk",
        // image: require("../../assets/Specification/Archie1.webp"),
        // image: audioTransmission,
        image: "https://i.ibb.co/GkPWFFS/anti-interference.jpg",
        description:
          "Press the “AFS” (Auto Frequency Selection) button 3 sec and the receiver will auto-scan and lock on to an open, interference-free frequency",
      },
      {
        title: "Compatible systems",
        // image: require("../../assets/Specification/Archei1.webp"),
        // image:advancedAudio,
        // image: acousticEcho,
        image: compatible,
        description:
          "Maximum 24 sets can be used simultaneously by using multiple frequency bands in one venue.",
      },
      {
        title: "IR Infrared Sync",
        // image: require("../../assets/Specification/archie3.webp"),
        // image: advancedAudio,
        image: "https://i.ibb.co/ngdcsT1/infra-red.jpg",
        description:
          "Press [IR] button to automatically pair the receiver frequency with the transmitter.",
      },
      {
        title: "Full Band Transmitter",
        // image: powerfulMotherboard,
        image: "https://i.ibb.co/0X6305f/full-band-transmitter.jpg",
        description:
          "134MHz full band transmitter allows pairing with the receiver irrespective of frequency band.",
      },
      {
        title: "Energy Conservation",
        // image: powerfulMotherboard,
        image: "https://i.ibb.co/wW35qh2/energy-conservation.jpg",
        description:
          "Low RF power mode gives twice the battery life of up to 14 hours*. (On AA Alkaline Batteries)",
      },
    ],
    Novo: [
      // {
      //   title: "NOVO Conference System",
      //   // images: ["image1.jpg", "image2.jpg", "image3.jpg"],
      //   image: require("../../assets/Specification/Novo/Novo0.webp"),
      //   // image:audioTransmission,
      //   description:
      //     "Novo Conference System is a wired simple and affordable conference discussion system with exquisite design focusing on plug n play operation. Its various applications include small to medium conference rooms, assembly halls & classrooms. ",
      // },
      {
        title: "Wired loop In-Out connectivity ",
        heading_description:
          "Novo Conference System is a wired simple and affordable conference discussion system with exquisite design focusing on plug n play operation. Its various applications include small to medium conference rooms, assembly halls & classrooms. ",
        heading: "NOVO Conference System",
        // image: require("../../assets/Specification/Novo/Novo1.webp"),
        // image: acousticEcho,
        // image: audioTransmission,
        image: loopIn,
        description:
          "Wired loop In-Out connectivity to microphone units on standard Cat5e Network Cables",
      },
      {
        title: "System Expandability",
        // image: require("../../assets/Specification/Novo/Novo2.webp"),
        image: systemExpandibility,
        // image: acousticEcho,
        description:
          "System Expandability of up to 110 units using external power supply for discussion units          ",
      },
      {
        title: "Multiple Speech Modes",
        // image: require("../../assets/Specification/Novo/Novo3.webp"),
        // image: advancedAudio,
        image: multiSpeech,
        description:
          "FIFO, Automatic (with a time limit), Request and Sound Activated.",
      },
    ],
    Vector: [
      // Similarly structured objects for 'vector'
      // {
      //   // title: "Anand",
      //   title: "VECTOR SERIES (Wired Microphones)",
      //   // images: ["image1.jpg", "image2.jpg", "image3.jpg"],
      //   image: require("../../assets/Arche Wireless Series.png"),

      //   description:
      //     "When it comes to capturing speech, there is no one-size-fits-all solution. Each venue is unique, just like the words that are spoken within it. That's why the Vector® family offers a range of versatile microphone types and accessories, allowing you to customize your setup and ensure that the audience is enveloped in pure, clear voice.",
      // },
      {
        title: "Vocals first",
        heading_description:
          "When it comes to capturing speech, there is no one-size-fits-all solution. Each venue is unique, just like the words that are spoken within it. That's why the Vector® family offers a range of versatile microphone types and accessories, allowing you to customize your setup and ensure that the audience is enveloped in pure, clear voice.",
        heading: "VECTOR SERIES (Wired Microphones)",
        image: "https://i.ibb.co/DbF1phN/vocals-first.jpg",
        // image: audioTransmission,
        description:
          "The precision engineering of Vector microphone cartridges ensures optimal vocal clarity and intelligibility. Every word you speak is captured with utmost accuracy, enhancing your communication and ensuring that your message is conveyed exactly as intended.",
      },

      {
        title: "Designed to appeal",
        // image: require("../../assets/Specification/archie3.webp"),
        // image: powerfulMotherboard,
        image: "https://i.ibb.co/TrLbD41/design-to-appeal.png",
        // image: advancedAudio,
        description:
          "With their discreet and low-profile designs, they seamlessly blend into any environment. Whether it's a conference room, an auditorium or an AV studio, vector microphones provide exceptional sound quality without compromising the aesthetics of the space.",
      },
      {
        title: "Superior Construction",
        // image: require("../../assets/Specification/Archei1.webp"),
        // image:advancedAudio,
        // image: acousticEcho,
        image: "https://i.ibb.co/949qZpG/superior-construction.jpg",
        description:
          "Our Vector microphones are meticulously crafted using high-quality brass, ensuring reliable & long-term performance. With a matte black paint finish on top, these microphones exude a stylish and professional aesthetics.",
      },
      // {
      //   title: "Full Band Transmitter",
      //   image: powerfulMotherboard,
      //   description:
      //     "134MHz full band transmitter allows pairing with the receiver irrespective of frequency band.",
      // },
      // {
      //   title: "Anti-Interference",
      //   image: usbAudio,
      //   description:
      //     "PLL (Phase Lock Loop frequency control) design ensures transmission reliability, “NoiseLock”squelch effectively blocks stray RF.",
      // },
    ],
    Keyer: [
      {
        title: "Dante Audio Transmission",
        // image: require("../../assets/Specification/Keyer/keyer1.webp"),
        heading_description:
          "Keyer Series - a powerhouse in digital processing and control. With Dante technology, share high-res audio effortlessly. Enjoy disturbance-free voice quality with advanced echo cancellation. Benefit from automatic noise suppression and adaptive feedback control. Powered by a reliable TI456MHz ARM9 Dual Core processor and featuring a bi-directional USB codec for seamless video conferencing compatibility. ",
        heading: "The Keyer Series",
        image: "https://i.ibb.co/7R2M9BH/dante.jpg",
        // image: audioTransmission,
        description:
          "The Dante technology from Audinate provides smooth and hassle-free digital audio transmission over the local area networks.        Dante-enabled devices can share multiple channels of high-resolution digital audio with each other over a local area network.  ",
      },
      {
        title: "Acoustic Echo Cancellation ",
        // image: require("../../assets/Specification/Keyer/keyer2.webp"),
        // image: acousticEcho,
        image: "https://i.ibb.co/yWwhYnT/echo-cancellation.jpg",
        description:
          "The Keyer Series of DSP have advanced AEC algorithms to create much faster echo or double talk eliminations and thereby providing a disturbance-free Intelligent voice quality. ",
      },
      {
        title: "Advanced Audio Processing",
        // image: require("../../assets/Specification/Keyer/Keyer3.webp"),
        image: "https://i.ibb.co/qRsVvTR/advanced-audio-processing.jpg",
        // image: advancedAudio,
        description: `Keyer DSPs boast much-needed algorithms like

          ANS(Automatic Noise Suppression)
          AFC (Adaptive Feedback Control)
          AGC (Automatic Gain Control) to provide you with a comprehensible audio output.
          `,
      },
      {
        title: "Powerful Motherboard Chip",
        image: "https://i.ibb.co/H2pSWG8/motherboard.jpg",
        // image: powerfulMotherboard,
        description:
          "TI456MHz ARM9 Dual Core processor is one of the most reliable chip for high performance multi-tasking programs",
      },
      {
        title: "USB Audio Interface",
        image: "https://i.ibb.co/qpgh09W/usb.jpg",
        // image: usbAudio,
        description:
          "The Bi-directional USB Codec in Keyer DSPs makes Software-based Video Conferencing compatibility super easy and user friendly.",
      },
    ],

    ADS: [
      {
        title: "Less Antennae More Coverage",
        heading: "Less Antennae More Coverage",
        heading_description:
          "The AD4S by Erthpot redefines wireless microphone setups with its advanced four-way antenna distribution system. By effectively mitigating signal dropouts caused by interference, it ensures seamless audio transmission to multiple receivers. With its integrated power supplies guaranteeing consistent performance, the AD4S stands as an indispensable choice for professionals seeking reliable and efficient wireless solutions.",

        image: adsfeature1,
        description:
          "Just a pair of antennae to run 8 wireless channels in a single setup",
      },
      {
        title: "Enhanced Signal Reception",
        heading: "Enhanced Signal Reception",
        heading_description:
          "Compatible with UHF based active antennae to increase operational range",

        image: adsfeature2,
        description:
          "Compatible with UHF based active antennae to increase operational range",
      },
      {
        title: "Power distribution simplified",
        heading: "Power distribution simplified",
        heading_description:
          "Houses four distinct power supplies to power up 4 wireless receivers",

        image: adsfeature3,
        description:
          "Houses four distinct power supplies to power up 4 wireless receivers",
      },
    ],
  };

  const myobject = myobject1[productName];

  const filteredProducts = dummyProducts.filter(
    (product) => product.productName === productName
  );

  const renderProductCards = () => {
    return filteredProducts.map((product, index) => {
      return (
        <div key={index} className="flex justify-center items-center">
          <ProductCard
            product={product}
            index={index}
            handleClick={() => handleClick(product)}
          />
        </div>
      );
    });
  };

  const carouselSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
  };

  return (
    <div className=" bg-black font-anand">
      <FooterBanner />

      <div className="max-w-768px md:max-w-none md:py-0 mt-5 md:mt-0">
        <div
          className="w-[70%] mx-auto  md:h-screen "
          style={{ maxHeight: "50vh" }}
        >
          <div className="w-full h-full flex-col flex justify-center items-center">
            <h3 className="text-2xl font-bold text-center text-gray-100 sm:text-4xl">
              {productName === "ADS" ? "The ADS Series" : myobject[0].heading}
            </h3>
            <div className="mt-8">
              <p className="font-anand text-[10px] text-center text-white md:text-base">
                {myobject[0].heading_description}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[95%]   mx-auto pb-10 mt-10 md:mt-0">
        {/* Featured Products */}
        <div
          className="px-5 md:px-10 md:h-screen mt-2"
          style={{ maxHeight: "65vh" }}
        >
          <h3 className="text-center font-bold text-gray-100 text-2xl pb-10 ">
            Featured Products
          </h3>

          <div
            className="w-full md:w-full mx-auto justify-center items-center"
            style={{ marginRight: "7vw" }}
          >
            <Slider className="w-full md:w-[70%] mx-auto flex justify-center"  {...carouselSettings}>
              {renderProductCards()}
            </Slider>
          </div>
        </div>
      </div>
      {/* 1 */}
      {myobject?.map((item, i) => (
        <div className="w-full  pt-3 ">
          <div className=" grid grid-cols-1  hover:bg-black ">
            <div className="relative h-[350px]  group cursor-pointer overflow-hidden  ">
              <img
                className=" h-full w-full group-hover:cursor-pointer object-cover  opacity-50 duration-700 group-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                src={item.image}
                alt=""
              />

              <div className="w-full ">
                <div className="flex-col w-[80%] mx-auto mt-8 group flex items-center justify-center absolute inset-0">
                  <h1 className=" text-center   h-16 text-2xl font-bold  text-gray-100  font-anand">
                    {item.title}
                  </h1>
                  <h1 className=" text-center text-base text-gray-100  font-anand">
                    {item.description}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {productName === "Arche" ? (
        <div className="w-[95%]  mx-auto py-10 ">
          <div className="w-full  md:h-screen">
            <div className="w-[70%] py-10 mx-auto">
              <h1 className="text-center text-gray-100 text-4xl font-bold font-anand">
                Application
              </h1>
              <p className="text-center text-gray-300 text-[15px] pt-5 font-anand">
                Keyer Series of Audio Processors have a very diversed audio
                functions to offer that make them a centerpiece in various
                applications
              </p>
            </div>

            <div className="w-[80%] mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-3  gap-3">
                <div className="relative h-[250px]  group cursor-pointer overflow-hidden hover:bg-black ">
                  <img
                    className="rounded-2xl h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                    src="https://images.pexels.com/photos/8197553/pexels-photo-8197553.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                  />
                  <div className="group relative ">
                    <h1 className=" group-hover:visible text-xl text-center absolute inset-x-0 bottom-0 h-16 font-bold invisible duration-700 group-hover:duration-300  text-gray-100  group-hover:translate-x-3 font-anand">
                      Classrooms
                    </h1>
                  </div>
                </div>
                <div className="relative h-[250px]  group cursor-pointer overflow-hidden hover:bg-black ">
                  <img
                    className="rounded-2xl h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                    src={auditorium}
                    alt=""
                  />
                  <div className="group  relative">
                    <h1 className=" group-hover:visible text-xl  text-center absolute inset-x-0 bottom-0 h-16 font-bold invisible duration-700 group-hover:duration-300  text-gray-100  group-hover:translate-x-3">
                      Auditoriums
                    </h1>
                  </div>
                </div>
                <div className="relative h-[250px]  group cursor-pointer overflow-hidden hover:bg-black">
                  <img
                    className=" rounded-2xl h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                    src="https://images.pexels.com/photos/167491/pexels-photo-167491.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                  />
                  <div className="group relative">
                    <h1 className=" group-hover:visible text-xl  text-center absolute inset-x-0 bottom-0 h-16 font-bold invisible duration-700 group-hover:duration-300  text-gray-100  group-hover:translate-x-3 px-5">
                      Live Performance
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {productName === "Keyer" ? (
        <div className="w-[95%]  mx-auto py-10 ">
          <div className="w-full  md:h-screen[90%]">
            <div className="w-[70%] py-10 mx-auto">
              <h1 className="text-center text-gray-100 text-4xl font-bold font-anand">
                Application
              </h1>
              <p className="text-center text-gray-300 text-[15px] pt-5 font-anand">
                Keyer Series of Audio Processors have a very diversed audio
                functions to offer that make them a centerpiece in various
                applications
              </p>
            </div>

            <div className="w-[80%] mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-4  gap-3">
                <div className="relative h-[250px]  group cursor-pointer overflow-hidden hover:bg-black ">
                  <img
                    className="rounded-2xl h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                    src="https://images.pexels.com/photos/1181408/pexels-photo-1181408.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                  />
                  <div className="group relative">
                    <h1 className=" group-hover:visible text-center absolute inset-x-0 bottom-0 h-16 text-xl font-bold invisible duration-700 group-hover:duration-300  text-gray-100  group-hover:translate-x-3 font-anand">
                      Conference Rooms
                    </h1>
                  </div>
                </div>
                <div className="relative h-[250px]  group cursor-pointer overflow-hidden hover:bg-black ">
                  <img
                    className="rounded-2xl h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                    src="https://images.pexels.com/photos/8197553/pexels-photo-8197553.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                  />
                  <div className="group relative ">
                    <h1 className=" group-hover:visible text-xl text-center absolute inset-x-0 bottom-0 h-16 font-bold invisible duration-700 group-hover:duration-300  text-gray-100  group-hover:translate-x-3 font-anand">
                      Classrooms
                    </h1>
                  </div>
                </div>
                <div className="relative h-[250px]  group cursor-pointer overflow-hidden hover:bg-black ">
                  <img
                    className="rounded-2xl h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                    src="https://images.pexels.com/photos/7983548/pexels-photo-7983548.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                  />
                  <div className="group  relative">
                    <h1 className=" group-hover:visible text-xl  text-center absolute inset-x-0 bottom-0 h-16 font-bold invisible duration-700 group-hover:duration-300  text-gray-100  group-hover:translate-x-3">
                      Training Rooms
                    </h1>
                  </div>
                </div>
                <div className="relative h-[250px]  group cursor-pointer overflow-hidden hover:bg-black">
                  <img
                    className=" rounded-2xl h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                    src="https://images.pexels.com/photos/167491/pexels-photo-167491.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                  />
                  <div className="group relative">
                    <h1 className=" group-hover:visible text-xl  text-center absolute inset-x-0 bottom-0 h-16 font-bold invisible duration-700 group-hover:duration-300  text-gray-100  group-hover:translate-x-3 px-5">
                      Restaurants, Bars & Clubs
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {productName === "Novo" ? (
        <div className="w-[95%]  mx-auto py-10 ">
          <div className="w-full  md:h-screen[90%]">
            <div className="w-[70%] py-10 mx-auto">
              <h1 className="text-center text-gray-100 text-4xl font-bold font-anand">
                Application
              </h1>
              <p className="text-center text-gray-300 text-[15px] pt-5 font-anand">
                Keyer Series of Audio Processors have a very diversed audio
                functions to offer that make them a centerpiece in various
                applications
              </p>
            </div>

            <div className="w-[80%] mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-3  gap-3">
                <div className="relative h-[250px]  group cursor-pointer overflow-hidden hover:bg-black ">
                  <img
                    className="rounded-2xl h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                    src="https://images.pexels.com/photos/1181408/pexels-photo-1181408.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                  />
                  <div className="group relative">
                    <h1 className=" group-hover:visible text-center absolute inset-x-0 bottom-0 h-16 text-xl font-bold invisible duration-700 group-hover:duration-300  text-gray-100  group-hover:translate-x-3 font-anand">
                      Conference Rooms
                    </h1>
                  </div>
                </div>
                <div className="relative h-[250px]  group cursor-pointer overflow-hidden hover:bg-black ">
                  <img
                    className="rounded-2xl h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                    src="https://images.pexels.com/photos/8197553/pexels-photo-8197553.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                  />
                  <div className="group relative ">
                    <h1 className=" group-hover:visible text-xl text-center absolute inset-x-0 bottom-0 h-16 font-bold invisible duration-700 group-hover:duration-300  text-gray-100  group-hover:translate-x-3 font-anand">
                      Classrooms
                    </h1>
                  </div>
                </div>
                <div className="relative h-[250px]  group cursor-pointer overflow-hidden hover:bg-black ">
                  <img
                    className="rounded-2xl h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                    src="https://images.pexels.com/photos/7983548/pexels-photo-7983548.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                  />
                  <div className="group  relative">
                    <h1 className=" group-hover:visible text-xl  text-center absolute inset-x-0 bottom-0 h-16 font-bold invisible duration-700 group-hover:duration-300  text-gray-100  group-hover:translate-x-3">
                      Training Rooms
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {productName === "Vector" ? (
        <div className="w-[95%]  mx-auto py-10 ">
          <div className="w-full  md:h-screen[90%]">
            <div className="w-[70%] py-10 mx-auto">
              <h1 className="text-center text-gray-100 text-4xl font-bold font-anand">
                Application
              </h1>
              <p className="text-center text-gray-300 text-[15px] pt-5 font-anand">
                Keyer Series of Audio Processors have a very diversed audio
                functions to offer that make them a centerpiece in various
                applications
              </p>
            </div>

            <div className="w-[80%] mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-4  gap-3">
                <div className="relative h-[250px]  group cursor-pointer overflow-hidden hover:bg-black ">
                  <img
                    className="rounded-2xl h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                    src="https://images.pexels.com/photos/1181408/pexels-photo-1181408.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                  />
                  <div className="group relative">
                    <h1 className=" group-hover:visible text-center absolute inset-x-0 bottom-0 h-16 text-xl font-bold invisible duration-700 group-hover:duration-300  text-gray-100  group-hover:translate-x-3 font-anand">
                      Conference Rooms
                    </h1>
                  </div>
                </div>
                <div className="relative h-[250px]  group cursor-pointer overflow-hidden hover:bg-black ">
                  <img
                    className="rounded-2xl h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                    src="https://images.pexels.com/photos/8197553/pexels-photo-8197553.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                  />
                  <div className="group relative ">
                    <h1 className=" group-hover:visible text-xl text-center absolute inset-x-0 bottom-0 h-16 font-bold invisible duration-700 group-hover:duration-300  text-gray-100  group-hover:translate-x-3 font-anand">
                      Classrooms
                    </h1>
                  </div>
                </div>
                <div className="relative h-[250px]  group cursor-pointer overflow-hidden hover:bg-black ">
                  <img
                    className="rounded-2xl h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                    src="https://images.pexels.com/photos/7983548/pexels-photo-7983548.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                  />
                  <div className="group  relative">
                    <h1 className=" group-hover:visible text-xl  text-center absolute inset-x-0 bottom-0 h-16 font-bold invisible duration-700 group-hover:duration-300  text-gray-100  group-hover:translate-x-3">
                      Training Rooms
                    </h1>
                  </div>
                </div>
                <div className="relative h-[250px]  group cursor-pointer overflow-hidden hover:bg-black">
                  <img
                    className=" rounded-2xl h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                    src="https://images.pexels.com/photos/167491/pexels-photo-167491.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                  />
                  <div className="group relative">
                    <h1 className=" group-hover:visible text-xl  text-center absolute inset-x-0 bottom-0 h-16 font-bold invisible duration-700 group-hover:duration-300  text-gray-100  group-hover:translate-x-3 px-5">
                      Live Performance
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {productName === "ADS" ? (
        <div className="w-[95%]  mx-auto py-10 ">
          <div className="w-full md:h-screen[90%]">
            <div className="w-[70%] py-10 mx-auto">
              <h1 className="text-center text-gray-100 text-4xl font-bold font-anand">
                Application
              </h1>
              <p className="text-center text-gray-300 text-[15px] pt-5 font-anand">
                ADS Series of Audio Processors have a very diversed audio
                functions to offer that make them a centerpiece in various
                applications
              </p>
            </div>

            <div className="w-[80%] mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-4  gap-3">
                <div className="relative h-[250px]  group cursor-pointer overflow-hidden hover:bg-black ">
                  <img
                    className="rounded-2xl h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                    src="https://images.pexels.com/photos/8197553/pexels-photo-8197553.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                  />
                  <div className="group relative">
                    <h1 className=" group-hover:visible text-center absolute inset-x-0 bottom-0 h-16 text-xl font-bold invisible duration-700 group-hover:duration-300  text-gray-100  group-hover:translate-x-3 font-anand">
                      Auditorium
                    </h1>
                  </div>
                </div>
                <div className="relative h-[250px]  group cursor-pointer overflow-hidden hover:bg-black ">
                  <img
                    className="rounded-2xl h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                    src={application2}
                    alt=""
                  />
                  <div className="group relative ">
                    <h1 className=" group-hover:visible text-xl text-center absolute inset-x-0 bottom-0 h-16 font-bold invisible duration-700 group-hover:duration-300  text-gray-100  group-hover:translate-x-3 font-anand">
                      Stadium
                    </h1>
                  </div>
                </div>
                <div className="relative h-[250px]  group cursor-pointer overflow-hidden hover:bg-black ">
                  <img
                    className="rounded-2xl h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                    src={application1}
                    alt=""
                  />
                  <div className="group  relative">
                    <h1 className=" group-hover:visible text-xl  text-center absolute inset-x-0 bottom-0 h-16 font-bold invisible duration-700 group-hover:duration-300  text-gray-100  group-hover:translate-x-3">
                      Experience center
                    </h1>
                  </div>
                </div>
                <div className="relative h-[250px]  group cursor-pointer overflow-hidden hover:bg-black">
                  <img
                    className=" rounded-2xl h-full w-full group-hover:cursor-pointer object-cover hover:opacity-50 duration-700 gropu-hover:duration-700  overflow-hidden transition-transform transform-gpu group-hover:scale-110"
                    src="https://images.pexels.com/photos/167491/pexels-photo-167491.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                  />
                  <div className="group relative">
                    <h1 className=" group-hover:visible text-xl  text-center absolute inset-x-0 bottom-0 h-16 font-bold invisible duration-700 group-hover:duration-300  text-gray-100  group-hover:translate-x-3 px-5">
                      Live Stage
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AllProducts;
