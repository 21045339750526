import React, { useState } from "react";
import { motion } from "framer-motion";
import "./Contact";
import { FaHome } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import axios from "axios";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    company: "",
    message: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  function inputChangeHandler(event) {
    const { name, type, value, checked } = event.target;
    // console.log(event.target.value);
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }

  async function submitHandler(event) {
    event.preventDefault();
    setFormSubmitted(true);
    emailjs
      .send("service_rxvfa8e", "template_z9rxghv", formData, {
        publicKey: "-WIObzjox69mrYBYJ",
      })
      .then(
        (response) => {
          setFormSubmitted(false);
          // console.log("SUCCESS!", response.status, response.text);
          alert("Request submited successfully");
          window.location.reload();
        },
        (err) => {
          setFormSubmitted(false);
          // console.log("FAILED...", err);
          alert("Something went wrong! Please try again");
        }
      );

    // try {
    //   const response = await axios.post(
    //     `http://localhost:4000/api/v1/contact`,
    //     formData,
    //     {
    //       withCredentials: true,
    //     }
    //   );
    //   const responseData = response.data;
    //   console.log("Data successfully posted:", responseData);

    //   setFormData({
    //     name: "",
    //     email: "",
    //     number: "",
    //     company: "",
    //     message: "",
    //   });
    // } catch (error) {
    //   console.error("Error creating contact:", error);
    // }
  }

  return (
    <motion.div
      variants={fadeIn}
      initial="hidden"
      animate="visible"
      className="bg-black min-h-screen  w-full font-anand  flex justify-center items-center "
    >
      <div className="  w-[100%] mx-auto h-full grid  md:grid-cols-2 gap-2 sm:grid-cols-1  md:pt-0 pt-20">
        <motion.div
          initial={{ opacity: 0, y: 0, x: 50 }}
          animate={{ opacity: 1, y: 0, x: 0 }}
          transition={{ delay: 0.5, duration: 1 }}
          className="w-[70%] h-full mx-auto flex justify-start items-center "
        >
          <div className="  h-full  ">
            <div className=" flex gap-3 pb-2  ">
              <div>
                <div className="w-14 h-14 rounded-full bg-gray-100 flex justify-center items-center shadow-2xl">
                  <FaHome className="text-3xl" />
                </div>
              </div>
              <div>
                <h1 className="text-xl text-gray-100 font-semibold">Address</h1>
                <p className="text-[14px]  text-gray-400">
                  A - 164, A Block Noida Sector 63,
                </p>
                <p className="text-[14px]  text-gray-400">
                  Gautam Budhha Nagar Uttar
                </p>
                <p className="text-[14px]  text-gray-400">
                  Pradesh Pincode: 201301
                </p>
              </div>
            </div>
            <div className=" flex gap-3 py-2">
              <div>
                <div className="w-14 h-14 rounded-full bg-gray-100 flex justify-center items-center shadow-2xl">
                  <FaPhoneAlt className="text-2xl" />
                </div>
              </div>
              <div>
                <h1 className="text-xl text-gray-100 font-semibold">Contact</h1>
                <p className="text-[14px]  text-gray-400">+91 120 4567452</p>
              </div>
            </div>
            <div className=" flex gap-3 py-6">
              <div>
                <div className="w-14 h-14 rounded-full bg-gray-100 flex justify-center items-center shadow-2xl">
                  <MdEmail className="text-3xl" />
                </div>
              </div>
              <div>
                <h1 className="text-xl text-gray-100 font-semibold">Email</h1>
                <p className="text-[14px]  text-gray-400">
                  <a href="mailto:info@erthpot.com" target="_blank">
                    Send Email to info@erthpot.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </motion.div>
        <form onSubmit={submitHandler}>
          <motion.section
            initial={{ opacity: 0, y: 0, x: -50 }}
            animate={{ opacity: 1, y: 0, x: 0 }}
            transition={{ delay: 1, duration: 0.5 }}
            className=" w-full h-full flex justify-center items-center"
          >
            <div className="w-[70%] mx-auto space-y-3  ">
              <div className="flex flex-col  space-y-1">
                <input
                  required
                  type="text"
                  placeholder="Your Name"
                  className="p-2 border   border-gray-300 focus:outline-none bg-gray-200 focus:border-gray-100 "
                  id="name"
                  name="name"
                  onChange={inputChangeHandler}
                  value={formData.name}
                />
              </div>
              <div className="flex flex-col w-full space-y-1 ">
                <input
                  required
                  type="email"
                  placeholder="Your Email"
                  id="email"
                  name="email"
                  onChange={inputChangeHandler}
                  value={formData.email}
                  className="p-2 border   border-gray-300 focus:outline-none bg-gray-200 focus:border-gray-100 "
                />
              </div>
              <div className="flex flex-col w-full space-y-1">
                <input
                  required
                  type="text"
                  id="number"
                  name="number"
                  onChange={inputChangeHandler}
                  value={formData.phone_no}
                  placeholder="Your Phone"
                  className="p-2 border   border-gray-300 focus:outline-none bg-gray-200 focus:border-gray-100 "
                />
              </div>
              <div className="flex flex-col w-full space-y-1 ">
                <input
                  required
                  type="text"
                  placeholder="Company Name"
                  id="company"
                  name="company"
                  onChange={inputChangeHandler}
                  value={formData.company_name}
                  className="p-2 border   border-gray-300 focus:outline-none bg-gray-200 focus:border-gray-100 "
                />
              </div>
              <div className="flex flex-col w-full space-y-1 ">
                <textarea
                  required
                  type="text"
                  placeholder="Write Something"
                  id="message"
                  name="message"
                  onChange={inputChangeHandler}
                  value={formData.write_something}
                  className="p-2 border   border-gray-300 focus:outline-none bg-gray-200 focus:border-gray-100 "
                />
              </div>

              <div className="mt-5">
                <button
                  disabled={formSubmitted === true ? true : false}
                  type="submit"
                  className="hover:bg-[#00AFAC] hover:duration-700 duration-700 
                                  rounded-md uppercase text-center border-2 border-[#00AFAC] px-5 py-2  
                                   text-gray-100 font-semibold  text-sm"
                >
                  {formSubmitted === true ? "Please wait" : "Submit"}
                </button>
              </div>
            </div>
          </motion.section>
        </form>
      </div>
    </motion.div>
  );
};

export default Contact;
