import React, { useState } from "react";
import { Link } from "react-router-dom";
import  NewsItems  from "./DummyNews";
const News = () => {
  const [intro, setIntro] = useState("");
 



  return (
    <div className="w-full py-5 pt-12 bg-black">
      <h1 className="text-center text-3xl text-gray-100 font-bold my-4">
        Latest News Update & Blogs
      </h1>
      <div className="w-[85%] mx-auto p-4 grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-4">
       
        {NewsItems.map((item, i) => (
          <Link to={`/news-events/pages/${i}` }key={i}>

        

            <div className="m-2 mx-auto hover:bg-opacity-25 hover:bg-[#00AFAC] hover:duration-500 duration-500">
              <div className="hover:-translate-y-2 hover:duration-500 duration-500">
                <img src={item.img} alt="no image" />

              </div>
              <div className="py-3 px-2 text-gray-400">
                <span className="text-[12px] ">{item.date}</span>
                <span className="text-[12px] pl-2">{item.Comments}</span>
                <span className="text-[12px] pl-2">{item.name}</span>
              </div>
              <div className="px-2 ">
                <h1 className="text-xl text-gray-100 hover:text-[#00AFAC] hover:duration-500 duration-500">
                  {item.heading}
                </h1>
                
              </div>
              <div className="px-2">
              <h1 className="text-md text-gray-100 hover:text-[#00AFAC] hover:duration-500 duration-500">
                  {item.intro}
                </h1>
              </div>
              <div className="py-2">
                <button className="text-bold text-gray-400 border-[#00afac] border-b-2 text-center  ml-2">
                  Read More
                </button>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default News;
