import React, { useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import salessupporttrouble from "../../assets/salessupporttrouble.webp";
import emailjs from "@emailjs/browser";

const endUser = "End User";
const systemIntegrator = "System Integrator";
const consultant = "Consultant";
const other = "Other";

const product1 = "Keyer 44 – 4Input x 4Output (non-dante)";
const product2 = "Keyer 88 – 8Input x 8Output (non-dante)";
const product3 = "Keyer 88(dante)";
const product4 = "Keyer 1616(non-dante)";
const product5 = "Keyer 1616(dante)";
const product6 = "PC-1";
const product7 = "Novo Conference System";

const API_BASE_URL = "http://localhost:4000/api/v1/support/afterSales";

const AfterSales = () => {
  const [formSubmitted, setFormSubmitted] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    user: "",
    productYourProduct: "",
    location: "",
    productSerialNo: "",
    howDoWeContactYou: "",
    image: null,
  });

  const handleProductChange = (e) => {
    const selectedProduct = e.target.value;
    setSelectedProduct(selectedProduct);
    setFormData({ ...formData, productYourProduct: selectedProduct });
  };

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    let data = {data: JSON.stringify(formData)}
    emailjs
      .send("service_rxvfa8e", "template_22pvuqo", data, {
        publicKey: "-WIObzjox69mrYBYJ",
      })
      .then(
        (response) => {
          setFormSubmitted(false);
          // console.log("SUCCESS!", response.status, response.text);
          alert("Request submited successfully");
          window.location.reload();
        },
        (err) => {
          setFormSubmitted(false);
          // console.log("FAILED...", err);
          alert("Something went wrong! Please try again");
        }
      );

    // try {
    //   const response = await axios.post(API_BASE_URL, formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   });

    //   const responseData = response.data;

    //   console.log("Data successfully posted:", responseData);
    //   setFormSubmitted([...formSubmitted, formData]);
    //   setFormData({
    //     firstName: "",
    //     lastName: "",
    //     email: "",
    //     phone: "",
    //     user: "",
    //     productYourProduct: "",
    //     location: "",
    //     productSerialNo: "",
    //     howDoWeContactYou: "",
    //     image: null,
    //   });

    //   setSelectedGrade("");
    //   setSelectedProduct("");
    //   console.log("Data created successfully");
    // } catch (error) {
    //   console.error("Error creating contact:", error);
    // }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleGradeChange = (e) => {
    const selectedGrade = e.target.value;
    setSelectedGrade(selectedGrade);
    setFormData({ ...formData, user: selectedGrade });
  };

  return (
    <motion.div
      variants={fadeIn}
      initial="hidden"
      animate="visible"
      className="bg-black min-h-screen font-anand mt-10"
    >
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 1 }}
        className="bg-cover bg-center h-64 relative"
        style={{ backgroundImage: `url(${salessupporttrouble})` }}
      >
        <div className="absolute inset-0 flex flex-col justify-center items-center">
          <div className="text-2xl md:text-5xl  text-gray-100 ">
            {" "}
            After Sales Support & Troubleshooting
          </div>

          <div className="mt-5 text-gray-100 text-xs font-semibold text-center uppercase">
            <a href="/">HOME</a> / After Sales Support & Troubleshooting
          </div>
        </div>
      </motion.div>

      <div className="w-full mt-1 grid  grid-cols-1 md:grid-cols-2">
        <div className="w-[95%] mx-auto">
          <motion.section
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 0.5 }}
            className="w-[90%] mx-auto mt-8"
          >
            <p className="mb-4 text-xl text-gray-100 ">We have got your back</p>
            <p className=" text-[14px]  text-gray-400 ">
              Erthpot takes complete responsibility for its products and
              parts-related issues. Let us know how can we make your experience
              better with us.
            </p>
          </motion.section>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1, duration: 0.5 }}
          className=""
        >
          <div>
            <div className="w-full  p-6 bg-black shadow-md md:mt-4">
              <div className="mx-auto w-[90%]">
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    <div className="mb-[0.7px]">
                      <input
                        type="text"
                        id="firstname"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300  focus:outline-none bg-gray-200 focus:border-[#00AFAC]  text-justify"
                        placeholder="First Name"
                        required
                      />
                    </div>
                    <div className="mb-[0.7px]">
                      <input
                        type="text"
                        id="lastname"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300  focus:outline-none bg-gray-200 focus:border-[#00AFAC]  text-justify"
                        required
                        placeholder="last Name"
                      />
                    </div>

                    <div className="mb-[0.7px]">
                      <input
                        type="email"
                        id="email"
                        onChange={handleChange}
                        value={formData.email}
                        name="email"
                        className="w-full p-2 border border-gray-300  focus:outline-none bg-gray-200 focus:border-[#00AFAC]  text-justify"
                        placeholder="john@example.com"
                      />
                    </div>

                    <div className="mb-[0.7px]">
                      <input
                        type="text"
                        id="Phone"
                        name="phone"
                        onChange={handleChange}
                        value={formData.phone}
                        className="w-full p-2 border border-gray-300  focus:outline-none bg-gray-200 focus:border-[#00AFAC]  text-justify"
                        placeholder="Phone No.."
                      />
                    </div>

                    <div className="mb-[0.7px]">
                      <select
                        className="w-full p-[8.5px] border border-gray-300 text-[14px]  text-gray-800 focus:outline-none bg-gray-200 focus:border-[#00AFAC]  text-justify"
                        value={selectedGrade}
                        onChange={handleGradeChange}
                      >
                        <option>You are a</option>
                        <option value={endUser}>End User</option>
                        <option value={systemIntegrator}>
                          Sysytem Integrator
                        </option>
                        <option value={consultant}>Consultant</option>
                        <option vlaue={other}>Other</option>
                      </select>
                    </div>

                    <div className="mb-[0.7px]">
                      <select
                        className="w-full p-[8.5px] border border-gray-300 text-[14px]  text-gray-800  focus:outline-none bg-gray-200 focus:border-[#00AFAC]  text-justify"
                        value={selectedProduct}
                        onChange={handleProductChange}
                      >
                        <option>Please Mention Your Product</option>
                        <option value={product1}>
                          Keyer 44 – 4Input x 4Output (non-dante)
                        </option>
                        <option value={product2}>
                          Keyer 88 – 8Input x 8Output (non-dante)
                        </option>
                        <option value={product3}>Keyer 88(dante)</option>
                        <option vlaue={product4}>Keyer 1616(non-dante)</option>
                        <option vlaue={product5}>Keyer 1616(dante)</option>
                        <option vlaue={product6}>PC-1</option>
                        <option vlaue={product7}>
                          Novo Conference System{" "}
                        </option>
                      </select>
                    </div>

                    <div className="mb-[0.7px]">
                      <input
                        type="text"
                        id="location"
                        name="location"
                        onChange={handleChange}
                        value={formData.location}
                        className="w-full p-2 border border-gray-300  focus:outline-none bg-gray-200 focus:border-[#00AFAC]  text-justify"
                        placeholder="Location"
                      />
                    </div>

                    <div className="mb-[0.7px]">
                      <input
                        type="text"
                        id="Product Serial No"
                        name="productSerialNo"
                        onChange={handleChange}
                        value={formData.productSerialNo}
                        className="w-full p-2 border border-gray-300  focus:outline-none bg-gray-200 focus:border-[#00AFAC]  text-justify"
                        placeholder=" Product Serial No"
                      />
                    </div>

                    <div class="">
                      <h1 class="text-sm font-medium mb-4  text-gray-400 text-justify">
                        Type of Fault
                      </h1>

                      <label class="flex items-center mb-2">
                        <input
                          type="checkbox"
                          class="form-checkbox h-3 w-3 text-blue-500"
                        />
                        <span class="ml-2 text-sm  text-gray-400">
                          No Power in the Unit
                        </span>
                      </label>
                      <label class="flex items-center mb-2">
                        <input
                          type="checkbox"
                          class="form-checkbox h-3 w-3 text-blue-500"
                        />
                        <span class="ml-2  text-sm  text-gray-400">
                          Misbehaving Unit
                        </span>
                      </label>

                      <label class="flex items-center mb-2">
                        <input
                          type="checkbox"
                          class="form-checkbox h-3 w-3 text-blue-500"
                        />
                        <span class="ml-2  text-sm  text-gray-400">
                          Physically Damaged
                        </span>
                      </label>
                      <label class="flex items-center mb-2">
                        <input
                          type="checkbox"
                          class="form-checkbox h-3 w-3 text-blue-500"
                        />
                        <span class="ml-2  text-sm  text-gray-400">
                          Overheating
                        </span>
                      </label>
                      <label class="flex items-center mb-2">
                        <input
                          type="checkbox"
                          class="form-checkbox h-3 w-3 text-blue-500"
                        />
                        <span class="ml-2  text-sm  text-gray-400">
                          Networking Issue
                        </span>
                      </label>
                      <label class="flex items-center mb-2">
                        <input
                          type="checkbox"
                          class="form-checkbox h-3 w-3 text-blue-500"
                        />
                        <span class="ml-2  text-sm  text-gray-400">Other</span>
                      </label>
                    </div>
                  </div>

                  <div className="mt-3 mb-2">
                    <input
                      type="file"
                      id="file"
                      name="file"
                      value={formData.file}
                      accept=".pdf"
                      onChange={handleFileChange}
                      className="w-full p-[5.2px] border border-gray-300  focus:outline-none bg-gray-200 focus:border-[#00AFAC]  text-justify"
                    />
                  </div>
                  <div className="mb-2">
                    <input
                      type="text"
                      id="howDoWeContactYou"
                      name="howDoWeContactYou"
                      onChange={handleChange}
                      value={formData.howDoWeContactYou}
                      className="w-full p-2 border border-gray-300  focus:outline-none bg-gray-200 focus:border-[#00AFAC]  text-justify"
                      placeholder="How do we contact you ?"
                    />
                  </div>

                  <div className="mt-2">
                    <button
                      className="hover:bg-[#00AFAC] hover:duration-700 duration-700 
                                    rounded-md uppercase text-center border-2 border-[#00AFAC] px-5 py-2  
                                    text-gray-100 font-semibold text-sm"
                      onClick={handleSubmit}
                      disabled= {formSubmitted === true ? true : false}
                    >
                       {formSubmitted === true ? "Please wait" : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default AfterSales;
