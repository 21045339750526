import React, { useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import partnerwithus from "../../assets/partnerwithus.png";
import emailjs from "@emailjs/browser";

const installedSound = "Installed Sound";
const live = "Live";
const home = "Home Theater System";
const studio = "Studio";
const sinema = "Sinema";

const API_BASE_URL = "http://localhost:4000/api/v1/support/partnerWithUs";

const Partner = () => {
  const [selectedGrade, setSelectedGrade] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    companyName: "",
    city: "",
    state: "",
    gstNo: "",
    legalStatus: "",
    contactPerson1: "",
    designation: "",
    productLineEnrolment: "",
  });
  const [formSubmitted, setFormSubmitted] = useState([]);

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true)
    let data = {data: JSON.stringify(formData)}
    emailjs
      .send("service_rxvfa8e", "template_22pvuqo", data, {
        publicKey: "-WIObzjox69mrYBYJ",
      })
      .then(
        (response) => {
          setFormSubmitted(false);
          // console.log("SUCCESS!", response.status, response.text);
          alert("Request submited successfully");
          window.location.reload();
        },
        (err) => {
          setFormSubmitted(false);
          // console.log("FAILED...", err);
          alert("Something went wrong! Please try again");
        }
      );
    // try {
    //   const response = axios.post(API_BASE_URL, formData, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   });
    //   setFormSubmitted(response.data);
    //   console.log("form data", formData);
    //   setFormData({
    //     email: "",
    //     companyName: "",
    //     city: "",
    //     state: "",
    //     gstNo: "",
    //     legalStatus: "",
    //     contactPerson1: "",
    //     designation: "",
    //     productLineEnrolment: "",
    //   });
    //   setSelectedGrade("");
    // } catch (error) {
    //   console.error("API error:", error);
    // }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleGradeChange = (e) => {
    const selectedGrade = e.target.value;
    setSelectedGrade(selectedGrade);
    setFormData({ ...formData, productLineEnrolment: selectedGrade });
  };

  return (
    <motion.div
      variants={fadeIn}
      initial="hidden"
      animate="visible"
      className="bg-black min-h-screen mt-10"
    >
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 1 }}
        className="bg-cover bg-center h-64 relative"
        style={{ backgroundImage: `url(${partnerwithus})` }}
      >
        <div className="absolute inset-0 flex flex-col items-center justify-center font-anand">
          <div className=" text-4xl md:text-5xl  text-gray-100">
            Partner With US
          </div>
          <div className="mt-5  text-gray-100   text-xs  font-semibold text-center uppercase">
            <a href="/">HOME</a> / Partner With US
          </div>
        </div>
      </motion.div>

      <div className="w-full mt-1 grid grid-cols-1 md:grid-cols-2 font-anand">
        <div className="w-[95%] mx-auto">
          <motion.section
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 0.5 }}
            className="w-[90%] mx-auto mt-8"
          >
            <p className="mb-4  text-xl   text-gray-100   ">
              Together, we can shape the audio-visual industry in India.
            </p>
            <p className="mb-2 text-[14px]  text-gray-400">
              Let’s jointly move your business forward by leading innovation,
              co-creating customer-centric solutions, and capturing new markets.
            </p>
            <div className="space-y-2">
              <p className=" text-[14px]  text-gray-400">
                Unlock new business opportunities to unlock borderless growth.
              </p>
              <p className=" text-[14px]  text-gray-400">
                Build trust and drive business.
              </p>
              <p className=" text-[14px]  text-gray-400 ">
                Manage customer experience, not just customer lifecycle.
              </p>
            </div>
          </motion.section>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1, duration: 0.5 }}
          className=""
        >
          <div className="w-full  p-6 bg-black shadow-md md:mt-4 ">
            <div className="mx-auto w-[90%]">
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                  <div className="mb-[0.7px]">
                    <input
                      type="text"
                      id="company name"
                      name="companyName"
                      onChange={handleChange}
                      value={formData.companyName}
                      className=" p-2 w-full border outline-none bg-gray-200 "
                      placeholder="Company Name"
                    />
                  </div>

                  <div className="mb-[0.7px]">
                    <input
                      type="text"
                      id="cityName"
                      onChange={handleChange}
                      value={formData.city}
                      name="city"
                      className="p-2 w-full border  outline-none bg-gray-200 "
                      placeholder="City Name"
                    />
                  </div>

                  <div className="mb-[0.7px]">
                    <input
                      type="text"
                      id="state name"
                      onChange={handleChange}
                      name="state"
                      value={formData.state}
                      className="p-2 w-full border  outline-none bg-gray-200 "
                      placeholder="State Name"
                    />
                  </div>

                  <div className="mb-[0.7px]">
                    <input
                      type="text"
                      id="gST registration no *"
                      name="gstNo"
                      value={formData.gstNo}
                      onChange={handleChange}
                      className="p-2 w-full border  outline-none bg-gray-200 text-justify"
                      placeholder="Registration No"
                    />
                  </div>

                  <div className="mb-[0.7px]">
                    <input
                      type="text"
                      id="legal Status"
                      name="legalStatus"
                      value={formData.legalStatus}
                      onChange={handleChange}
                      className="p-2 w-full border  outline-none bg-gray-200 text-justify"
                      placeholder="Legal Status"
                    />
                  </div>

                  <div className="mb-[0.7px]">
                    <input
                      type="number"
                      id="contact "
                      name="contactPerson1"
                      value={formData.contactPerson1}
                      onChange={handleChange}
                      className="p-2 w-full border  outline-none bg-gray-200 text-justify"
                      placeholder="Your Contact"
                    />
                  </div>

                  <div className="mb-[0.7px]">
                    <input
                      type="text"
                      id="designation"
                      name="designation"
                      value={formData.designation}
                      onChange={handleChange}
                      className="p-2 w-full border  outline-none bg-gray-200 text-justify"
                      placeholder="Your Designation"
                    />
                  </div>

                  <div className="mb-[0.7px]">
                    <input
                      type="email"
                      id="email"
                      onChange={handleChange}
                      value={formData.email}
                      name="email"
                      className="p-2 w-full border  outline-none bg-gray-200 "
                      placeholder="john@example.com"
                    />
                  </div>
                </div>

                <div className="mt-[3.2px] mb-2">
                  <select
                    className="mt-1 p-[10px] w-full border  text-[14px]   outline-none bg-gray-200"
                    value={selectedGrade}
                    onChange={handleGradeChange}
                  >
                    <option> Product Line Enrolment</option>
                    <option value={installedSound}>Installed Sound</option>
                    <option value={live}>Live</option>
                    <option value={home}>Home Theater System</option>
                    <option vlaue={studio}>Studio</option>
                    <option value={sinema}>Sinema</option>
                  </select>
                </div>

                <div className="mt-2">
                  <button
                    className="hover:bg-[#00AFAC] hover:duration-700 
                                    duration-700 rounded-md uppercase text-center 
                                    border-2 border-[#00AFAC] px-5  py-2  text-gray-100 font-semibold text-sm"
                    onClick={handleSubmit}
                    disabled={formSubmitted === true ? true : false}
                  >
                    {formSubmitted === true ? "Please wait" : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Partner;
