import React from "react";
import bridging from "../../assets/bridging the future.jpg";
import made_in_india from "../../assets/made in india made for the world (1).jpg";
import sustainability from "../../assets/sustainability banner.jpg";
const Story = () => {
  return (
    <div className="relative w-full  md:h-screen bg-black font-anand  pt-16 ">
      <div className="w-[95%] mx-auto   group md:h-full  ">
        <div className="pb-14">
          <h1 className=" text-center font-anand -mb-3 font-bold">
            {" "}
            <span className="text-2xl text-gray-100  sm:text-center ">
              The{" "}
              <span className="group-hover:text-[#00AFAC] font-bold">
                {" "}
                Erthpot{" "}
              </span>
            </span>
            <span className=" text-2xl   text-gray-100 ">Story.</span>
          </h1>
          <p className="text-gray-300 md:text-[14px] pt-5 text-center w-[90%] mx-auto">
            Symbolizing innovation and environmental consciousness, the brand
            draws inspiration from the timeless relevance of earthen pots in our
            culture. With an unwavering commitment to quality and
            sustainability, Erthpot aims to make a distinctive impact in the
            competitive AV market.
          </p>
        </div>
        <div className=" w-full  grid md:grid-cols-6 gap-8 sm:grid-cols-1">
          <div className=" md:col-span-4 relative w-full md:h-[70%] overflow-hidden hover:bg-black" >
            <img
              className="object-cover h-full w-full group hover:opacity-50 hover:cursor-pointer hover:scale-110 hover:duration-700 overflow-hidden transition-transform transform-gpu"
              src={made_in_india}
              alt=""
              loading="lazy"
            />
            <div className="absolute bottom-5  left-4">
              <span className="text-xl font-bold text-white ">Teamwork</span>
              <p className="text-white">Commited and creative</p>
            </div>
          </div>
          <div className="md:col-span-2  w-full">
            <div className=" md:h-[70%]">
              <div className="flex flex-col gap-8 h-full w-full ">
                <div className="relative md:h-[47%] font-anand overflow-hidden hover:bg-black">
                  <img
                    className=" object-cover h-full  w-full hover:opacity-50 hover:cursor-pointer hover:scale-110 hover:duration-700 overflow-hidden transition-transform transform-gpu"
                    src={sustainability}
                    alt=""
                    loading="lazy"
                  />
                  <div className="absolute bottom-5  left-4">
                    <span className="text-xl font-bold text-white ">
                      Creativity
                    </span>
                    <p className="text-white">striving for success</p>
                  </div>
                </div>
                <div className="relative md:h-[47%] overflow-hidden hover:bg-black">
                  <img
                    className=" object-cover h-full w-full relative hover:opacity-50 hover:cursor-pointer hover:scale-110 hover:duration-700 overflow-hidden transition-transform transform-gpu"
                    src={bridging}
                    alt=""
                    loading="lazy"
                  />
                  <div className="absolute bottom-5  left-4">
                    <span className="text-xl font-bold text-white ">
                      Innovation
                    </span>
                    <p className="text-white">leading in industry</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
