import React from "react";
import p1 from "../../assets/client/AMBIKA STEELS.png";
import p2 from "../../assets/client/CIDCO (1).png";
import p3 from "../../assets/client/FITGEE.png";
import p4 from "../../assets/client/GB Pant.png";
import p5 from "../../assets/client/GOI.png";
import p6 from "../../assets/client/INSTITUTE (1).png";
import p7 from "../../assets/client/RB.png";
import p8 from "../../assets/client/Railway.png";
import p9 from "../../assets/client/TE (2).png";
import p10 from "../../assets/client/FSSAI (1).png";

const Client = () => {
  return (
    <div className="relative w-full md:h-screen[80%] bg-black  font-anand pt-10">
      <div className="w-[95%] mx-auto  h-full group pt-10 pb-10">
        <div className="pb-0">
          <h1 className="text-3xl text-center text-gray-100  font-bold ">
            Trusted <span className="group-hover:text-[#00AFAC] ">By.</span>
          </h1>
          <h1 className=" text-center text-gray-100 pt-2 text-[14px] ">
            A Chronicle of Trust and Triumph - Celebrating the unwavering
            partners who entrusted us with their journey.
          </h1>
        </div>
        <div className="  gap-1 mx-auto  grid md:grid-cols-5 sm:grid-cols-5 grid-cols-5">
          <div className="  w-full  flex justify-center items-center">
            <img className="w-[100px]" src={p1} alt="" />
          </div>
          <div className=" h-[150px] w-full  flex justify-center items-center">
            <img className="w-[100px]" src={p2} alt="" />
          </div>
          <div className=" h-[150px] w-full  flex justify-center items-center">
            <img className="w-[100px]" src={p3} alt="" />
          </div>
          <div className=" h-[150px] w-full  flex justify-center items-center">
            <img className="w-[100px]" src={p4} alt="" />
          </div>
          <div className=" h-[150px] w-full flex justify-center items-center">
            <img className="w-[100px]" src={p5} alt="" />
          </div>
        </div>
        <div className="w-[100%] gap-1 mx-auto  grid md:grid-cols-5 sm:grid-cols-5 grid-cols-5 ">
          <div className=" h-[150px]  w-full  flex justify-center items-center">
            <img className="w-[100px]" src={p6} alt="" />
          </div>
          <div className=" h-[150px] w-full  flex justify-center items-center">
            <img className="w-[100px]" src={p7} alt="" />
          </div>
          <div className=" h-[150px] w-full  flex justify-center items-center">
            <img className="w-[100px]" src={p8} alt="" />
          </div>
          <div className=" h-[150px] w-full  flex justify-center items-center">
            <img className="w-[100px]" src={p9} alt="" />
          </div>
          <div className=" h-[150px] w-full flex justify-center items-center">
            <img className="w-[100px]" src={p10} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Client;
