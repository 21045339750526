import React, { useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import quickStart from "../../assets/quickStart.webp";
import emailjs from "@emailjs/browser";

const product1 = "Antenna Distribution System";
const product2 = "ARCHE Wireless Microphone";
const product3 = "KEYER DSP & Controls";
const product4 = "NOVO Conference System";
const product5 = "VECTOR Wired Microphones";
const product6 = "PC-1";
const product7 = "Novo Conference System";

const enquiry1 = "User Manual";
const enquiry2 = "Quick Start Guide";

const API_BASE_URL =
  "http://localhost:4000/api/v1/support/installationDebugging";

const Installation = () => {
  const [formSubmitted, setFormSubmitted] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedEnquiry, setSelectedEnquiry] = useState("");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    productYourProduct: "",
    enquiryType: "",
    howDoWeContactYou: "",
  });

  const handleProductChange = (e) => {
    const selectedProduct = e.target.value;
    setSelectedProduct(selectedProduct);
    setFormData({ ...formData, productYourProduct: selectedProduct });
  };

  const handleEnquiryType = (e) => {
    const selectedEnquiry = e.target.value;
    setSelectedEnquiry(selectedEnquiry);
    setFormData({ ...formData, enquiryType: selectedEnquiry });
  };

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    let data = { data: JSON.stringify(formData) };
    emailjs
      .send("service_rxvfa8e", "template_22pvuqo", data, {
        publicKey: "-WIObzjox69mrYBYJ",
      })
      .then(
        (response) => {
          setFormSubmitted(false);
          // console.log("SUCCESS!", response.status, response.text);
          alert("Request submited successfully");
          window.location.reload();
        },
        (err) => {
          setFormSubmitted(false);
          // console.log("FAILED...", err);
          alert("Something went wrong! Please try again");
        }
      );
    // try {
    //   const response = await axios.post(API_BASE_URL, formData, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   });

    //   const responseData = response.data;

    //   console.log("Data successfully posted:", responseData);
    //   setFormSubmitted([...formSubmitted, formData]);
    //   setFormData({
    //     firstName: "",
    //     lastName: "",
    //     email: "",
    //     phone: "",
    //     productYourProduct: "",
    //     enquiryType: "",
    //     howDoWeContactYou: "",
    //   });
    //   setSelectedProduct("");
    //   setSelectedEnquiry("");
    //   console.log("Data created successfully");
    // } catch (error) {
    //   console.error("Error creating contact:", error);
    // }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <motion.div
      variants={fadeIn}
      initial="hidden"
      animate="visible"
      className="bg-black min-h-screen font-anand mt-10"
    >
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 1 }}
        className="bg-cover bg-center h-64 relative"
        style={{ backgroundImage: `url(${quickStart})` }}
      >
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <div className="text-2xl md:text-5xl  text-white">
            Installation Debugging Instruction
          </div>
          <div className="mt-5  text-white  text-xs  font-semibold text-center uppercase">
            <a href="/">HOME</a> / Installation Debugging Instruction
          </div>
        </div>
      </motion.div>

      <div className="w-full grid grid-cols-1 md:grid-cols-2 ">
        <div className="w-[90%] mx-auto">
          <motion.section
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 0.5 }}
            className="w-[90%] mx-auto mt-8"
          >
            <h1 className="mb-4 text-xl   text-gray-100   ">
              Glad to know that you are using Erthpot products.
            </h1>
            <p className=" text-[14px]  text-gray-400">
              We have got your back. We check all the products in our QC lab
              before shipping. However some failures might occur once installed
              at the site. Rest assured, we take complete responsibility for
              Erthpot products and parts related issues. Let us know how we can
              make your experience better with us.
            </p>
          </motion.section>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 2, duration: 0.5 }}
        >
          <div>
            <div className="w-full  p-6 bg-black shadow-md md:mt-4">
              <div className="mx-auto w-[90%]">
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    <div className="mb-[0.7px]">
                      <input
                        type="text"
                        id="firstname"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="w-full p-2 border  border-gray-300 focus:outline-none bg-gray-200 focus:border-[#00AFAC]  text-justify"
                        placeholder="First Name"
                        required
                      />
                    </div>
                    <div className="mb-[0.7px]">
                      <input
                        type="text"
                        id="lastname"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 focus:outline-none bg-gray-200 focus:border-[#00AFAC]  text-justify"
                        required
                        placeholder="last Name"
                      />
                    </div>

                    <div className="">
                      <input
                        type="email"
                        id="email"
                        onChange={handleChange}
                        value={formData.email}
                        name="email"
                        className="w-full p-2 border border-gray-300 focus:outline-none bg-gray-200 focus:border-[#00AFAC]  text-justify "
                        placeholder="john@example.com"
                      />
                    </div>

                    <div className="">
                      <input
                        type="text"
                        id="Phone"
                        name="phone"
                        onChange={handleChange}
                        value={formData.phone}
                        className="w-full p-2 border border-gray-300 focus:outline-none bg-gray-200 focus:border-[#00AFAC]  text-justify  "
                        placeholder="Phone No.."
                      />
                    </div>

                    <div className="mb-[0.7px]">
                      <select
                        className="p-[9.5px]  w-full border text-[14px]  text-gray-800   outline-none bg-gray-200 "
                        value={selectedProduct}
                        onChange={handleProductChange}
                      >
                        <option> Please Select Your Category </option>
                        <option value={product1}>
                          Antenna Distribution System
                        </option>
                        <option value={product2}>
                          ARCHE Wireless Microphone
                        </option>
                        <option value={product3}>KEYER DSP & Controls</option>
                        <option vlaue={product4}>NOVO Conference System</option>
                        <option vlaue={product5}>
                          VECTOR Wired Microphones
                        </option>
                      </select>
                    </div>

                    <div className="mb-[0.7px]">
                      <select
                        className=" p-[9.5px] w-full border text-[14px]  text-gray-800 outline-none bg-gray-200 "
                        value={selectedProduct}
                        onChange={handleEnquiryType}
                      >
                        <option> Enquiry Type</option>
                        <option value={enquiry1}>User Manual</option>
                        <option value={enquiry2}>Quick Start Guide</option>
                      </select>
                    </div>
                  </div>

                  <div className="mt-[4.5px] mb-[0.7px]">
                    <input
                      type="text"
                      id="Contact"
                      name="howDoWeContactYou"
                      onChange={handleChange}
                      value={formData.howDoWeContactYou}
                      className="mt-1 p-2 w-full border  outline-none bg-gray-200 "
                      placeholder=" How do we contact you ?"
                    />
                  </div>

                  <div className="mt-2">
                    <button
                      className="hover:bg-[#00AFAC] hover:duration-700 duration-700 
                                    rounded-md uppercase text-center border-2 border-[#00AFAC] px-5  py-2  
                                     text-gray-100 font-semibold text-sm"
                      onClick={handleSubmit}
                      disabled={formSubmitted === true ? true : false}
                    >
                      {formSubmitted === true ? "Please wait" : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Installation;
