import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./Component/Landing";
import BlogPage from "./Component/Pages/BlogPage";
import Blogs from "./Component/Blogs/Blogs";
import About from "./Component/Pages/About";
import Products from "./Component/Products/Products";
import SingleProductPage from "./Component/Products/SingleProduct";
import AllProducts from "./Component/Products/AllProducts";
import Support from "./Component/Pages/Support";
import Contact from "./Component/Pages/Contact/Contact";
import Partner from "./Component/Pages/Partner";
import Navbar from "./Component/Navbar/Navbar";
import Footer from "./Component/Footer/Footer";
import DesignSolution from "./Component/Pages/DesignSolution";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Installation from "./Component/Pages/Installation";
import AfterSales from "./Component/Pages/AfterSales";
import NewsEvents from "./Component/Pages/NewsEvents";
import News from "./Component/News/News";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className="App overflow-y-hidden">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/pages/:index" element={<BlogPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product/:categoryName/:productName" element={<SingleProductPage />} />
          <Route path="/allproducts/:productName" element={<AllProducts />} />
          <Route path="/registration-warranty-form" element={<Support />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/registration-warranty-form" element={<Support />} />
          <Route path="/partner-with-us" element={<Partner />} />
          <Route path="/design-solution" element={<DesignSolution />} />
          <Route path="/installation-debugging-instruction" element={<Installation />} />
          <Route path="/after-sales-support-troubleshooting"element={<AfterSales />}   />
          <Route path="/news-events" element={<News />} />
          <Route path="/news-events/pages/:index" element={<NewsEvents />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
