import React, { useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import warrantyphoto from "../../assets/warrantyphoto.webp";
import emailjs from "@emailjs/browser";

const API_BASE_URL = "http://localhost:4000/api/v1/support/registrationForm";

const Support = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company_name: "",
    country: "",
    state: "",
    zip_code: "",
    image: null,
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // Check if a file is selected
    if (selectedFile) {
      setSelectedFileName(selectedFile.name);
    } else {
      setSelectedFileName("");
    }
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    let data = { data: JSON.stringify(formData) };
    emailjs
      .send("service_rxvfa8e", "template_22pvuqo", data, {
        publicKey: "-WIObzjox69mrYBYJ",
      })
      .then(
        (response) => {
          setFormSubmitted(false);
          // console.log("SUCCESS!", response.status, response.text);
          alert("Request submited successfully");
          window.location.reload();
        },
        (err) => {
          setFormSubmitted(false);
          // console.log("FAILED...", err);
          alert("Something went wrong! Please try again");
        }
      );

    // try {
    //   const response = await axios.post(API_BASE_URL, formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //     withCredentials: true,
    //   });

    //   const responseData = response.data;

    //   console.log("Data successfully posted:", responseData);
    //   setFormSubmitted([...formSubmitted, formData]);
    //   setFormData({
    //     name: "",
    //     email: "",
    //     phone: "",
    //     companyName: "",
    //     country: "",
    //     state: "",
    //     zipCode: "",
    //     image: null,
    //   });
    //   setSelectedFileName("");
    //   console.log("Contact created successfully");
    // } catch (error) {
    //   console.error("Error creating contact:", error);
    // }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <motion.div
      variants={fadeIn}
      initial="hidden"
      animate="visible"
      className="bg-black mt-10"
    >
      {/* Banner Section */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 1 }}
        className="bg-cover bg-center h-64 relative"
        style={{ backgroundImage: `url(${warrantyphoto})` }}
      >
        <div className="absolute inset-0 flex flex-col items-center justify-center mt-4 font-anand">
          <div className="text-center text-2xl md:text-5xl  text-gray-100 ">
            Registration & Warranty Form
          </div>
          <div className="mt-2 md:mt-5 text-center font-semibold text-gray-100 text-xs  uppercase">
            <a href="/">HOME</a>/ REGISTRATION & WARRANTY FORM
          </div>
        </div>
      </motion.div>

      <div className="w-full py-10 grid  grid-cols-1 md:grid-cols-2 font-anand">
        <div className="w-[95%] mt-2 mx-auto ">
          <motion.section
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 0.5 }}
            className="w-[90%] mx-auto mt-8"
          >
            <h1 className="mb-4  text-xl   text-gray-100  ">
              Erthpot warrants your product against all defects and factory
              errors when purchased.
            </h1>
            <div className="space-y-2">
              <p className=" text-[14px]  text-gray-400">
                The warranty is valid for 3 years from the date of retail
                purchase.In case of any defect Erthpot ensures to resolve it at
                its best by option:
              </p>

              <p className=" text-[14px]  text-gray-400">
                Repair the product using original spare parts.
              </p>
              <p className=" text-[14px]  text-gray-400">
                Replace the product with an equivalent new product.
              </p>
            </div>

            <h1 className="mt-4 mb-4 text-xl   text-gray-100 ">
              The following cases are not covered by the above warranty:
            </h1>

            <div className="space-y-2">
              <p className=" text-[14px]  text-gray-400">
                Any accessories supplied with the products.
              </p>
              <p className=" text-[14px]  text-gray-400">
                Rechargeable and disposable batteries (these products have a
                shorter service life, the length of which also depends on the
                frequency of use).
              </p>
              <p className=" text-[14px]  text-gray-400">
                Faults resulting from improper use (e.g. operating errors,
                mechanical damage, incorrect operating voltage).
              </p>
              <p className=" text-[14px]  text-gray-400">
                Proper use for the purposes of this warranty is defined as the
                use of the product under the conditions stated in the
                instructions for use.
              </p>
              <p className="text-[14px]   text-gray-400">
                Faults resulting from wear and tear as well as force majeure
                events.
              </p>
            </div>
          </motion.section>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1, duration: 0.5 }}
          className="mt-1"
        >
          <div className="w-full p-6 bg-black shadow-md md:mt-4  ">
            <div className="mx-auto w-[90%]">
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                  <div className="mb-[0.7px]">
                    <input
                      type="text"
                      id="name"
                      value={formData.name}
                      name="name"
                      onChange={handleChange}
                      className="w-full p-2 border   border-gray-300 focus:outline-none bg-gray-200 focus:border-gray-100  "
                      placeholder="John Doe"
                    />
                  </div>
                  <div className="mb-[0.7px]">
                    <input
                      type="email"
                      id="email"
                      onChange={handleChange}
                      value={formData.email}
                      name="email"
                      className="w-full p-2 border border-gray-300 focus:outline-none focus:border-gray-100  bg-gray-200"
                      placeholder="john@example.com"
                    />
                  </div>

                  <div className="mb-[0.7px]">
                    <input
                      type="number"
                      id="Phone"
                      name="phone"
                      onChange={handleChange}
                      value={formData.phone}
                      className="w-full p-2 border border-gray-300 focus:outline-none focus:border-gray-100 bg-gray-200"
                      placeholder="Phone No"
                    />
                  </div>
                  <div className="mb-[0.7px]">
                    <input
                      type="text"
                      id="company name"
                      name="company_name"
                      onChange={handleChange}
                      value={formData.company_name}
                      className="w-full p-2 border border-gray-300 focus:outline-none focus:border-gray-100  bg-gray-200"
                      placeholder="Company Name"
                    />
                  </div>

                  <div className="mb-[0.7px]">
                    <input
                      type="text"
                      id="country name"
                      onChange={handleChange}
                      value={formData.country}
                      name="country"
                      className="w-full p-2 border border-gray-300 focus:outline-none focus:border-gray-100  bg-gray-200 "
                      placeholder="Country Name"
                    />
                  </div>

                  <div className="mb-2">
                    <input
                      type="text"
                      id="state name"
                      onChange={handleChange}
                      name="state"
                      value={formData.state}
                      className="w-full p-2 border border-gray-300 focus:outline-none focus:border-gray-100  bg-gray-200"
                      placeholder="State Name"
                    />
                  </div>
                </div>
                <div className=" mb-2">
                  <input
                    type="Zipcode/Postal Code"
                    id="Zipcode/Postal Code"
                    name="zip_code"
                    value={formData.zip_code}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 focus:outline-none focus:border-gray-100 bg-gray-200"
                    placeholder="Zipcode/Postal Code"
                  />
                </div>

                <div className="relative">
                  <input
                    type="file"
                    accept=".pdf"
                    id="pdfFile"
                    onChange={handleFileChange}
                    className="w-full p-[5.2px] border border-gray-300 focus:outline-none focus:border-gray-100 bg-gray-200"
                  />
                  {selectedFileName && (
                    <div className="absolute bottom-2 right-10 md:right-0 p-[5.2px] mr-2   text-gray-100 text-[10px]">
                      <a
                        rel="noopener noreferrer"
                        className="text-gray-100 hover:underline"
                      >
                        View Pdf :{selectedFileName}
                      </a>
                    </div>
                  )}
                </div>

                <div className="mt-[0.7px]">
                  <button
                    className="hover:bg-[#00AFAC] hover:duration-700 duration-700 
                                    rounded-md uppercase text-center border-2 border-[#00AFAC] px-5  py-2  
                                    mt-2 text-gray-100 font-semibold text-sm"
                    onClick={handleSubmit}
                    disabled={formSubmitted === true ? true : false}
                  >
                    {formSubmitted === true ? "Please wait" : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Support;
