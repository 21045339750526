import React, { useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import handman from "../../assets/handman.webp";
import emailjs from "@emailjs/browser";

const endUser = "End User";
const systemIntegrator = "System Integrator";
const consultant = "Consultant";
const other = "Other";

const appliction1 = "Conference Room";
const appliction2 = "Auditorium";
const appliction3 = "Classroom";
const appliction4 = "Restaurant";

const API_BASE_URL = "http://localhost:4000/api/v1/support/designSolution";

const DesignSolution = () => {
  const [formSubmitted, setFormSubmitted] = useState([]);
  const [selectedYouAre, setSelectedYouAre] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedApplication, setSelectedAppliction] = useState("");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    user: "",
    application: "",
    location: "",
    phone: "",
    message: "",
    image: null,
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    let data = {data: JSON.stringify(formData)}
    emailjs
      .send("service_rxvfa8e", "template_22pvuqo", data, {
        publicKey: "-WIObzjox69mrYBYJ",
      })
      .then(
        (response) => {
          setFormSubmitted(false);
          // console.log("SUCCESS!", response.status, response.text);
          alert("Request submited successfully");
          window.location.reload();
        },
        (err) => {
          setFormSubmitted(false);
          // console.log("FAILED...", err);
          alert("Something went wrong! Please try again");
        }
      );
    // try {
    //   const response = await axios.post(API_BASE_URL, formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   });

    //   const responseData = response.data;

    //   console.log("Data successfully posted:", responseData);
    //   setFormSubmitted([...formSubmitted, formData]);
    //   setFormData({
    //     firstName: "",
    //     lastName: "",
    //     user: "",
    //     application: "",
    //     location: "",
    //     phone: "",
    //     message: "",
    //     image: null,
    //   });
    //   console.log("Data created successfully");
    //   setSelectedFileName("");
    //   setSelectedYouAre("");
    //   setSelectedAppliction("");
    // } catch (error) {
    //   console.error("Error creating contact:", error);
    // }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleYouAreChange = (e) => {
    const selectedYouAre = e.target.value;
    setSelectedYouAre(selectedYouAre);
    setFormData({ ...formData, user: selectedYouAre });
  };

  const handleApplictionChange = (e) => {
    const selectedAppliction = e.target.value;
    setSelectedAppliction(selectedAppliction);
    setFormData({ ...formData, application: selectedAppliction });
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      className="bg-black min-h-screen font-anand mt-10"
    >
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 1 }}
        className="bg-cover bg-center h-64 relative"
        style={{ backgroundImage: `url(${handman})` }}
      >
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <div className="text-4xl md:text-5xl  text-gray-100">
            Design & Solution
          </div>
          <div className="mt-5  text-gray-100  text-xs  font-semibold text-center">
            <a href="/">HOME</a> / DESIGN SOLUTION
          </div>
        </div>
      </motion.div>

      <div className="w-full mt-1 grid  grid-cols-1 md:grid-cols-2">
        <div className="w-[95%] mx-auto">
          <motion.section
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 0.5 }}
            className="w-[90%] mx-auto mt-8"
          >
            <p className="mb-4  text-xl   text-gray-100  ">
              We assure you that we commit as much as you do for your customers.
            </p>
            <p className=" text-[14px]  text-gray-400  ">
              We know how important it is to be correct right at the design
              stage. Clearly understanding and laying down the client’s
              requirements is the first and foremost requirement in any
              technical solution. We assure you that we commit as much as you do
              for your customers to ensure that the final results are more than
              satisfactory. We would love to help you design the right solution
              for your client. Would you like to give some information about the
              project you are working on?
            </p>
          </motion.section>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1, duration: 0.5 }}
          className=""
        >
          <div className="w-full p-6 bg-black  shadow-md md:mt-4 ">
            <div className="mx-auto w-[90%]">
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                  <div className="mb-[0.7px]">
                    <input
                      type="text"
                      id="firstname"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      className="w-full p-2 border  border-gray-300  focus:outline-none bg-gray-200 focus:border-[#00AFAC]  text-justify"
                      placeholder="First Name"
                      required
                    />
                  </div>
                  <div className="mb-[0.7px]">
                    <input
                      type="text"
                      id="lastname"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      className="w-full p-2 border  border-gray-300  focus:outline-none bg-gray-200 focus:border-[#00AFAC]  text-justify"
                      placeholder="Last Name"
                      required
                    />
                  </div>
                  <div className="mb-[0.7px]">
                    <select
                      className="w-full p-2 border  border-gray-300 text-[14px]  text-gray-800  outline-none bg-gray-200  "
                      value={selectedYouAre}
                      onChange={handleYouAreChange}
                    >
                      <option> You are a</option>
                      <option value={endUser}>End User</option>
                      <option value={systemIntegrator}>
                      System Integrator
                      </option>
                      <option value={consultant}>Consultant</option>
                      <option vlaue={other}>Other</option>
                    </select>
                  </div>

                  <div className="mb-[0.7px]">
                    <select
                      className="w-full p-2 border  border-gray-300 text-[14px]  text-gray-800  outline-none bg-gray-200"
                      value={selectedApplication}
                      onChange={handleApplictionChange}
                    >
                      <option>Application</option>
                      <option value={appliction1}>Conference Room</option>
                      <option value={appliction2}>Auditorium</option>
                      <option value={appliction3}>Classroom</option>
                      <option vlaue={appliction4}>Restaurant</option>
                      <option value={other}>Other</option>
                    </select>
                  </div>

                  <div className="mb-[0.7px]">
                    <input
                      type="text"
                      id="location"
                      name="location"
                      onChange={handleChange}
                      value={formData.location}
                      className="w-full p-2 border border-gray-300  focus:outline-none bg-gray-200 focus:border-[#00AFAC]  text-justify"
                      placeholder="Location"
                      required
                    />
                  </div>

                  <div className="mb-[0.7px]">
                    <input
                      type="number"
                      id="phone"
                      name="phone"
                      onChange={handleChange}
                      value={formData.phone}
                      className="w-full p-2 border border-gray-300  focus:outline-none bg-gray-200 focus:border-[#00AFAC]  text-justify"
                      placeholder="Phone No"
                      required
                    />
                  </div>
                </div>

                <div className="mt-2 mb-[0.7px]">
                  <input
                    type="file"
                    accept=".pdf"
                    id="pdfFile"
                    onChange={handleFileChange}
                    className="w-full p-[5.2px] border border-gray-300 focus:outline-none bg-gray-200 focus:border-gray-100  text-justify"
                  />

                  {selectedFileName && (
                    <div className="mt-1 text-gray-100 text-sm font-medium">
                      Selected File: {selectedFileName}
                    </div>
                  )}
                </div>
                <div className="mt-2">
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="1"
                    className="w-full p-2 border border-gray-300 focus:outline-none bg-gray-200 focus:border-blue-500"
                    placeholder="Please elaborate your requirement"
                    required
                  ></textarea>
                </div>

                <div className="mt-[2px]">
                  <button
                    className="hover:bg-[#00AFAC] hover:duration-700 duration-700 uppercase text-center 
                                border-2 border-[#00AFAC] px-5  py-2 
                                 text-gray-100 font-semibold text-sm rounded-md"
                    onClick={handleSubmit}
                    disabled= {formSubmitted === true ? true : false}
                  >
                    {formSubmitted === true ? "Please wait" : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};
export default DesignSolution;
