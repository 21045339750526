
import newsImg from "../../assets/NewsImage/newsimg.webp"
import newsImg2  from "../../assets/NewsImage/newsimg1.webp"

const NewsItems = [
  {

    img: newsImg,
    date: "Dec 19, 2023",
    by: "Chhaya",
    heading: "Palm Expo 2023 Mumbai",
    intro: "Erthpot, a newly born company dedicated to bringing state-of-the-art commercial audio products, captivated audiences at the esteemed Palm...",
    Comments: "2 Comments",
    in: "Introduction:",
    introduction: "Erthpot, a newly born company dedicated to bringing state-of-the-art commercial audio products, captivated audiences at the esteemed Palm Expo 2023 held in Mumbai. Despite being a relatively new player in the industry, Erthpot showcased its extensive range of cutting-edge audiovisual products, leaving attendees spellbound with its innovative designs and outstanding performance. Erthpot’s reputation for delivering high-quality solutions that elevate audio and visual experiences preceded its participation in the Palm Expo. Visitors flocked to the Erthpot booth, eager to explore the latest offerings that the company had in store",

    technicalSpecification: [
      "Erthpot, a newly born company dedicated to bringing state-of-the-art commercial audio products, captivated audiences at the esteemed Palm Expo 2023 held in Mumbai. Despite being a relatively new player in the industry, Erthpot showcased its extensive range of cutting-edge audiovisual products, leaving attendees spellbound with its innovative designs and outstanding performance. Erthpot’s reputation for delivering high-quality solutions that elevate audio and visual experiences preceded its participation in the Palm Expo. Visitors flocked to the Erthpot booth, eager to explore the latest offerings that the company had in store",

      "Erthpot’s Digital Signal Processing (DSP) controls were the show’s stars, stealing the limelight at the expo. The company’s website reveals the DSP controls section, offering a glimpse of its diverse lineup. These devices provide users with precise control over audio and video signals, enabling the creation of extraordinary experiences across various industries.",
      "The DSP controls on display at Palm Expo 2023 were some of the most advanced on the market. They featured a wide range of features, including",
      " Precise signal processing: The DSP controls were able to precisely process audio and video signals, ensuring that the output was always of the highest quality.",
      " Seamless integration: The DSP controls were designed to seamlessly integrate with a wide range of audio and video equipment. This made them easy to use and set up, even for users with limited experience.",
      "Exceptional performance: The DSP controls performed flawlessly throughout the expo. They were able to handle even the most demanding audio and video signals without any problems.",
      "As a result of their outstanding performance and features, Erthpot’s DSP controls were a huge hit at the Palm Expo. They generated a great deal of interest from both potential customers and industry professionals.",
      "Other products on display",

      " In addition to its DSP controls, Erthpot also showcased a range of other products at the Palm Expo. These included:",

      " Audio conference systems: Erthpot’s audio conference systems were designed to facilitate crystal-clear communication in conference rooms, boardrooms, and other professional settings. They featured a wide range of features, including:",

      "• Superior audio quality",
      "• Seamless integration with video conferencing software",
      "• Easy- to - use controls",

      "Wireless microphones: Erthpot’s wireless microphones were built to meet the demands of live performances, broadcasting, and professional studios. They featured a wide range of features, including:",
     "• Exceptional performance",
    "• Long battery life",
    "• Rugged construction",
    "The Future of Erthpot: Erthpot’s showcase at Palm Expo 2023 was a huge success. It generated great interest in the company’s products and services. As a result, Erthpot is well-positioned for future growth. The company is already planning to expand its product range and enter new markets. It is also planning to partner with other companies to develop innovative new solutions. With its innovative products and dedicated team, Erthpot is well-positioned to become a leading player in the global audio industry.",
    "Testimonials",
    "“Erthpot’s showcase at Palm Expo 2023 was truly a feast for the senses,” remarked Mr. Rajesh Patel, a sound engineer attending the event. “Their range of products, from DSP controls to audio conference systems and wireless microphones, demonstrated their dedication to pushing the boundaries of audio technology. I was thoroughly impressed.”",
    "“Erthpot is a company to watch,” said Mr. Sanjay Mehta, a business consultant who visited the Erthpot booth at the Palm Expo. “They have a clear vision for the future of audio technology, and they are well-positioned to achieve their goals.”",
    "Conclusion: Erthpot’s showcase at Palm Expo 2023 was a huge success. It generated great interest in the company’s products and services. As a result, Erthpot is well-positioned for future growth. The company is already planning to expand its product range and enter new markets. It is also planning to partner with other companies to develop innovative new solutions. With its innovative products and dedicated team, Erthpot is well-positioned to become a leading player in the global audio industry."
    
    ],
  },
  {
    img: newsImg2,
    intro: "G.B. Pant National Institute of Himalayan Environment, a renowned research institution, needed a reliable and efficient conference disc...",
    date: "Dec 15, 2023",
    by: "Anand",
    heading: "The Novo Conference System at G.B. Pant (NIHE)",
    in: "Introduction:",
    Comments: "2 Comments",
    introduction: "In an era where virtual collaboration has become integral to business operations, the demand for reliable video conferencing solutions is soaring. Choosing the right equipment is crucial, and thankfully, India boasts a plethora of reputable suppliers catering to diverse business needs. Here’s a glimpse into some of the top video conferencing equipment suppliers in India, all accessible through erthpot.com:",

    technicalSpecification: [
      "G.B. Pant National Institute of Himalayan Environment, a renowned research institution, needed a reliable and efficient conference discussion system for its meeting spaces. They sought a solution to provide seamless communication among participants and integrate audio-video capabilities. After carefully evaluating available options, they turned to us, a leading audio-visual solutions provider, to install the state-of-the-art Novo Conference System.",
      "We understood the institute’s requirements and recommended the Novo Conference System due to its simple and affordable design, plug-and-play operation, and impressive features. The system’s ability to handle up to 110 microphone units simultaneously and its utility settings, like automatic sound activation, time limit, and adjustable active speakers, made it the ideal choice for the institute’s various conference rooms, assembly halls, and classrooms.",
      "One of the standout features of the Novo Conference System was its wired loop In-Out connectivity, which enabled easy installation and communication between the discussion units and the central controller using standard STP Cat5e network cables. This reduced costs and made troubleshooting and maintenance a hassle-free process.",
      "The discussion units themselves were engineered with high-quality aluminum alloyed metal die-cast, giving them a robust and long-lasting feel. Each team featured an integrated high-sensitivity shotgun microphone with a status light ring at the bottom, providing visual cues to indicate whether the microphone was muted (red) or active (green). This intuitive design ensured clear and efficient communication during conferences.",
      "Recognizing the growing importance of visuals in modern conferences, We integrated the Novo Conference System with video cameras. Up to four cameras could be connected to the system, allowing them to follow the active speakers in the room. This seamless audio-video integration added a new level of engagement and enhanced the overall conference experience.",
      "The Novo Conference System’s expandability was another significant advantage for the institute. With the ability to connect up to 110 units using an external power supply for the discussion units, G.B. Pant National Institute of Himalayan Environment could easily accommodate various meeting sizes and configurations without compromising audio quality.",
      "The system also offered multiple speech modes, including FIFO (First-In-First-Out), Automatic with a time limit, Request, and Sound Activated, ensuring flexibility and adaptability to different conference formats. The Automatic Gain processing technology integrated into the system prevented feedback issues and improved voice clarity, enhancing the overall audio experience for participants.",
      "Furthermore, the Novo Conference System supported camera connection and speaker tracking through RS232 and RS422 communication ports, enabling seamless video integration and ensuring active speakers remained focused throughout the conference. It also featured 2×2 Dante digital input and output, allowing integration with other digital conference solutions.",
      "We successfully installed the Novo Conference System at G.B. Pant National Institute of Himalayan Environment, transforming their conference rooms, assembly halls, and classrooms into fully-equipped communication hubs. The system’s plug-and-play operation, audio-video integration, and advanced features aligned perfectly with the institute’s simplicity, ease of use, and high-quality communication requirements.",
      "Our successful installation of the Novo Conference System at G.B. Pant National Institute of Himalayan Environment enhanced its reputation as a leading audio-visual solutions provider. It contributed to the institute’s mission of promoting research and knowledge exchange in the field of Himalayan environment studies. The project is a testament to the power of advanced audio-visual technology in facilitating effective communication and driving success in academic and research institutions."
    ]
  },

]

export default NewsItems;
