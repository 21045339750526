import React from "react";
import p1 from "../../assets/founder/IMG_20240124_162537(11).jpg";
import p2 from "../../assets/founder/IMG_20240124_165639.jpg";
import p3 from "../../assets/founder/IMG_20240124_165708.jpg";
import p4 from "../../assets/founder/heena.png";
import { FaLinkedin } from "react-icons/fa";

const Foundation = () => {
  return (
    <>
      <div className="w-full md:h-screen  bg-black overflow-hidden">
        <div className="py-6">
          <h1 className="text-center text-gray-100 text-2xl font-bold py-5">
            {" "}
            Board Of Directors
          </h1>
        </div>
        <div className=" w-[80%] h-full  mx-auto grid sm:grid-cols-2 grid-cols-1 md:grid-cols-4 gap-3">
          <div className=" md:h-[75%]  overflow-hidden">
            <div className="zoom-container relative h-full grayscale hover:grayscale-0  hover:cursor-pointer hover:scale-[1.1] hover:duration-700 overflow-hidden transition-transform transform-gpu ">
              <img
                src={p1}
                alt=""
                className="object-cover w-full h-full transform border border-solid border-white"
              />

              <h1 className="w-[99%] bg-black absolute text-[18px] text-center bottom-7 left-1/2 transform -translate-x-1/2 text-gray-100  font-bold whitespace-nowrap">
                Vikram Yadav <p className=" text-xs">Finance & Production</p>
                <a
                  href="https://www.linkedin.com/in/vikram-yadav-02902620/"
                  target="_blank"
                  className="flex justify-center"
                >
                  <FaLinkedin className=" text-2xl text-gray-100 hover:text-[#00AFAC] duration-700 hover:duration-700 cursor-pointer" />
                </a>
              </h1>
            </div>
          </div>
          <div className=" md:h-[75%]  overflow-hidden">
            <div className="zoom-container relative h-full grayscale hover:grayscale-0 hover:cursor-pointer hover:scale-[1.1] hover:duration-700 overflow-hidden transition-transform transform-gpu ">
              <img
                src={p3}
                alt=""
                className="object-cover w-full h-full transform border border-solid border-white"
              />
              <h1 className="w-[99%] bg-black absolute text-[18px] text-center bottom-7 left-1/2 transform -translate-x-1/2 text-gray-100  font-bold whitespace-nowrap">
                Vijay Sinha <p className=" text-xs">Marketing</p>
                <a
                  href="https://www.linkedin.com/in/vijay-sinha-2ab88327/"
                  target="_blank"
                  className="flex justify-center"
                >
                  <FaLinkedin className=" text-2xl text-gray-100 hover:text-[#00AFAC] duration-700 hover:duration-700 cursor-pointer" />
                </a>
              </h1>
            </div>
          </div>
          <div className=" md:h-[75%]  overflow-hidden">
            <div className="zoom-container relative h-full grayscale hover:grayscale-0 hover:cursor-pointer hover:scale-[1.1] hover:duration-700 overflow-hidden transition-transform transform-gpu ">
              <img
                src={p4}
                alt=""
                className="object-cover w-full h-full transform border border-solid border-white"
              />
              <h1 className="w-[99%] bg-black absolute text-[18px] text-center bottom-7 left-1/2 transform -translate-x-1/2 text-gray-100  font-bold whitespace-nowrap">
                Heena Golani <p className=" text-xs">Legal & Sales</p>
                <a
                  href="https://www.linkedin.com/in/heena-g-b94148103/"
                  target="_blank"
                  className="flex justify-center"
                >
                  <FaLinkedin className=" text-2xl text-gray-100 hover:text-[#00AFAC] duration-700 hover:duration-700 cursor-pointer" />
                </a>
              </h1>
            </div>
          </div>
          <div className=" md:h-[75%]  overflow-hidden">
            <div className="zoom-container relative h-full grayscale hover:grayscale-0 hover:cursor-pointer hover:scale-[1.1] hover:duration-700 overflow-hidden transition-transform transform-gpu ">
              <img
                src={p2}
                alt=""
                className="object-cover w-full h-full transform border border-solid border-white"
              />
              <h1 className="w-[99%] bg-black absolute text-[18px] text-center bottom-7 left-1/2 transform -translate-x-1/2 text-gray-100  font-bold whitespace-nowrap">
                Shailesh Awasthi <p className=" text-xs">Sales & Technical</p>
                <a
                  href="https://www.linkedin.com/in/shailesh-awasthi-665a391a/"
                  target="_blank"
                  className="flex justify-center"
                >
                  <FaLinkedin className=" text-2xl text-gray-100 hover:text-[#00AFAC] duration-700 hover:duration-700 cursor-pointer" />
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Foundation;
